import React, { Component, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, GridColumn, GridRow, Header, Segment, Confirm, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { openModal } from "../../../app/common/modals/modalReducer";
import QuotePDFPackage from "../QuotePDF/QuotePDFPackage";
import { deleteQuote, createRevision, updateQuote, getPackageFlights, deleteHeaderImage, getQuoteTemplates } from "../quoteActions";
import format from "date-fns/format";
import { toast } from "react-toastify";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";
import addSeconds from "date-fns/addSeconds";
import { ErrorBoundary } from "@sentry/react";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function QuoteDetailedStatus(props) {
    const [opentThis, setOpentThis] = useState(false);
    let dispatch = useDispatch();
    // let quote = props.quote;
    let quotePackages = props.quotePackages;
    // let quote_templates = props.quote_templates;
    let lead = props.lead;
    let consultant = props.consultant;
    let profile = props.profile;
    let client = props.client;

    let quote = props.quote;
    let quotedate = "";

    if (quote && quote.createDate && quote.createDate !== "") {
        try {
            quotedate = format(addSeconds(new Date(0), quote.createDate.seconds), "yyyyMMdd");
        } catch (error) {
            // console.log(error.message);
        }
    }

    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();
    const [printing, setPrinting] = useState(false);
    const [revising, setRevising] = useState(false);
    const [quote_templates, setQuote_templates] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore.collection("quote_templates").onSnapshot((snapshot) => {
            let list = [];
            snapshot.forEach((doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), id: doc.id };
                    list.push(appObj);
                }
            });

            let quoteTemplateList = [
                {
                    key: "",
                    text: "Choose a Header Template",
                    value: "",
                },
                {
                    key: "Custom Upload",
                    text: "Custom Upload",
                    value: "Custom Upload",
                },
            ];

            Object.keys(list).forEach(function (key) {
                quoteTemplateList.push({
                    key: list[key].id,
                    text: list[key].template_name_admin ? list[key].template_name_admin : list[key].template_name,
                    value: list[key].id,
                });
            });

            setQuote_templates(quoteTemplateList);
        });

        return () => unsubscribe();
    }, []);

    const logo_division = [
        { key: "", text: "Choose a Logo", value: "" },
        { key: "Leisure", text: "Leisure", value: "Leisure" },
        { key: "Non-Leisure", text: "Non-Leisure", value: "Non-Leisure" },
    ];

    async function createRevisionFunc() {
        setRevising(true);
        quote.id = lastSegment;
        let quote_id = await createRevision(quote);
        setRevising(false);
        window.location.replace("/quotes/edit/" + quote_id);
    }

    async function deleteHeaderImageData() {
        await deleteHeaderImage(quote);
    }

    async function convertHtmlToPdf() {
        let quoteNew = quote;
        setPrinting(true);

        await getQuoteTemplates().then(
            (res) => {
                let list = [];
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                quoteNew = { ...quoteNew };

                //Find Quote template
                Object.keys(list).forEach(function (key) {
                    if (list[key].id === quote.template_id) {
                        quoteNew.templateUrl = list[key].fileUrl;
                        quoteNew.template_name = list[key].template_name;
                        quoteNew.visa = list[key].visa;
                        quoteNew.destination_link = list[key].destination_link;
                        quoteNew.wording = list[key].wording ? list[key].wording : "";
                    }
                });

                //Condensed PDF?
                if (!quoteNew.condensed_pdf) {
                    quoteNew.condensed_pdf = false;
                }
            },
            (err) => {
                console.log(err);
            }
        );

        let flights = await getPackageFlights(lastSegment);

        if (flights.length > 0) {
            flights.sort((a, b) => (a.departDateTime > b.departDateTime ? 1 : -1));
        }

        const pdfPackage = renderToString(
            <ErrorBoundary>
                <QuotePDFPackage quote={quoteNew} quotePackages={quotePackages} lead={lead} profile={profile} consultant={consultant} flights={flights} />
            </ErrorBoundary>
        );

        let html = pdfPackage;

        await fetch("https://v2018.api2pdf.com//chrome/html", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "104a3c14-8782-4ee5-885f-000b5a7fb8a1",
            },
            body: JSON.stringify({
                html: html,
                inlinePdf: true,
                fileName: "test.pdf",
                options: {
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                },
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success === true) {
                    window.open(res.pdf);
                } else {
                    toast.error("Something went wrong");
                }
            });
        setPrinting(false);
    }

    function openConfirm() {
        setOpentThis(true);
    }
    function closeConfirm() {
        setOpentThis(false);
    }

    async function deleteQuoteHere() {
        await deleteQuote(lastSegment);
        window.location.replace("/leads/edit/" + props.quote.leadId);
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Segment attached="top">
                <Header as="h3">Client Details - {quotedate ? quotedate + "-" + quote.quote_nr + "-" + quote.quote_rev : ""}</Header>
            </Segment>

            <Segment attached>
                <Formik
                    enableReinitialize="true"
                    initialValues={quote}
                    validate={(values) => {
                        const error = {};
                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        let quote = props.quote;

                        if (!values.logo_division || (values.logo_division !== "Leisure" && values.logo_division !== "Non-Leisure")) {
                            values.logo_division = "Leisure";
                        }
                        updateQuote(values, quote);
                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <>
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid verticalAlign="middle">
                                    <GridRow>
                                        <GridColumn width={5}>
                                            <label>Full Name</label>
                                        </GridColumn>
                                        <GridColumn width={11}>{quote.client_name}</GridColumn>
                                    </GridRow>
                                    {quote.client_email && (
                                        <GridRow>
                                            <GridColumn width={5}>
                                                <label>Email</label>
                                            </GridColumn>

                                            <GridColumn width={11}>{quote.client_email}</GridColumn>
                                        </GridRow>
                                    )}
                                    {quote.client_email && (
                                        <GridRow>
                                            <GridColumn width={5}>
                                                <label>Cell No</label>
                                            </GridColumn>
                                            <GridColumn width={11}>{quote.client_phone}</GridColumn>
                                        </GridRow>
                                    )}
                                </Grid>

                                <MySelectInput label="Logo Division" name="logo_division" options={logo_division} placeholder="Logo Division" />
                                <MySelectInput label="Header Template" name="template_id" options={quote_templates} placeholder="Header Template" />

                                <MyCheckboxInput type="checkbox" label="" labelcb="Condensed PDF" name="condensed_pdf" />

                                {quote && quote.template_id && quote.template_id === "Custom Upload" && (
                                    <div style={{ marginTop: 10 }}>
                                        {quote.fileUrl && quote.fileUrl.length > 0 ? (
                                            <Grid verticalAlign="middle">
                                                <Grid.Row>
                                                    <Grid.Column width={5}>
                                                        <label>
                                                            <strong>Custom Header Image</strong>
                                                        </label>
                                                    </Grid.Column>

                                                    <Grid.Column width={11}>
                                                        {/* <Form.Field> */}
                                                        <Image size="small" style={{ marginTop: 20 }} src={quote.fileUrl} />

                                                        <Button size="medium" color="orange" content="Delete" onClick={deleteHeaderImageData} />
                                                        {/* </Form.Field> */}
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        ) : (
                                            <Grid verticalAlign="middle">
                                                <Grid.Row>
                                                    <Grid.Column width={5}>
                                                        <label>
                                                            <strong>Upload Header Image</strong>
                                                        </label>
                                                    </Grid.Column>

                                                    <Grid.Column width={11}>
                                                        <input
                                                            id="uploadFile"
                                                            name="uploadFile"
                                                            type="file"
                                                            onChange={(event) => {
                                                                setFieldValue("uploadFile", event.currentTarget.files[0]);
                                                            }}
                                                            className="form-control"
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        )}
                                    </div>
                                )}
                                <Grid verticalAlign="middle">
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button disabled={isSubmitting} loading={isSubmitting} size="medium" color="orange" content="Save" />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </form>
                        </>
                    )}
                </Formik>
            </Segment>

            <Segment attached="bottom" className="buttons_row">
                <Button size="medium" color="orange" content="Create Revision" loading={revising} onClick={createRevisionFunc} />

                <Button size="medium" color="orange" content="Back to Lead" as={Link} to={"/leads/edit/" + quote.leadId} />

                {quotePackages && quotePackages.length > 0 && <Button size="medium" color="orange" content="Print PDF" loading={printing} onClick={convertHtmlToPdf} />}
                {quotePackages && quotePackages.length > 0 && (
                    <Button
                        size="medium"
                        disabled={!quote || !quote.client_email || quote.client_email.length === 0}
                        color="orange"
                        onClick={() =>
                            dispatch(
                                openModal({
                                    modalType: "LeadGeneralEmailModal",
                                    modalProps: {
                                        leadId: quote.leadId,
                                        quoteId: lastSegment,
                                        client_email: quote.client_email,
                                        initialValues: {
                                            subject: "",
                                            to: quote.client_email,
                                            bcc: profile.email,
                                            message: profile.fileUrl && profile.fileUrl !== "" ? profile.defaultQuoteMessage + "<img src='" + profile.fileUrl + "' />" : profile.defaultQuoteMessage,
                                            cc: client.client_cc_email,
                                        },
                                        emailType: "Email Quote",
                                        profile,
                                        includeQuote: true,
                                        quote,
                                        quotePackages,
                                        quote_templates,
                                        lead,
                                        consultant,
                                    },
                                })
                            )
                        }
                        content="Email Client"
                    />
                )}
                <Button size="medium" color="red" content="Delete" onClick={openConfirm} />
            </Segment>

            <Confirm
                header="You are about to delete this quote"
                content="This cannot be undone, are you sure?"
                size="tiny"
                open={opentThis}
                onCancel={closeConfirm}
                onConfirm={deleteQuoteHere}
                confirmButton="Yes"
            />
        </div>
    );
}
