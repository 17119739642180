import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Accordion } from "semantic-ui-react";
import { getLogs } from "../leads/leadActions";
import format from "date-fns/format";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";

import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadLogsModal(props) {
    const [logs, setLogs] = useState({});
    const [activeIndex, setActiveIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);

        const unsubscribe = firestore
            .collection("email")
            .where("leadId", "==", props.leadId)
            .orderBy("addedDt", "asc")
            .onSnapshot(async (snapshot) => {
                let emails = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let id = doc.id;

                        let emailLog = { ...doc.data(), id: doc.id };
                        let logs = [];

                        firestore
                            .collection("email_logs")
                            .where("emailId", "==", id)
                            .onSnapshot((snapshot2) => {
                                snapshot2.docs.forEach((doc2) => {
                                    if (doc2.exists) {
                                        logs.push({ ...doc2.data(), id: doc2.id });
                                    }
                                });
                            });

                        emails.push([emailLog, logs]);
                    }
                });

                const timeoutAction = () => {
                    return new Promise((resolve) => {
                        setTimeout(function () {
                            resolve(emails);
                        }, 500);
                    });
                };

                const email_list = await timeoutAction();

                email_list.sort((a, b) => (a.addedDt < b.addedDt ? 1 : -1));

                Object.keys(email_list).forEach(function (key) {
                    email_list[key][1].sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                });

                setLogs(email_list);
                setLoading(false);
            });

        return () => unsubscribe();
    }, []);

    function handleClick(newIndex) {
        if (newIndex === activeIndex) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(newIndex);
        }
    }

    return (
        <Modal size="large" open={true} onClose={props.closeModal} closeOnDimmerClick={true}>
            <Modal.Header>Email Logs</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {loading === true ? (
                        <p>Loading...</p>
                    ) : (
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Email Details</Table.HeaderCell>
                                    <Table.HeaderCell>Activity Logs</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {logs &&
                                    logs.length > 0 &&
                                    logs.map((log, index) => (
                                        <>
                                            <Table.Row key={log[0].id}>
                                                <Table.Cell>
                                                    {log[0].addedDt ? format(log[0].addedDt.toDate(), "yyyy-MM-dd HH:mm") : ""}
                                                    <br />
                                                    {log[0].emailType}
                                                </Table.Cell>

                                                <Table.Cell>
                                                    {log[1] && log[1].length > 0 ? (
                                                        <div style={{ maxHeight: "300px", overflow: "auto" }}>
                                                            <Table>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>Event</Table.HeaderCell>
                                                                        <Table.HeaderCell>Date/Time</Table.HeaderCell>
                                                                        <Table.HeaderCell>Recipient</Table.HeaderCell>
                                                                        <Table.HeaderCell>Status</Table.HeaderCell>
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                    {log[1].map((l) => {
                                                                        let event_time = new Date(l.webhook_data["event-data"].timestamp * 1000);
                                                                        return (
                                                                            <Table.Row>
                                                                                <Table.Cell>{l.webhook_data["event-data"].event}</Table.Cell>
                                                                                <Table.Cell>{format(event_time, "yyyy-MM-dd HH:mm")}</Table.Cell>
                                                                                <Table.Cell>{l.webhook_data["event-data"].recipient}</Table.Cell>
                                                                                <Table.Cell>
                                                                                    {l.webhook_data["event-data"].event === "failed" ? l.webhook_data["event-data"]["delivery-status"].description : ""}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        );
                                                                    })}
                                                                </Table.Body>
                                                            </Table>
                                                        </div>
                                                    ) : (
                                                        "No logs"
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>

                                            <Table.Row key={index}>
                                                <Table.Cell colSpan={2}>
                                                    <Accordion>
                                                        <Accordion.Title active={activeIndex === index} index={index} onClick={() => handleClick(index)}>
                                                            <strong>Email Message - Click to show/hide</strong>
                                                        </Accordion.Title>
                                                        <Accordion.Content active={activeIndex === index}>
                                                            <div dangerouslySetInnerHTML={{ __html: log[0].message }}></div>
                                                        </Accordion.Content>
                                                    </Accordion>
                                                </Table.Cell>
                                            </Table.Row>
                                        </>
                                    ))}
                            </Table.Body>
                        </Table>
                    )}

                    <Button onClick={() => dispatch(closeModal())}>Close</Button>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
