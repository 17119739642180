import React, { useState } from "react";
import { Modal, Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import ReactWhatsapp from "react-whatsapp";
import { closeModal } from "../../app/common/modals/modalReducer";

export default function WhatsappUserModal(props){
    const dispatch = useDispatch();
    let phone = ((props.lead.client_phone).replace(/ /g,'')).replace(/[()]/g,'');
    if(phone.substring(0, 4) === "+270") {
        phone = phone.replace(/^.{4}/g, '27');
    } else if(phone.substring(0, 3) === "+27") {
        phone = phone.replace(/^.{3}/g, '27');
    } else if(phone.substring(0, 1) === "0") {
        phone = phone.replace(/^.{1}/g, '27');
    } else {
        phone = phone
    }

    const [message, setMessage] = useState("");

    function handleChange(event){
        setMessage(event.target.value)
    }

    return(
        <>
            <Modal size="mini" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>
                    <Grid>
                        <Grid.Row columns={1} verticalAlign="middle">
                            <Grid.Column>Send a Whatsapp Message</Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>

                <Modal.Content>
                    <Modal.Description>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <label>
                                        Message: &nbsp;
                                        <select onChange={value => handleChange(value)} >
                                            <option value="">Choose One</option>
                                            <option value="Your quote has been sent!">Your quote has been sent!</option>
                                            <option value="Thank you for your enquiry, Please can you provide us with a few more details in order to quote you accurately. Travel dates, where you departing from, how many people and if there are any children their ages. Regards">
                                                More Info
                                            </option>
                                        </select>
                                    </label>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <ReactWhatsapp number={phone} message={message} id="whatsapp">
                                        Open Whatsapp
                                    </ReactWhatsapp>
                                    &nbsp;&nbsp;
                                    <Button onClick={() => dispatch(closeModal())}>
                                        Cancel
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                
            </Modal>
        </>
    )
}