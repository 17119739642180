import React, { useCallback, useEffect, useState } from "react";
import { Modal, Grid, Table } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { clonePackageFromTemplate, deletePackageTemplate, userConsultants, packageTemplates, getAuthUser } from "../quotes/quoteActions";
import { Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";

import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export default function PackageTemplateCreateModal(props) {
    const { currentUserProfile } = useSelector((state) => state.profile);
    let dispatch = useDispatch();
    const [users, setusers] = useState([]);
    const [packages, setPackages] = useState([]);
    const [chosenUser, setchosenUser] = useState(currentUserProfile.id);
    const [packageTemplatesList, setPackageTemplatesList] = useState([{ key: "", text: "None", value: "" }]);
    const [chosenPackage, setChosenPackage] = useState([]);
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();
    let quote = props.quote;
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        const unsubscribe2 = firestore
            .collection("users")
            .where("isConsultant", "==", true)
            .onSnapshot((snapshot) => {
                let userlist = [];
                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        userlist.push(appObj);
                    }
                });
                userlist.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

                userlist = userlist.map(function (pt) {
                    return {
                        key: pt.id,
                        text: pt.displayName,
                        value: pt.id,
                    };
                });

                setusers(userlist);
            });

        const unsubscribe = firestore
            .collection("package_templates")
            .where("userUid", "==", chosenUser)
            .onSnapshot((snapshot) => {
                let packageList = [];
                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        packageList.push(appObj);
                    }
                });
                setPackages(packageList);

                let package_templates = packageList;

                package_templates.sort((a, b) => (a.template_name > b.template_name ? 1 : -1));

                setPackageTemplatesList(
                    package_templates.map(function (pt) {
                        return {
                            key: pt.id,
                            text: pt.template_name + " (" + pt.package_name + ")",
                            value: pt.id,
                        };
                    })
                );
            });

        return () => {
            unsubscribe();
            unsubscribe2();
        };
    }, [chosenUser]);

    // let usersList = [];
    // users.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
    // usersList = users.map(function (pt) {
    //     return {
    //         key: pt.id,
    //         text: pt.displayName,
    //         value: pt.id,
    //     };
    // });

    const updateUser = useCallback((value) => {
        setchosenUser(value);
    }, []);

    function updateChosenUser(value) {
        updateUser(value.value);
    }

    const updatePackages = useCallback((value) => {
        // console.log(value)
        setChosenPackage(value);
    }, []);

    function loadTemplate(value) {
        let package_templates = packages;
        let chosenKey = "";

        Object.keys(package_templates).forEach(function (key) {
            if (package_templates[key].id === value.value) {
                chosenKey = key;
            }
        });

        if (chosenKey !== "") {
            console.log(package_templates[chosenKey]);
            updatePackages(package_templates[chosenKey]);
        }
    }

    async function deletePackage() {
        setDeleting(true);

        console.log(chosenPackage);

        await deletePackageTemplate(chosenPackage, props.quote);

        setDeleting(false);
    }

    return (
        <>
            <Modal size="large" open={true} onClose={() => dispatch(closeModal())}>
                <Modal.Content>
                    <Modal.Description>
                        <Formik
                            initialValues={{ new_package_name: "", package_template_id: "" }}
                            enableReinitialize="true"
                            validate={(values) => {
                                const error = {};
                                return error;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                quote.id = lastSegment;
                                await clonePackageFromTemplate(values, quote);

                                setSubmitting(false);
                                dispatch(closeModal());
                            }}
                            onChange={(value) => {}}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, field, setFieldValue }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid>
                                        <Grid.Row columns={4}>
                                            <Grid.Column width={3}>
                                                <MyTextInput label="" labelTop="Step 1: Package Name" name="new_package_name" type="text" />
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <MySelectInput
                                                    label=""
                                                    labelTop="Step 2: Choose a User"
                                                    name="chosenUser"
                                                    options={users}
                                                    onChange={(field, value) => updateChosenUser(value)}
                                                    value={chosenUser}
                                                    placeholder="Select a User"
                                                    legacy={true}
                                                />
                                            </Grid.Column>

                                            <Grid.Column width={3}>
                                                <MySelectInput
                                                    label=""
                                                    labelTop="Step 3: Choose a Template"
                                                    name="package_template_id"
                                                    options={packageTemplatesList}
                                                    value={values.package_template_id}
                                                    placeholder="Select a Package Template"
                                                    onChange={(field, value) => {
                                                        loadTemplate(value);
                                                        setFieldValue("package_template_id", value.value);
                                                    }}
                                                    legacy={true}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={7}>
                                                <div>Step 4: Clone</div>

                                                <Button disabled={isSubmitting} loading={isSubmitting} color="orange" content="Create Cloned Package" type="submit" />
                                                <Button disabled={deleting} loading={deleting} color="red" content="Delete Package" onClick={deletePackage} />
                                                <Button disabled={isSubmitting} color="grey" content="Cancel" onClick={() => dispatch(closeModal())} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            )}
                        </Formik>

                        {chosenPackage && chosenPackage.id && (
                            <Table celled style={{ marginTop: 20 }}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <strong>Package ID</strong>
                                        </Table.Cell>
                                        <Table.Cell>{chosenPackage.id}</Table.Cell>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell></Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>
                                            <strong>Package Name</strong>
                                        </Table.Cell>
                                        <Table.Cell>{chosenPackage.package_name}</Table.Cell>
                                        <Table.Cell>
                                            <strong>Price</strong>
                                        </Table.Cell>
                                        <Table.Cell>{chosenPackage.price}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>
                                            <strong>Holiday</strong>
                                        </Table.Cell>
                                        <Table.Cell>{chosenPackage.holiday_name}</Table.Cell>
                                        <Table.Cell>
                                            <strong>Discount</strong>
                                        </Table.Cell>
                                        <Table.Cell>{chosenPackage.discount}</Table.Cell>
                                    </Table.Row>

                                    {chosenPackage.price_breakdown && (
                                        <Table.Row>
                                            <Table.Cell colSpan="4">
                                                <strong>Price Breakdown</strong>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}

                                    {chosenPackage.price_breakdown && (
                                        <Table.Row>
                                            <Table.Cell colspan={4}>
                                                <ul>
                                                    {chosenPackage.price_breakdown.map((price, index) => (
                                                        <li key={`price_breakdown_${index}`}>{price.value}</li>
                                                    ))}
                                                </ul>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}

                                    {chosenPackage.includes && (
                                        <Table.Row>
                                            <Table.Cell colSpan="4">
                                                <strong>Includes</strong>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}

                                    {chosenPackage.includes && (
                                        <Table.Row>
                                            <Table.Cell colspan={4}>
                                                <ul>
                                                    {chosenPackage.includes.map((include, index) => (
                                                        <li key={`include${index}`}>{include.value}</li>
                                                    ))}
                                                </ul>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}

                                    {chosenPackage.excludes && (
                                        <Table.Row>
                                            <Table.Cell colSpan="4">
                                                <strong>Excludes</strong>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}

                                    {chosenPackage.excludes && (
                                        <Table.Row>
                                            <Table.Cell colspan={4}>
                                                <ul>
                                                    {chosenPackage.excludes.map((exclude, index) => (
                                                        <li key={`exclude${index}`}>{exclude.value}</li>
                                                    ))}
                                                </ul>
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        )}
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
