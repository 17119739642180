import React, { useState, useEffect, useCallback } from "react";
import { Button, Container, Grid, Segment } from "semantic-ui-react";
import ReactExport from "react-data-export";
import addSeconds from "date-fns/addSeconds";
import format from "date-fns/format";
import { getExportLeads } from "./exportActions";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadsExportPage(props) {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [usersFilterList, setUsersFilterList] = useState([]);
    const [leads, setLeads] = useState([]);
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [status, setStatus] = useState("All");
    const [country, setCountry] = useState("All");
    const [filterUser, setFilterUser] = useState("All");
    const [regional, setRegional] = useState("All");
    const [loadingData, setLoadingData] = useState(false);
    // const [leadsAll, setLeadsAll]= useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);
    useEffect(() => {
        const unsubscribe2 = firestore
            .collection("users")
            .where("isConsultant", "==", true)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });
                const externalConsultants = firestore
                    .collection("users")
                    .where("isExternal", "==", true)
                    .onSnapshot((snapshot) => {
                        snapshot.docs.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });
                        list.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
                        setUsersFilterList(list);
                    });
            });

        return () => {
            // unsubscribe();
            unsubscribe2();
        };
    }, []);

    let usersList = [
        {
            key: "All",
            text: "All Users",
            value: "All",
        },
    ];

    if (usersFilterList) {
        Object.keys(usersFilterList).forEach(function (key) {
            usersList.push({
                key: usersFilterList[key].id,
                // text: usersFilterList[key].displayName,
                text: usersFilterList[key].isExternal ? `${usersFilterList[key].displayName} (external consultant)` : usersFilterList[key].displayName,
                value: usersFilterList[key].displayName,
            });
        });
    }

    useEffect(() => {
        const timestamp1 = firebase.firestore.Timestamp.fromDate(new Date(dateFrom));
        const timestamp2 = firebase.firestore.Timestamp.fromDate(new Date(dateTo));

        setLoadingData(true);

        // firestore.collection("leads")
        //     .onSnapshot((snapshot) => {
        //         let list = [];

        //         snapshot.docs.forEach((doc) => {
        //             let docData = {...doc.data(), id: doc.id}

        //             let lead = {};
        //             lead.client_email = docData.client_email ? docData.client_email : "";
        //             lead.client_name = docData.client_name ? docData.client_name : "";
        //             lead.createDate = docData.createDate ? docData.createDate.toDate() : "";
        //             lead.assignDate = docData.assignDate ? docData.assignDate.toDate() : "";
        //             lead.status = docData.status ? docData.status : "";

        //             list.push(lead);
        //         })

        //         setLeadsAll(list);
        //         console.log(list)
        //     })

        const unsubscribe = firestore
            .collection("leads")
            .where("createDate", ">=", timestamp1)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    // console.table(doc.data());
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };

                        // console.log()

                        if (appObj.destination == undefined || appObj.destination == "") {
                            if (appObj.cfdb !== undefined && appObj.cfdb.line_items !== undefined && appObj.cfdb.line_items.length > 0) {
                                appObj.destination = appObj.cfdb.line_items[0].name;
                            } else if (appObj.cfdb !== undefined && appObj.cfdb.package_name !== undefined && appObj.cfdb.package_name !== "") {
                                appObj.destination = appObj.cfdb.package_name.split("(")[0];
                            } else if (appObj.cfdb !== undefined && appObj.cfdb.where_go !== undefined && appObj.cfdb.where_go !== "") {
                                appObj.destination = appObj.cfdb.where_go;
                            }
                        }

                        if (status == "All") {
                            if (doc.data().cfdb && doc.data().cfdb.source_url) {
                                appObj.web_source = doc.data().cfdb.source_url;
                            }

                            if (country == "All" && regional == "All") {
                                list.push(appObj);
                            } else if (country !== "All" && regional == "All") {
                                if (appObj.destination && appObj.destination.includes(country)) {
                                    list.push(appObj);
                                }
                            } else if (country == "All" && regional !== "All") {
                                if (appObj.destination && appObj.destination.includes(regional)) {
                                    list.push(appObj);
                                }
                            }
                        } else if (appObj.status === status) {
                            if (doc.data().cfdb && doc.data().cfdb.source_url) {
                                appObj.web_source = doc.data().cfdb.source_url;
                            }

                            if (country == "All" && regional == "All") {
                                list.push(appObj);
                            } else if (country !== "All" && regional == "All") {
                                if (appObj.destination && appObj.destination.includes(country)) {
                                    list.push(appObj);
                                }
                            } else if (country == "All" && regional !== "All") {
                                if (appObj.destination && appObj.destination.includes(regional)) {
                                    list.push(appObj);
                                }
                            }
                        }
                    }
                });

                // console.log(list);
                // console.log(filterUser);
                if (filterUser !== "All") {
                    list = list.filter((lead) => {
                        console.log(lead.userDisplayName, filterUser);
                        return lead.userDisplayName == filterUser;
                    });
                }
                console.log(list);
                // console.table(list);

                setLeads(list);
                setLoadingData(false);
            });

        return () => unsubscribe();
    }, [dateFrom, status, country, regional, filterUser]);

    const leadStatus = [
        { key: "All", text: "All", value: "All" },
        { key: "Assigned", text: "Assigned", value: "Assigned" },
        { key: "Booked", text: "Booked", value: "Booked" },
        { key: "Lost Lead", text: "Lost Lead", value: "Lost Lead" },
        { key: "Quoted", text: "Quoted", value: "Quoted" },
        { key: "Pending", text: "Pending", value: "Pending" },
        { key: "Follow Up", text: "Follow Up", value: "Follow Up" },
        { key: "Duplicate", text: "Duplicate", value: "Duplicate" },
        { key: "Awaiting Rates", text: "Awaiting Rates", value: "Awaiting Rates" },
        { key: "Travel out of date range", text: "Travel out of date range", value: "Travel out of date range" },
        { key: "Prospective", text: "Prospective", value: "Prospective" },
        { key: "Cancelled BKG", text: "Cancelled BKG", value: "Cancelled BKG" },
        { key: "Awaiting client feedback", text: "Awaiting client feedback", value: "Awaiting client feedback" },
    ];

    const leadRegional = [
        { key: "All", text: "All", value: "All" },
        { key: "Victoria Falls", text: "Victoria Falls", value: "Victoria Falls" },
        { key: "Zimbabwe", text: "Zimbabwe", value: "Zimbabwe" },
        { key: "Zambia", text: "Zambia", value: "Zambia" },
        { key: "Zanzibar", text: "Zanzibar", value: "Zanzibar" },
        { key: "Tanzania", text: "Tanzania", value: "Tanzania" },
        { key: "Botswana", text: "Botswana", value: "Botswana" },
        { key: "Kenya", text: "Kenya", value: "Kenya" },
        { key: "Namibia", text: "Namibia", value: "Namibia" },
        { key: "Mozambique", text: "Mozambique", value: "Mozambique" },
        { key: "Morocco", text: "Morocco", value: "Morocco" },
    ];

    const leadCountry = [
        { key: "All", text: "All", value: "All" },
        { key: "Thailand", text: "Thailand", value: "Thailand" },
        { key: "Bali", text: "Bali", value: "Bali" },
        { key: "Vietnam", text: "Vietnam", value: "Vietnam" },
        { key: "Hong Kong", text: "Hong Kong", value: "Hong Kong" },
        { key: "Philippines", text: "Philippines", value: "Philippines" },
        { key: "Singapore", text: "Singapore", value: "Singapore" },
        { key: "India", text: "India", value: "India" },
        { key: "China", text: "China", value: "China" },
        { key: "Mauritius", text: "Mauritius", value: "Mauritius" },
        { key: "Zanzibar", text: "Zanzibar", value: "Zanzibar" },
        { key: "Seychelles", text: "Seychelles", value: "Seychelles" },
        { key: "Madagascar", text: "Madagascar", value: "Madagascar" },
        { key: "Maldives", text: "Maldives", value: "Maldives" },
        { key: "Reunion", text: "Reunion", value: "Reunion" },
        { key: "Sri Lanka", text: "Sri Lanka", value: "Sri Lanka" },
        { key: "Austria", text: "Austria", value: "Austria" },
        { key: "Croatia", text: "Croatia", value: "Croatia" },
        { key: "Italy", text: "Italy", value: "Italy" },
        { key: "Germany", text: "Germany", value: "Germany" },
        { key: "France", text: "France", value: "France" },
        { key: "Greece", text: "Greece", value: "Greece" },
        { key: "Spain", text: "Spain", value: "Spain" },
        { key: "Switzerland", text: "Switzerland", value: "Switzerland" },
        { key: "Dubai", text: "Dubai", value: "Dubai" },
        { key: "Abu Dhabi", text: "Abu Dhabi", value: "Abu Dhabi" },
        { key: "Turkey", text: "Turkey", value: "Turkey" },
        { key: "Israel", text: "Israel", value: "Israel" },
        { key: "Egypt", text: "Egypt", value: "Egypt" },
        { key: "North America", text: "North America", value: "North America" },
        { key: "South America", text: "South America", value: "South America" },
        { key: "Central America", text: "Central America", value: "Central America" },
        { key: "New York", text: "New York", value: "New York" },
        { key: "Las Vegas", text: "Las Vegas", value: "Las Vegas" },
        { key: "Peru", text: "Peru", value: "Peru" },
        { key: "Brazil", text: "Brazil", value: "Brazil" },
        { key: "Orlando", text: "Orlando", value: "Orlando" },
    ];

    if (currentUserProfile.isLoaded === true && currentUserProfile.isEmpty === true) {
        props.history.push("/");
    }

    if (currentUserProfile.isSuper === false) {
        props.history.push("/");
    }

    if (leads && leads.length > 0) {
        for (var key in leads) {
            if (leads[key]["assignDate"] && leads[key]["assignDate"]["seconds"]) {
                leads[key].assignDate = format(addSeconds(new Date(0), leads[key]["assignDate"]["seconds"]), "d MMMM yyyy hh:mm:ii");
            }

            if (leads[key]["createDate"] && leads[key]["createDate"]["seconds"]) {
                leads[key].createDate = format(addSeconds(new Date(0), leads[key]["createDate"]["seconds"]), "d MMMM yyyy hh:mm:ii");
            }

            if (leads[key]["travel_date"] && leads[key]["travel_date"]["seconds"]) {
                leads[key].travel_date = format(addSeconds(new Date(0), leads[key]["travel_date"]["seconds"]), "d MMMM yyyy hh:mm:ii");
            }

            if (leads[key]["travel_date_from"] && leads[key]["travel_date_from"]["seconds"]) {
                leads[key].travel_date_from = format(addSeconds(new Date(0), leads[key]["travel_date_from"]["seconds"]), "d MMMM yyyy hh:mm:ii");
            }
        }
    }

    const updateDateFrom = useCallback((value) => {
        setDateFrom(value);
    }, []);
    function dateChange(value) {
        if (value !== undefined) {
            updateDateFrom(value);
        }
    }

    const updateDateTo = useCallback((value) => {
        setDateTo(value);
    }, []);
    function dateChangeTo(value) {
        if (value !== undefined) {
            updateDateTo(value);
        }
    }

    const updateStatus = useCallback((value) => {
        setStatus(value);
    }, []);
    function changeStatus(value) {
        if (value !== undefined) {
            updateStatus(value);
        }
    }

    // console.log(leads)

    return (
        <Container>
            <h2>Leads Export</h2>
            <Segment>
                <Formik
                    enableReinitialize="true"
                    initialValues={{ from: "", status: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form className="ui form" style={{ marginTop: 10, marginBottom: 20 }}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={2}>
                                        <label>
                                            <strong>From</strong>
                                        </label>
                                        <br />
                                        <DatePicker
                                            name="FromDate"
                                            labelTop="From"
                                            value={values.FromDate}
                                            selected={dateFrom}
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown={true}
                                            showMonthDropdown={true}
                                            dropdownMode="select"
                                            onChange={(value) => {
                                                dateChange(value);
                                                setFieldValue("FromDate", value.value);
                                            }}
                                            autcomplete="false"
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <label>
                                            <strong>To</strong>
                                        </label>
                                        <br />
                                        <DatePicker
                                            name="ToDate"
                                            labelTop="To"
                                            value={values.ToDate}
                                            selected={dateTo}
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown={true}
                                            showMonthDropdown={true}
                                            dropdownMode="select"
                                            min={dateFrom}
                                            onChange={(value) => {
                                                dateChangeTo(value);
                                                setFieldValue("ToDate", value.value);
                                            }}
                                            autcomplete="false"
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <MySelectInput
                                            labelTop="Status"
                                            name="status"
                                            options={leadStatus}
                                            value={values.status}
                                            placeholder="Leads Status"
                                            onChange={(field, value) => {
                                                changeStatus(value.value);
                                                setFieldValue("status", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <MySelectInput
                                            labelTop="Country"
                                            name="country"
                                            options={leadCountry}
                                            value={values.country}
                                            placeholder="Leads Country"
                                            onChange={(field, value) => {
                                                setCountry(value.value);
                                                setFieldValue("country", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <MySelectInput
                                            labelTop="Regional"
                                            name="regional"
                                            options={leadRegional}
                                            value={values.regional}
                                            placeholder="Leads Regional"
                                            onChange={(field, value) => {
                                                setRegional(value.value);
                                                setFieldValue("regional", value.value);
                                            }}
                                        />
                                    </Grid.Column>
                                    {/* {console.log(filterUser)}
                                    {console.table(usersList)} */}
                                    {currentUserProfile && !currentUserProfile.isExternal && (
                                        <Grid.Column width={3}>
                                            <MySelectInput
                                                labelTop="Consultant"
                                                name="filterUser"
                                                options={usersList}
                                                onChange={(field, value) => {
                                                    setFilterUser(value.value);
                                                    setFieldValue("filterUser", value.value);
                                                }}
                                                placeholder="Select a Consultant"
                                                removeClearable={true}
                                            />
                                        </Grid.Column>
                                    )}
                                    <Grid.Column width={16} style={{ textAlign: "right", marginTop: 20 }}>
                                        <ExcelFile
                                            element={
                                                <Button color="orange" loading={loadingData}>
                                                    Download Data
                                                </Button>
                                            }
                                        >
                                            <ExcelSheet data={leads} name="Leads">
                                                <ExcelColumn label="ID" value="id" />
                                                <ExcelColumn label="Client ID" value="clientId" />
                                                <ExcelColumn label="Client Name" value="client_name" />
                                                <ExcelColumn label="Client Email" value="client_email" />
                                                <ExcelColumn label="Client Phone" value="client_phone" />
                                                <ExcelColumn label="Agent" value="userDisplayName" />
                                                <ExcelColumn label="Date Created" value="createDate" />
                                                <ExcelColumn label="Destination" value="destination" />
                                                <ExcelColumn label="Enquiry Source" value="enquiry_source" />
                                                <ExcelColumn label="Form Name" value="form_name" />
                                                <ExcelColumn label="Has Files" value="hasFiles" />
                                                <ExcelColumn label="Date Assigned" value="assignDate" />
                                                <ExcelColumn label="Pickup Delay" value="pickupDelay" />
                                                <ExcelColumn label="Status" value="status" />
                                                <ExcelColumn label="Lead Type" value="leadType" />
                                                <ExcelColumn label="Travel Date" value="travel_date" />
                                                <ExcelColumn label="Travel Date From" value="travel_date_from" />
                                                <ExcelColumn label="Website Source" value="website_source" />
                                                <ExcelColumn label="Source" value="web_source" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Grid.Column>

                                    {/* <Grid.Column width={16} style={{ textAlign: "right", marginTop:20 }}>
                                        <ExcelFile element={<Button color="orange" loading={loadingData}>Download ALL</Button>}>
                                            <ExcelSheet data={leadsAll} name="Leads">
                                                <ExcelColumn label="Client Email" value="client_email" />
                                                <ExcelColumn label="Client Name" value="client_name" />
                                                <ExcelColumn label="Date Created" value="createDate" />
                                                <ExcelColumn label="Date Assigned" value="assignDate" />
                                                <ExcelColumn label="Status" value="status" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Grid.Column> */}
                                </Grid.Row>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Segment>
        </Container>
    );
}
