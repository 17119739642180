import React, { useEffect, useState } from "react";
import { Button, Table } from "semantic-ui-react";
import format from "date-fns/format";
import { openModal } from "../../../app/common/modals/modalReducer";
import { getUnassignedLeads } from "../leadActions";
import { useDispatch } from "react-redux";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import subYears from "date-fns/subYears";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadsDashboardPage() {
    const [leads, setLeads] = useState([]);
    const [loadingLeads, setLoadingLeads] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        // getUnassignedLeads().then(
        //     (res) => {
        //         if (res && res.length > 0) {
        //             setLeads(res);
        //         }
        //         setLoadingLeads(false);
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );

        const unsubscribe = firestore
            .collection("leads")
            .where("status", "==", "Unassigned")
            .orderBy("createDate", "desc")
            .onSnapshot(async (snapshot) => {
                let leads = await Promise.all(
                    snapshot.docs.map(async (doc) => {
                        if (doc.exists) {
                            let new_lead_data = doc.data();
                            new_lead_data.id = doc.id;

                            new_lead_data.dupes = 0;

                            let date_dupes = new Date(Date.now());
                            date_dupes = subYears(date_dupes, 1);

                            if (new_lead_data.client_email && new_lead_data.client_email !== "") {
                                let dupesRef = await firestore.collection("leads").where("client_email", "==", new_lead_data.client_email).where("createDate", ">", date_dupes).get();
                                let dupes = dupesRef.docs.length > 0 ? dupesRef.docs.length - 1 : 0;
                                new_lead_data.dupes = dupes;
                            }

                            return await new_lead_data;
                        }
                    })
                );

                setLeads(leads);
                setLoadingLeads(false);
            });

        return () => unsubscribe();
    }, []);

    return (
        <div>
            <Button color="orange" onClick={() => dispatch(openModal({ modalType: "LeadCreateModal", modalProps: { status: "Unassigned" } }))}>
                Add Unassigned Lead
            </Button>

            {loadingLeads === true ? (
                <LoadingComponent />
            ) : leads && leads.length > 0 ? (
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Enquired On</Table.HeaderCell>
                            <Table.HeaderCell>Division</Table.HeaderCell>
                            <Table.HeaderCell>Form Name</Table.HeaderCell>
                            <Table.HeaderCell>Client Name</Table.HeaderCell>
                            <Table.HeaderCell>Client Email</Table.HeaderCell>
                            <Table.HeaderCell>Duplicates</Table.HeaderCell>
                            <Table.HeaderCell>Destination</Table.HeaderCell>
                            <Table.HeaderCell>Package</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {leads.map((lead) => (
                            <Table.Row
                                key={lead.id}
                                onClick={() => dispatch(openModal({ modalType: "LeadUnassViewModal", modalProps: { status: "Unassigned", lead: lead } }))}
                                style={{ cursor: "pointer", color: lead.dupes > 0 ? "red" : "" }}
                            >
                                <Table.Cell>{lead.createDate ? format(lead.createDate.toDate(), "dd MMM yyyy HH:mm a") : ""}</Table.Cell>
                                <Table.Cell>{lead.website_division}</Table.Cell>
                                <Table.Cell>{lead.form_name}</Table.Cell>
                                <Table.Cell>{lead.client_name}</Table.Cell>
                                <Table.Cell>{lead.client_email}</Table.Cell>
                                <Table.Cell>{lead.dupes}</Table.Cell>
                                <Table.Cell>{lead.destination}</Table.Cell>
                                <Table.Cell>{lead.package}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            ) : (
                <div>No Leads found.</div>
            )}
        </div>
    );
}
