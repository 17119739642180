import React from "react";
import { connect, useSelector } from "react-redux";
import LeadUnassViewModal from "./LeadUnassViewModal";
import LeadCreateModal from "./LeadCreateModal";
import LeadGeneralEmailModal from "./LeadGeneralEmailModal";
import FlightSearchModal from "./FlightSearchModal";
import LeadLogsModal from "./LeadLogsModal";
import LeadScheduledEmailModal from "./LeadScheduledEmailModal";
import PackageTemplateModal from "./PackageTemplateModal";
import PackageTemplateCreateModal from "./PackageTemplateCreateModal";
import FlightSearchModalSub from "./FlightSearchModalSub";
import QuoteFlightEditModal from "./QuoteFlightEditModal";
import ClientCreateModal from "./ClientCreateModal";
import LeadBookingModal from "./LeadBookingModal";
import BookingEmailModal from "./BookingEmailModal";
import WhatsappUserModal from "./WhatsappUserModal";
import CommentEdit from "./CommentEdit";

export default function ModalManager(){

    const modalLookup = {
        LeadUnassViewModal,
        LeadCreateModal,
        LeadGeneralEmailModal,
        FlightSearchModal,
        LeadLogsModal,
        LeadScheduledEmailModal,
        PackageTemplateModal,
        PackageTemplateCreateModal,
        FlightSearchModalSub,
        QuoteFlightEditModal,
        ClientCreateModal,
        LeadBookingModal,
        BookingEmailModal,
        WhatsappUserModal,
        CommentEdit,
    };

    const currentModal = useSelector((state) => state.modals);
    let renderedModal;

    if(currentModal){
        const { modalType, modalProps } = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />;
    }

    return <span>{renderedModal}</span>;

}
