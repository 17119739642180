import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Header, Segment, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { createQuote } from "../leadActions";
import format from "date-fns/format";
import { openModal } from "../../../app/common/modals/modalReducer";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadDetailedQuotes(props) {
    const [quotes, setQuotes] = useState([]);
    let client = props.client;

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("quotes")
            .where("leadId", "==", props.lead.id)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setQuotes(list);
            });

        return () => unsubscribe();
    }, []);

    const dispatch = useDispatch();

    let defaultMoreInfo = 
        '<div style="font-family: Calibri; font-size: 14px; line-height: 20px;"><p>Good afternoon,</p><p>Thank you for your lovely enquiry submitted to our website. In order for me to offer you the best value quotation, I would like to get a few additional details to proceed.</p><ul><li>Preferred destination, country or city?</li><li>Proposed dates of travel?</li><li>How many passengers, and if children/teens please provide ages at time of travel?</li><li>Will you be departing from Johannesburg, Durban or Cape Town?</li><li>Any special requirement e.g. honeymoon?</li><li>Please advise what your budget is and I can provide a few appropriate options?</li><li>Please provide your telephone number to contact you on and best times to call?</li></ul><p>Once I have these details I can work on the quote. Please feel free to contact me should you have any queries.</p><p>Kind regards</p></div>';

        if(currentUserProfile && currentUserProfile.moreInfoMessage && currentUserProfile.moreInfoMessage !== ""){
            defaultMoreInfo = currentUserProfile.moreInfoMessage;
        }

    async function createNewQuote(lead, profile) {
        let newQuote = await createQuote(lead, profile);
        props.history.push("/quotes/edit/" + newQuote);
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Segment attached="top">
                <Header as="h3">Quotes</Header>
            </Segment>

            <Segment attached>
                <Table celled compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Quote No</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                            {/* <Table.HeaderCell>Email Log</Table.HeaderCell> */}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {quotes &&
                            quotes.length > 0 &&
                            quotes.map((quote) => (
                                <Table.Row key={quote.id}>
                                    <Table.Cell>
                                        {quote.createDate && quote.createDate !== "" ? format(quote.createDate.toDate(), "yyyyMMdd") + "-" + quote.quote_nr + "-" + quote.quote_rev : ""}
                                    </Table.Cell>
                                    <Table.Cell>{quote.createDate && quote.createDate !== "" ? format(quote.createDate.toDate(), "d MMM yyyy") : ""}</Table.Cell>
                                    <Table.Cell>
                                        <Button size="medium" color="orange" content="Edit" as={Link} to={"/quotes/edit/" + quote.id + "/"} />
                                    </Table.Cell>
                                    {/* <Table.Cell>
                                        <Button size="medium" color="orange" content="Logs" as={Link} to={"/quotes/edit/" + quote.id + "/"} />
                                    </Table.Cell> */}
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </Segment>

            <Segment attached="bottom" className="buttons_row">
                <Button size="medium" color="orange" content="Create Quote" onClick={() => createNewQuote(props.lead, props.currentUserProfile)} />

                {props.lead && props.lead.client_email && props.lead.client_email !== "" && (
                    <Button size="medium" color="orange" content="Logs" onClick={() => dispatch(openModal({ modalType: "LeadLogsModal", modalProps: { leadId: props.lead.id } }))} />
                )}

                <Button
                    size="medium"
                    disabled={!props.lead || !props.lead.client_email || props.lead.client_email.length === 0}
                    color="orange"
                    onClick={() =>
                        dispatch(
                            openModal({
                                modalType: "LeadGeneralEmailModal",
                                modalProps: {
                                    leadId: props.lead.id,
                                    quoteId: "",
                                    client_email: props.lead.client_email,
                                    initialValues: {
                                        subject: "",
                                        to: props.lead.client_email,
                                        message: props.currentUserProfile.fileUrl && props.currentUserProfile.fileUrl !== "" ? defaultMoreInfo + "<img src='" + props.currentUserProfile.fileUrl + "' />" : defaultMoreInfo,
                                        bcc: props.currentUserProfile.email,
                                        cc: client.client_cc_email,
                                    },
                                    emailType: "More Info",
                                    profile: props.currentUserProfile,
                                },
                            })
                        )
                    }
                    content="More Info Email"
                />
                <Button
                    size="medium"
                    disabled={!props.lead || !props.lead.client_email || props.lead.client_email.length === 0}
                    color="orange"
                    content="Scheduled Emails"
                    onClick={() => dispatch(openModal({ modalType: "LeadScheduledEmailModal", modalProps: { lead: props.lead, profile: props.currentUserProfile } }))}
                />
            </Segment>
        </div>
    );
}
