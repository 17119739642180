import { combineReducers } from "redux";
import modalReducer from "../common/modals/modalReducer";
import authReducer from "../../features/auth/authReducer";
import asyncReducer from "../async/asyncReducer";
import profileReducer from "../../features/profiles/profileReducer";
import leadReducer from "../../features/leads/leadReducer";
import { connectRouter } from "connected-react-router";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const rootReducer = (history) =>
    combineReducers({
        firebase: firebaseReducer,
        firestore: firestoreReducer,
        router: connectRouter(history),
        modals: modalReducer,
        auth: authReducer,
        async: asyncReducer,
        profile: profileReducer,
        lead: leadReducer,
    });

export default rootReducer;
