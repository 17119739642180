import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Segment, Header, Button, Grid, Form, Confirm, Table, Icon, Ref, Image } from "semantic-ui-react";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { saveAppBooking, removeBookingImage, getLead, getClientBooking, getClientItin, getClient, getClientUsers, getClientAppFiles } from "../../clients/clientActions";
import { removeItinerary, saveSortOrder, deleteBooking, importIncludes, importFlights, importItinerary } from "../../leads/leadActions";
import { openModal } from "../../../app/common/modals/modalReducer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function AppPage(props) {
    let clientId = props.match.params.clientId;
    let leadId = props.match.params.leadId;
    let bookingId = props.match.params.bookingId;
    const dispatch = useDispatch();

    const [lead, setLead] = useState([]);
    const [client_booking, setClient_booking] = useState([]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [client_itinerary, setClient_itinerary] = useState([]);
    const [client, setClient] = useState([]);
    const [client_users, setClientUsers] = useState([]);
    const [client_appFiles, setClientAppFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [removeItem, setRemoveItem] = useState("");
    const [removeType, setRemoveType] = useState("");
    const [travel_date, setTravel_date] = useState("");

    useEffect(() => {
        const unsubscribe = firestore
            .collection("bookings")
            .doc(bookingId)
            .onSnapshot((doc) => {
                let newClient = doc.data();
                newClient.id = doc.id;

                setClient_booking(newClient);

                if (newClient.travel_date !== undefined && newClient.travel_date !== "") {
                    // try {
                    // travel_date = newClient.travel_date.toDate();
                    setTravel_date(newClient.travel_date.toDate());
                    // } catch (e) {
                    //     console.log(e);
                    // }
                }
            });

        const unsubscribe2 = firestore
            .collection("bookings")
            .doc(bookingId)
            .collection("itinerary")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
                setClient_itinerary(list);
            });

        const unsubscribe3 = firestore
            .collection("leads")
            .doc(leadId)
            .onSnapshot((doc) => {
                let newLead = doc.data();
                newLead.id = doc.id;

                setLead(newLead);
            });

        const unsubscribe4 = firestore
            .collection("clients")
            .doc(clientId)
            .onSnapshot((doc) => {
                let newClient = doc.data();
                newClient.id = doc.id;

                setClient(newClient);
            });

        const unsubscribe5 = firestore
            .collection("bookings")
            .doc(bookingId)
            .collection("users")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                setClientUsers(list);
            });

        const unsubscribe6 = firestore
            .collection("bookings")
            .doc(bookingId)
            .collection("appFiles")
            .orderBy("sort_order")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                setClientAppFiles(list);
            });

        return () => {
            unsubscribe();
            unsubscribe2();
            unsubscribe3();
            unsubscribe4();
            unsubscribe5();
            unsubscribe6();
        };
    }, []);

    function openB() {
        setDeleteOpen(true);
    }

    function closeB() {
        setDeleteOpen(false);
    }

    const bookingType = [
        { key: "", text: "Select Booking Type", value: "" },
        { key: "Leisure", text: "Leisure", value: "Leisure" },
        { key: "Group", text: "Group", value: "Group" },
        { key: "Corporate", text: "Corporate", value: "Corporate" },
    ];

    const imageOptions = [
        { key: 1, text: "Bali 1", value: 1 },
        { key: 2, text: "Bali 2", value: 2 },
        { key: 3, text: "Bali 3", value: 3 },
        { key: 4, text: "Beach 1", value: 4 },
        { key: 5, text: "Beach 2", value: 5 },
        { key: 6, text: "Beach 3", value: 6 },
        { key: 7, text: "City 1", value: 7 },
        { key: 8, text: "City 2", value: 8 },
        { key: 9, text: "City 3", value: 9 },
        { key: 10, text: "Corporate 1", value: 10 },
        { key: 11, text: "Corporate 2", value: 11 },
        { key: 12, text: "Corporate 3", value: 12 },
        { key: 13, text: "Croatia 1", value: 13 },
        { key: 14, text: "Croatia 2", value: 14 },
        { key: 15, text: "Flights 1", value: 15 },
        { key: 16, text: "Flights 2", value: 16 },
        { key: 17, text: "Flights 3", value: 17 },
        { key: 18, text: "Italy 1", value: 18 },
        { key: 19, text: "Italy 2", value: 19 },
        { key: 20, text: "Italy 3", value: 20 },
        { key: 21, text: "London 1", value: 21 },
        { key: 22, text: "London 2", value: 22 },
        { key: 23, text: "London 3", value: 23 },
        { key: 24, text: "Maldives 2", value: 24 },
        { key: 25, text: "Maldives", value: 25 },
        { key: 26, text: "Mauritius 1", value: 26 },
        { key: 27, text: "Mauritius 2", value: 27 },
        { key: 28, text: "Mauritius 3", value: 28 },
        { key: 29, text: "New York 1", value: 29 },
        { key: 30, text: "New York 2", value: 30 },
        { key: 31, text: "New York 3", value: 31 },
        { key: 32, text: "Paris 1", value: 32 },
        { key: 33, text: "Paris 2", value: 33 },
        { key: 34, text: "Paris", value: 34 },
        { key: 35, text: "Pin 1", value: 35 },
        { key: 36, text: "Pin 2", value: 36 },
        { key: 37, text: "Pin 3", value: 37 },
        { key: 38, text: "Reunion 1", value: 38 },
        { key: 39, text: "Reunion 2", value: 39 },
        { key: 40, text: "Thailand 1", value: 40 },
        { key: 41, text: "Thailand 2", value: 41 },
        { key: 42, text: "Thailand 3", value: 42 },
        { key: 43, text: "Zanzibar", value: 43 },
    ];

    async function importIncludesData(type) {
        client_booking.id = bookingId;
        await importIncludes(leadId, client_booking, type);
    }

    async function importItineraryData(type) {
        client_booking.id = bookingId;
        await importItinerary(leadId, client_booking, type);
    }

    async function importFlightsData(type) {
        client_booking.id = bookingId;
        await importFlights(leadId, client_booking, type);
    }

    function close() {
        setOpen(false);
    }

    function openData(id, removeType) {
        setOpen(true);
        setRemoveItem(id);
        setRemoveType(removeType);
    }

    async function removeItineraryData() {
        await removeItinerary(client_booking, removeItem, removeType);
        setOpen(false);
    }

    async function deleteBookingData() {
        await deleteBooking(client, client_booking);

        setDeleteOpen(false);
        setOpen(false);

        props.history.push("/clients/edit/" + clientId);
    }

    async function onDragEnd(result) {
        const { destination, source, reason } = result;

        // Not a thing to do...
        if (!destination || reason === "CANCEL") {
            this.setState({
                draggingRowId: null,
            });
            return;
        }

        //Dropped back in same place
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        //What table are we sorting?
        let type = "";
        let sorted_data = {};

        if (result.destination.droppableId === "table_app_itinerary") {
            sorted_data = Object.assign([], client_itinerary);
            type = "itinerary";
        } else if (result.destination.droppableId === "table") {
            sorted_data = Object.assign([], client_appFiles);
            type = "appFiles";
        }

        //Change order of the items
        const file = sorted_data[source.index];
        sorted_data.splice(source.index, 1);
        sorted_data.splice(destination.index, 0, file);

        //Save order
        await saveSortOrder(sorted_data, type, client_booking);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && "lightblue",
        ...draggableStyle,
    });

    // console.log(client_booking);

    return (
        <div style={{ paddingBottom: 20 }}>
            <Segment attached="top">
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Header as="h3">
                                {client_booking.createDate
                                    ? "Booking - " + format(client_booking.createDate.toDate(), "yyyyMMdd") + "-" + client_booking.booking_nr + "-" + client_booking.booking_rev + " - App Details"
                                    : "Booking - App Details"}
                            </Header>
                        </Grid.Column>

                        <Grid.Column textAlign="right" width={12}>
                            {leadId && leadId !== "none" && <Button content="Back to Lead" as={Link} to={"/leads/edit/" + leadId} />}
                            <Button content="Back to Client" as={Link} to={"/clients/edit/" + clientId} />

                            {leadId && leadId !== "none" && (
                                <Button size="medium" color="orange" content="Booking" as={Link} to={"/booking/confirmation/" + lead.clientId + "/" + lead.id + "/" + client_booking.id} />
                            )}

                            <Button color="red" content="Delete Booking" onClick={openB} />

                            <Confirm
                                header="You are about to delete this booking"
                                content="This cannot be undone, are you sure?"
                                size="tiny"
                                open={deleteOpen}
                                onCancel={closeB}
                                onConfirm={() => deleteBookingData(client_booking)}
                                confirmButton="Yes"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment attached>
                <Formik
                    initialValues={client_booking}
                    enableReinitialize
                    validate={(values) => {
                        const error = {};

                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (values.travel_date && values.travel_date.seconds !== undefined) {
                            values.travel_date = new Date(values.travel_date.toDate());
                        } else {
                            values.travel_date = new Date(values.travel_date);
                        }

                        await saveAppBooking(values, client_booking);
                        setSubmitting(false);
                    }}
                >
                    {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <Grid.Row columns={2} verticalAlign="middle">
                                    <Grid.Column style={{ paddingTop: 20, paddingBottom: 20 }}>
                                        <Grid verticalAlign="middle">
                                            <Grid.Row>
                                                <Grid.Column width={5}>
                                                    <label>
                                                        <strong>Travel Date*</strong>
                                                    </label>
                                                </Grid.Column>

                                                <Grid.Column width={11}>
                                                    <DatePicker
                                                        name="travel_date"
                                                        value={values.travel_date}
                                                        selected={travel_date}
                                                        dateFormat="yyyy-MM-dd"
                                                        showYearDropdown={true}
                                                        showMonthDropdown={true}
                                                        dropdownMode="select"
                                                        onChange={(value) => {
                                                            setFieldValue("travel_date", format(value, "yyyy-MM-dd"));
                                                        }}
                                                        autcomplete="false"
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        <MyTextInput label="Booking Name*" name="destination" type="text" />

                                        <MySelectInput
                                            label="Booking Type"
                                            name="bookingType"
                                            options={bookingType}
                                            value={values.bookingType}
                                            placeholder="Select a Booking Type"
                                            onChange={(field, value) => setFieldValue("bookingType", value.value)}
                                        />

                                        <MyCheckboxInput type="checkbox" label="" labelcb="Show on App" name="showOnApp" />
                                        <p><strong>If you simply want to have this unticked but saving gives an error please remember to add a Travel Date even if not used!</strong></p>
                                    </Grid.Column>

                                    <Grid.Column textAlign="right">
                                        <MySelectInput
                                            label="Choose a Background"
                                            name="imageBackground"
                                            options={imageOptions}
                                            value={values.imageBackground}
                                            onChange={(field, value) => setFieldValue("imageBackground", value.value)}
                                            placeholder="Select a Background"
                                        />

                                        {client_booking.fileUrl && client_booking.fileUrl.length > 0 ? (
                                            <div>
                                                <Image size="small" style={{ marginTop: 20 }} src={client_booking.fileUrl} />
                                                <Button
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                    as="a"
                                                    color="orange"
                                                    content="Delete Image"
                                                    onClick={() => removeBookingImage(client_booking)}
                                                />
                                            </div>
                                        ) : (
                                            <Grid verticalAlign="middle">
                                                <Grid.Row>
                                                    <Grid.Column width={5}>
                                                        <label>
                                                            <strong>Upload Custom Background</strong>
                                                        </label>
                                                    </Grid.Column>

                                                    <Grid.Column width={11}>
                                                        <input
                                                            id="uploadFile"
                                                            name="uploadFile"
                                                            type="file"
                                                            onChange={(event) => {
                                                                setFieldValue("uploadFile", event.currentTarget.files[0]);
                                                            }}
                                                            className="form-control"
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Button disabled={isSubmitting} loading={isSubmitting} color="orange" type="submit" content="Save" />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Segment>

            <Segment attached="top">
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Header as="h3">
                                App Itinerary
                                <br />
                                (Upload files here)
                            </Header>
                        </Grid.Column>

                        <Grid.Column textAlign="right" width={12}>
                            {client_booking.booked_package_id && client_booking.booked_package_id !== "" && (
                                <Button as="a" onClick={() => importIncludesData("itinerary")}>
                                    Import Includes
                                </Button>
                            )}

                            {client_booking.booked_package_id && client_booking.booked_package_id !== "" && (
                                <Button as="a" onClick={() => importItineraryData("itinerary")}>
                                    Import Itinerary
                                </Button>
                            )}

                            {client_booking.booked_package_id && client_booking.booked_package_id !== "" && (
                                <Button as="a" onClick={() => importFlightsData("itinerary")}>
                                    Import Flight Details
                                </Button>
                            )}

                            <Button
                                color="orange"
                                as="a"
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modalType: "LeadBookingModal",
                                            modalProps: { modalType: "Add Itinerary", client_booking: client_booking, clientId: clientId, bookingType: "itinerary" },
                                        })
                                    )
                                }
                            >
                                Add Itinerary
                            </Button>

                            <Button color="orange" as="a" onClick={() => dispatch(openModal({ modalType: "QuoteFlightEditModal", modalProps: { client_booking } }))}>
                                Add Manual Flight
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {client_itinerary && client_itinerary.length > 0 && (
                <Segment attached>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.HeaderCell>Date / Time</Table.HeaderCell>
                                    <Table.HeaderCell>Item</Table.HeaderCell>
                                    <Table.HeaderCell>Booking Reference</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                        <Icon name="file alternate" />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                        <Icon name="camera" />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Droppable droppableId="table_app_itinerary">
                                {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                        <Table.Body {...provided.droppableProps}>
                                            {client_itinerary.map((itin, idx) => {
                                                return (
                                                    <Draggable draggableId={itin.id.toString()} index={idx} key={itin.id}>
                                                        {(provided, snapshot) => (
                                                            <Ref innerRef={provided.innerRef}>
                                                                <Table.Row
                                                                    key={itin.id + "_app"}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <Table.Cell>{itin.itineraryType}</Table.Cell>
                                                                    <Table.Cell>{itin.date ? format(itin.date.toDate(), "yyyy-MM-dd HH:mm") : ""}</Table.Cell>
                                                                    <Table.Cell>{itin.item}</Table.Cell>
                                                                    <Table.Cell>{itin.booking_ref}</Table.Cell>
                                                                    <Table.Cell textAlign="center">{itin.hasFiles && itin.hasFiles === true && <Icon name="check" />}</Table.Cell>
                                                                    <Table.Cell textAlign="center">
                                                                        <Icon
                                                                            style={{ cursor: "pointer" }}
                                                                            name={itin.hasBG && itin.hasBG === true ? "check" : "plus square outline"}
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    openModal({
                                                                                        modalType: "LeadBookingModal",
                                                                                        modalProps: {
                                                                                            itin,
                                                                                            client_booking: client_booking,
                                                                                            lead_booking: client_booking,
                                                                                            modalType: "Edit BG",
                                                                                            bookingId: bookingId,
                                                                                            bookingType: "itinerary",
                                                                                        },
                                                                                    })
                                                                                )
                                                                            }
                                                                        />
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="right" width={3} hidden={snapshot.isDragging === true}>
                                                                        {(itin.itineraryType === "Itinerary" || itin.itineraryType === "Hotel") && (
                                                                            <Button
                                                                                color="orange"
                                                                                as="a"
                                                                                onClick={() =>
                                                                                    dispatch(
                                                                                        openModal({
                                                                                            modalType: "LeadBookingModal",
                                                                                            modalProps: { itin, client_booking: client_booking, modalType: "Edit", bookingType: "itinerary" },
                                                                                        })
                                                                                    )
                                                                                }
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        )}

                                                                        <Button size="medium" color="red" content="Delete" as="a" onClick={() => openData(itin.id, "itinerary")} />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </Ref>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </Table.Body>
                                    </Ref>
                                )}
                            </Droppable>
                        </Table>
                    </DragDropContext>
                </Segment>
            )}

            <Segment attached="top">
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column>
                            <Header as="h3">App Users (Details that clients use to log into the App)</Header>
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            <Button
                                color="orange"
                                as="a"
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modalType: "LeadBookingModal",
                                            modalProps: { lead_booking: client_booking, bookingId: client_booking.id, client: client, modalType: "Add User", bookingId: bookingId },
                                        })
                                    )
                                }
                            >
                                Add User
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment attached>
                {client_users.length > 0 ? (
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Password</Table.HeaderCell>
                                <Table.HeaderCell>App User</Table.HeaderCell>
                                <Table.HeaderCell>User Type</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {client && client.appPassword && (
                                <Table.Row key={client.id}>
                                    <Table.Cell>{client.client_name}</Table.Cell>
                                    <Table.Cell>{client.client_email}</Table.Cell>
                                    <Table.Cell>{client.appPassword}</Table.Cell>
                                    <Table.Cell>{client.appUserUid && client.appUserUid.length > 0 ? "Yes" : ""}</Table.Cell>
                                    <Table.Cell>{client.userType}</Table.Cell>
                                    <Table.Cell textAlign="right" width={3}></Table.Cell>
                                </Table.Row>
                            )}
                            {client_users.map((user) => (
                                <>
                                    {user.client_email !== client.client_email ? (
                                        <Table.Row key={user.id}>
                                            <Table.Cell>{user.client_name}</Table.Cell>
                                            <Table.Cell>{user.client_email}</Table.Cell>
                                            <Table.Cell>{user.password}</Table.Cell>
                                            <Table.Cell>{user.appUserUid && user.appUserUid.length > 0 ? "Yes" : ""}</Table.Cell>
                                            <Table.Cell>{user.userType}</Table.Cell>
                                            <Table.Cell textAlign="right" width={3}>
                                                <Button
                                                    color="orange"
                                                    as="a"
                                                    onClick={() =>
                                                        dispatch(
                                                            openModal({
                                                                modalType: "LeadBookingModal",
                                                                modalProps: { user, lead_booking: client_booking, modalType: "Edit User", bookingType: "User", bookingId: client_booking.id },
                                                            })
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>

                                                <Button size="medium" color="red" content="Delete" as="a" onClick={() => openData(user.id, "users")} />
                                            </Table.Cell>
                                        </Table.Row>
                                    ) : null}
                                </>
                            ))}
                        </Table.Body>
                    </Table>
                ) : (
                    "No Users"
                )}
            </Segment>

            <Segment attached="top">
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column>
                            <Header as="h3">Files</Header>
                        </Grid.Column>

                        <Grid.Column textAlign="right"></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment attached>
                {client_appFiles.length > 0 ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>File Name</Table.HeaderCell>
                                    <Table.HeaderCell>File Type</Table.HeaderCell>
                                    <Table.HeaderCell>User</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Droppable droppableId="table">
                                {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                        <Table.Body {...provided.droppableProps}>
                                            {client_appFiles.map((file, idx) => {
                                                return (
                                                    <Draggable draggableId={file.id.toString()} index={idx} key={file.id}>
                                                        {(provided, snapshot) => (
                                                            <Ref innerRef={provided.innerRef}>
                                                                <Table.Row
                                                                    key={file.id}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <Table.Cell>{file.fileName}</Table.Cell>
                                                                    <Table.Cell>{file.fileType}</Table.Cell>
                                                                    <Table.Cell>{file.groupUserName}</Table.Cell>
                                                                </Table.Row>
                                                            </Ref>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </Table.Body>
                                    </Ref>
                                )}
                            </Droppable>
                        </Table>
                    </DragDropContext>
                ) : (
                    "No Files"
                )}
            </Segment>

            <Confirm
                header="You are about to delete this Itinerary Row"
                content="This cannot be undone, are you sure?"
                size="tiny"
                open={open}
                onCancel={close}
                onConfirm={() => removeItineraryData(client_booking, "confirmation")}
                confirmButton="Yes"
            />
        </div>
    );
}
