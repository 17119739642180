import React from "react";

const styleHtml = {
    fontFamily: "Gotham, sans-serif",
    fontSize: "13px",
    lineHeight: "17px",
};

const footerP = {
    fontFamily: "Gotham, sans-serif",
    fontSize: "14px",
    lineHeight: "20px",
    padding: "20px 0",
};

const orangeBar = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "5px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "16px",
};

const flightTbl = {
    backgroundColor: "#eef0f1",
    padding: "5px 15px",
    marginTop: "10px",
};

const clearHeading = {
    paddingLeft: "15px",
    paddingRight: "15px",
    marginTop: "10px",
};

const columns = {
    display: "Flex",
    justifyContent: "space-between",
};
const column = {
    width: "320px",
    backgroundColor: "#eef0f1",
    padding: "5px 15px",
    marginTop: "10px",
};

const columnClean = {
    width: "350px",
    marginTop: "10px",
};

const columnCleanBg = {
    backgroundColor: "#eef0f1",
    padding: "5px 15px",
};

const columnsTerms = {
    display: "flex",
    justifyContent: "space-between",
    //alignItems: "center",
};
const columnCleanTerms = {
    width: "330px",
    border: "1px solid black",
    padding: "20px 10px",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "15px",
    lineHeight: "17px",
    //height: "70px",
};

const QuotePDFFinal = ({ quote, lead, consultant, quotePackages }) => {
    let excludes = [];

    Object.keys(quotePackages).forEach(function (key) {
        if (quotePackages[key].excludes) {
            Object.keys(quotePackages[key].excludes).forEach(function (key2) {
                excludes.push(quotePackages[key].excludes[key2]);
            });
        }
    });

    return (
        <div style={styleHtml}>
            <div style={{ padding: "0 30px" }}>
                <div style={clearHeading}>CONSULTANT</div>

                <div style={columns}>
                    <div style={column}>{consultant.displayName}</div>
                    <div style={column}>CELL NO: {consultant.cellNumber}</div>
                </div>

                <div style={clearHeading}>MAIN PASSENGER CONTACT DETAILS</div>

                <div style={flightTbl}>FULL NAME: {quote.client_name}</div>

                <div style={columns}>
                    <div style={column}>EMAIL: {quote.client_email}</div>
                    <div style={column}>CELL NO: {quote.client_phone}</div>
                </div>

                <div style={columns}>
                    <div style={columnClean}>
                        <div style={orangeBar}>
                            <strong>EXCLUSIONS</strong>
                        </div>

                        <div style={columnCleanBg}>
                            <ul style={{ margin: 0, padding: "0 20px" }}>
                                {excludes.map((price, key) => (
                                    <li key={key}>{price.value}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div style={columnClean}>
                        <div style={orangeBar}>
                            <strong>VISA</strong>
                        </div>

                        <div style={columnCleanBg}>{quote.visa ? quote.visa : ""}</div>
                    </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                    <div style={orangeBar}>
                        <strong>DOCUMENTS</strong>
                    </div>
                    <div style={footerP}>
                        Please note that your passport must be valid for 6 months after return to South Africa with a min of 2 blank pages.
                        <br />
                        ** All passengers under 18 years must travel with an Unabridged Birth Certificate **
                        <br />
                        <br />
                        <strong>** DUE TO RAND VOLATILITY, ALL PRICES QUOTED ARE VALID IF BOOKED & PAID ON THE SAME DAY</strong>
                        <br />
                        <br />
                        ** All of our packages are tailor-made to suit your needs, so if you’d like to amend anything – please let me know. Please note that all quotes are subject to availability and
                        rate of exchange changes at the time of booking. The price is only guaranteed once a booking is made, confirmed AND payment is received in full.
                    </div>
                </div>

                <div style={columnsTerms}>
                    {/* {quote.destination_link && quote.destination_link !== "" && (
                        <div style={columnCleanTerms}>
                            <div>{quote.template_name} DESTINATION INFORMATION</div>
                            <a style={{ display: "block", textDecoration: "none", color: "black", marginTop: "10px" }} href={quote.destination_link}>
                                CLICK HERE
                            </a>
                            <div style={{ fontSize: "10px", lineHeight: "12px", marginTop: "10px" }}>
                                <div>
                                    Please take note of any health precautions to
                                    <br />
                                    <br/>
                                    your chosen destination.
                                </div>

                                {quote.wording && quote.wording !== "" && <div style={{ fontSize: 10, lineHeight: "14px" }} dangerouslySetInnerHTML={{ __html: quote.wording }}></div>}
                            </div>
                        </div>
                    )} */}

                    <div style={columnCleanTerms}>
                        <div>TERMS & CONDITIONS</div>
                        <a style={{ display: "block", textDecoration: "none", color: "black", marginTop: "10px" }} href="https://leads.igotravel.co.za/pdf/IGO_Travel_Terms_and_Conditions_2023.pdf">
                            CLICK HERE
                        </a>
                    </div>

                    <div style={columnCleanTerms}>
                        <div>ASATA Certificate</div>
                        <a style={{ display: "block", textDecoration: "none", color: "black", marginTop: "10px" }} href="https://leads.igotravel.co.za/pdf/ASATA_certifiate_to_March_2024.pdf">
                            CLICK HERE
                        </a>
                    </div>

                    <div style={columnCleanTerms}>
                        <div>IATA Certificate</div>
                        <a style={{ display: "block", textDecoration: "none", color: "black", marginTop: "10px" }} href="https://leads.igotravel.co.za/pdf/IATA_Certificate_2025.pdf">
                            CLICK HERE
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuotePDFFinal;
