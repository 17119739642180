import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Header, Segment, Button, Table, Grid } from "semantic-ui-react";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import format from "date-fns/format";
import { getConsultantUser, updateLead } from "../leadActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadsDetailedStatus(props) {
    const [user, setUsers] = useState([]);
    const [unassigning, setUnassigning] = useState(false);
    const [archiving, setArchiving] = useState(false);

    let iniValues = {};

    let lead = props.lead;
    let currentUserProfile = props.currentUserProfile;

    if (props.lead !== "" && props.lead !== undefined) {
        if (props.lead.status !== undefined) {
            iniValues.status = props.lead.status;
        } else {
            iniValues.status = "";
        }
        if (props.lead.userUid !== undefined) {
            iniValues.userUid = props.lead.userUid;
        } else {
            iniValues.userUid = "";
        }
        if (props.lead.enquiry_source !== undefined) {
            iniValues.enquiry_source = props.lead.enquiry_source;
        } else {
            iniValues.enquiry_source = "";
        }
        if (props.lead.enquiry_source_other !== undefined) {
            iniValues.enquiry_source_other = props.lead.enquiry_source_other;
        } else {
            iniValues.enquiry_source_other = "";
        }
        if (props.lead.package !== undefined) {
            iniValues.package = props.lead.package;
        } else {
            iniValues.package = "";
        }
        if (props.lead.client_name !== undefined) {
            iniValues.client_name = props.lead.client_name;
        } else {
            iniValues.client_name = "";
        }
        if (props.lead.client_email !== undefined) {
            iniValues.client_email = props.lead.client_email;
        } else {
            iniValues.client_email = "";
        }
        if (props.lead.client_phone !== undefined) {
            iniValues.client_phone = props.lead.client_phone;
        } else {
            iniValues.client_phone = "";
        }
        if (props.lead.departing_from !== undefined) {
            iniValues.departing_from = props.lead.departing_from;
        } else {
            iniValues.departing_from = "";
        }
        if (props.lead.destination !== undefined) {
            iniValues.destination = props.lead.destination;
        } else {
            iniValues.destination = "";
        }
        if (props.lead.travel_date_from !== undefined) {
            try {
                //Test if date is ok
                let FromDate = props.lead.travel_date_from.toDate();
                iniValues.travel_date_from = props.lead.travel_date_from;
            } catch (error) {
                iniValues.travel_date_from = "";
            }
        } else {
            iniValues.travel_date_from = "";
        }
        if (props.lead.travel_date !== undefined) {
            try {
                //Test if date is ok
                let FromDate = props.lead.travel_date.toDate();
                iniValues.travel_date = props.lead.travel_date;
            } catch (error) {
                iniValues.travel_date = "";
            }
        } else {
            iniValues.travel_date = "";
        }
        if (props.lead.number_travellers !== undefined) {
            iniValues.number_travellers = props.lead.number_travellers;
        } else {
            iniValues.number_travellers = "";
        }
        if (props.lead.website_division !== undefined) {
            iniValues.website_division = props.lead.website_division;
        } else {
            iniValues.website_division = "";
        }
        if (props.lead.leadType !== undefined) {
            iniValues.leadType = props.lead.leadType;
        } else {
            iniValues.leadType = "";
        }
        if (props.lead.lost_reason !== undefined) {
            iniValues.lost_reason = props.lead.lost_reason;
        } else {
            iniValues.lost_reason = "";
        }
    } else {
        iniValues = {
            status: "",
            userUid: "",
            enquiry_source: "",
            enquiry_source_other: "",
            package: "",
            client_name: "",
            client_email: "",
            client_phone: "",
            departing_from: "",
            destination: "",
            travel_date_from: "",
            travel_date: "",
            number_travellers: "",
            website_division: "",
            leadType: "",
            lost_reason: "",
        };
    }

    let FromDate = "";
    let ToDate = "";

    if (lead.travel_date_from !== undefined && lead.travel_date_from !== "") {
        try {
            FromDate = lead.travel_date_from.toDate();
        } catch (error) {
            // console.log(error);
        }
    }
    if (lead.travel_date !== undefined && lead.travel_date !== "") {
        try {
            ToDate = lead.travel_date.toDate();
        } catch (error) {
            // console.log(error);
        }
    }

    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();

    useEffect(() => {
        const unsubscribe = firestore
            .collection("users")
            .where("isConsultant", "==", true)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });
                const externalConsultants = firestore
                    .collection("users")
                    .where("isExternal", "==", true)
                    .onSnapshot((snapshot) => {
                        snapshot.docs.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });

                        setUsers(list);
                    });
            });

        return () => unsubscribe();
    }, []);

    const leadStatus = [
        { key: "", text: "Change Status", value: "" },
        { key: "Assigned", text: "Assigned", value: "Assigned" },
        { key: "Booked", text: "Booked", value: "Booked" },
        { key: "Lost Lead", text: "Lost Lead", value: "Lost Lead" },
        { key: "Quoted", text: "Quoted", value: "Quoted" },
        { key: "Pending", text: "Pending", value: "Pending" },
        { key: "Follow Up", text: "Follow Up", value: "Follow Up" },
        { key: "Duplicate", text: "Duplicate", value: "Duplicate" },
        { key: "Awaiting Rates", text: "Awaiting Rates", value: "Awaiting Rates" },
        { key: "Travel out of date range", text: "Travel out of date range", value: "Travel out of date range" },
        { key: "Prospective", text: "Prospective", value: "Prospective" },
        { key: "Cancelled BKG", text: "Cancelled BKG", value: "Cancelled BKG" },
        { key: "Awaiting client feedback", text: "Awaiting client feedback", value: "Awaiting client feedback" },
        { key: "Covid-19 Refund", text: "Covid-19 Refund", value: "Covid-19 Refund" },
        { key: "Covid-19 Postponed trip", text: "Covid-19 Postponed trip", value: "Covid-19 Postponed trip" },
    ];

    const sourceArr = [
        { key: "", text: "Select your Source", value: "" },
        { key: "Telephone", text: "Telephone", value: "Telephone" },
        { key: "Email", text: "Email", value: "Email" },
        { key: "Chat", text: "Chat", value: "Chat" },
        { key: "Website", text: "Website", value: "Website" },
        { key: "Social Media", text: "Social Media", value: "Social Media" },
        { key: "Repeat Client", text: "Repeat Client", value: "Repeat Client" },
        { key: "Referral", text: "Referral", value: "Referral" },
        { key: "Mailchimp/Mailer", text: "Mailchimp/Mailer", value: "Mailchimp/Mailer" },
        { key: "Other", text: "Other", value: "Other" },
    ];

    const webDivArr = [
        { key: "", text: "Select your Division", value: "" },
        { key: "Leisure", text: "Leisure", value: "Leisure" },
        { key: "Non-Leisure", text: "Non-Leisure", value: "Non-Leisure" },
    ];

    const leadType = [
        { key: "", text: "Select Lead Type", value: "" },
        { key: "Leisure", text: "Leisure", value: "Leisure" },
        { key: "Group", text: "Group", value: "Group" },
        { key: "Corporate", text: "Corporate", value: "Corporate" },
    ];

    const lostReason = [
        { key: "", text: "Select Lost Reason", value: "" },
        { key: "Booked Elsewhere", text: "Booked Elsewhere", value: "Booked Elsewhere" },
        { key: "Too Expensive", text: "Too Expensive", value: "Too Expensive" },
        { key: "Not Travelling", text: "Not Travelling", value: "Not Travelling" },
        { key: "Traveling Later", text: "Traveling Later", value: "Traveling Later" },
        { key: "No Availability", text: "No Availability", value: "No Availability" },
        { key: "No Response", text: "No Response", value: "No Response" },
    ];

    let today = new Date();
    var arrUsers = [];
    for (var key in user) {
        arrUsers.push({
            key: user[key].id,
            text: user[key].isExternal ? `${user[key].displayName} (external consultant)` : user[key].displayName,
            value: user[key].id,
        });
    }

    function changeDateTo(value) {
        ToDate = value;
    }

    function changeDateFrom(value) {
        FromDate = value;
    }

    async function UnassignLead(lead) {
        setUnassigning(true);

        lead.status = "Unassigned";
        lead.id = lastSegment;

        await updateLead(lead, currentUserProfile);

        setUnassigning(false);
    }

    async function ArchiveLead(lead) {
        setArchiving(true);

        lead.status = "Archived";
        lead.archived = true;
        lead.archivedOn = new Date(Date.now());
        lead.id = lastSegment;

        await updateLead(lead, currentUserProfile);

        setArchiving(false);
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Formik
                initialValues={iniValues}
                enableReinitialize="true"
                validate={(values) => {
                    const error = {};
                    if (!values.client_name) {
                        error.client_name = "Required";
                    }
                    if (!values.enquiry_source) {
                        error.enquiry_source = "Required";
                    }

                    if (!values.client_email && !values.client_phone) {
                        error.client_email = "Please enter either Email or Phone";
                        error.client_phone = "Please enter either Email or Phone";
                    }
                    return error;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    values.id = lastSegment;

                    await updateLead(values, currentUserProfile);
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form" style={{ marginBottom: 20 }}>
                        <Segment attached="top">
                            <Header as="h3">Status - {lead.status}</Header>
                        </Segment>

                        <Segment attached>
                            <Table collapsing basic="very">
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={8}>Created On:</Table.Cell>
                                        <Table.Cell>{lead.createDate ? format(lead.createDate.toDate(), "d MMMM yyyy HH:mm") : "N/A"}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Picked Up:</Table.Cell>
                                        <Table.Cell>{lead.assignDate ? format(lead.assignDate.toDate(), "d MMMM yyyy HH:mm") : "N/A"}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Pickup Delay:</Table.Cell>
                                        <Table.Cell>{lead.pickupDelay}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Current Owner:</Table.Cell>
                                        <Table.Cell>{lead.status !== "Unassigned" ? lead.userDisplayName : "Unassigned"}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>

                            {lead.status !== "Unassigned" && (
                                <>
                                    {console.log(arrUsers)}
                                    <MySelectInput
                                        label="Re-Assign to:"
                                        name="reassign"
                                        type="text"
                                        options={[{ value: "", label: "Nothing chosen" }, ...arrUsers]}
                                        placeholder="Re-Assign to"
                                        legacy={true}
                                    />
                                    <MySelectInput label="Change Status:" name="status" type="text" options={leadStatus} placeholder="Change Status" legacy={true} />
                                </>
                            )}

                            <MySelectInput label="Source*" name="enquiry_source" type="text" options={sourceArr} placeholder="Select your Source" legacy={true} />
                            <MyTextInput label="Source Other" name="enquiry_source_other" type="text" />
                            <MyTextInput label="Package" name="package" type="text" />

                            {!lead.clientId && <MyTextInput label="Name*" name="client_name" type="text" />}
                            {!lead.clientId && <MyTextInput label="Email" name="client_email" type="email" />}
                            {!lead.clientId && <MyTextInput label="Cell" name="client_phone" type="text" />}

                            <MyTextInput label="Departing From" name="departing_from" type="text" />
                            <MyTextInput label="Destination" name="destination" type="text" />
                            <br />

                            <label style={{ marginRight: 40 }}>
                                <b>Date of Travel (From)</b>
                            </label>
                            <DatePicker
                                name="travel_date_from"
                                label="Date of Travel (From)"
                                selected={FromDate}
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                dropdownMode="select"
                                onChange={(value) => {
                                    changeDateFrom(value);
                                    setFieldValue("travel_date_from", value);
                                }}
                                autcomplete="false"
                            />
                            <br />
                            <br />
                            <label style={{ marginRight: 60 }}>
                                <b>Date of Travel (To)</b>
                            </label>
                            <DatePicker
                                name="travel_date"
                                label="Date of Travel (To)"
                                selected={ToDate}
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown={true}
                                showMonthDropdown={true}
                                dropdownMode="select"
                                onChange={(value) => {
                                    changeDateTo(value);
                                    setFieldValue("travel_date", value);
                                }}
                                autcomplete="false"
                            />
                            <br />
                            <br />

                            {lead.travel_date_old && (
                                <Grid verticalAlign="middle">
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <label>
                                                <strong>Old Travel Date</strong>
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column width={11}>{lead.travel_date_old}</Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}

                            <MyTextInput label="Number of Passengers" name="number_travellers" type="text" />
                            <MySelectInput label="Website Division" name="website_division" options={webDivArr} placeholder="Select your Division" />
                            <MySelectInput label="Lead Type" name="leadType" options={leadType} placeholder="Select your Lead Type" />
                            {lead.status === "Lost Lead" && <MySelectInput label="Lost - Reason" name="lost_reason" options={lostReason} placeholder="Lost - Reason" />}
                        </Segment>

                        <Segment attached="bottom">
                            <div className="buttons_row">
                                {lead.status !== "Unassigned" && (
                                    <>
                                        <Button type="submit" disabled={isSubmitting} loading={isSubmitting} color="orange" size="large" content="Save" />
                                        {currentUserProfile.isSuper === true && (
                                            <Button disabled={unassigning} loading={unassigning} size="large" color="orange" content="Unassign" onClick={() => UnassignLead(lead)} />
                                        )}
                                        {currentUserProfile.isSuper === true && (
                                            <Button disabled={archiving} loading={archiving} size="large" color="red" content="Archive" onClick={() => ArchiveLead(lead)} />
                                        )}
                                    </>
                                )}
                            </div>
                        </Segment>
                    </form>
                )}
            </Formik>
        </div>
    );
}
