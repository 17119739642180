import React, { useState } from "react";
import { Segment, Button, Confirm, Header } from "semantic-ui-react";
import { deleteLead } from "../leadActions";

export default function LeadDetailedAdmin(props) {
    let lead = props.lead;
    const [open, setOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);

    function openD() {
        setOpen(true);
    }
    function close() {
        setOpen(false);
    }

    async function deleteLeadHere(lead) {
        setOpen(false);
        setDeleting(true);

        await deleteLead(lead);

        window.location.replace("/leads/landing");
    }

    return (
        <>
            <Segment attached="top">
                <Header as="h3">Admin</Header>
            </Segment>
            <Segment attached>
                {
                    <div>
                        <Button size="medium" color="red" content="Delete" onClick={openD} loading={deleting} disabled={deleting} />

                        <Confirm
                            header="You are about to delete this lead"
                            content="This cannot be undone, are you sure?"
                            size="tiny"
                            open={open}
                            onCancel={close}
                            onConfirm={() => deleteLeadHere(lead)}
                            confirmButton="Yes"
                        />
                    </div>
                }
            </Segment>
        </>
    );
}
