import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Header, Segment, Table, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { createBookingNew, getBookings } from "../clientActions";
import format from "date-fns/format";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function ClientCorporateBookings(props) {
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("bookings")
            .where("clientId", "==", props.client.id)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setBookings(list);
            });

        return () => unsubscribe();
    }, []);

    async function createBooking() {
        setLoading(true);
        await createBookingNew(props.client);
        setLoading(false);
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Segment attached="top">
                <Header as="h3">Bookings</Header>
            </Segment>

            <Segment attached>
                <Table celled compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Corporate</Table.HeaderCell>
                            <Table.HeaderCell>Booking No</Table.HeaderCell>
                            <Table.HeaderCell>Travel Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                <Icon name="file alternate" />
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">App</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {bookings &&
                            bookings.length > 0 &&
                            bookings.map((book) => (
                                <Table.Row key={book.id}>
                                    <Table.Cell textAlign="center">{!book.leadId && <Icon name="check" />}</Table.Cell>
                                    <Table.Cell>
                                        {book.createDate && book.createDate instanceof Date ? format(book.createDate.toDate(), "yyyyMMdd") + "-" + book.booking_nr + "-" + book.booking_rev : ""}
                                    </Table.Cell>
                                    <Table.Cell>{book.travel_date && book.travel_date instanceof Date ? format(book.travel_date.toDate(), "d MMM yyyy") : ""}</Table.Cell>
                                    <Table.Cell textAlign="center">{book.hasFiles && book.hasFiles === true && <Icon name="check" />}</Table.Cell>
                                    <Table.Cell textAlign="center">{!book.showOnApp || (book.showOnApp === true && <Icon name="check" />)}</Table.Cell>
                                    <Table.Cell>
                                        {!book.leadId ? (
                                            <Button size="medium" color="orange" content="Edit" as={Link} to={"/booking/confirmation/" + props.client.id + "/none/" + book.id} />
                                        ) : (
                                            <Button size="medium" color="orange" content="Edit" as={Link} to={"/booking/confirmation/" + props.client.id + "/" + book.leadId + "/" + book.id} />
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </Segment>

            <Segment attached="bottom" className="buttons_row">
                <Button size="medium" color="orange" content="Create Corporate Booking" loading={loading} onClick={createBooking} />
            </Segment>
        </div>
    );
}
