import React from "react";
import format from "date-fns/format";

const header = {
    padding: "20px 30px 0 30px",
    position: "relative",
    margin: "0 0 20px 0",
};

const header_condensed = {
    padding: "20px 30px 0 30px",
    position: "relative",
    margin: "0 0 0 0",
};

const quote_nr2 = {
    fontSize: "13px",
    lineHeight: "16px",
    textAlign: "right",
};

const BookingPDFHeader = ({ quote, lead, pagenr, condensed }) => {
    return (
        <div style={condensed === true ? header_condensed : header}>
            <table style={{ width: "100%" }}>
                <tr>
                    <td style={{ width: "60%" }} align="left" valign="top">
                        <img src="https://leads.igotravel.co.za/assets/logo_pdf2.png" alt="IGO Travel" />
                    </td>

                    <td style={quote_nr2}>
                        <p>
                            CONFIRMATION
                            <br />
                            BOOKING NO: {quote.createDate ? format(quote.createDate.toDate(), "yyyyMMdd") + "-" + quote.quote_nr + "-" + quote.quote_rev : ""}
                            <br />
                            CLIENT REF: {lead.l_nr}
                            <br />
                            Page {pagenr}
                        </p>
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default BookingPDFHeader;
