import cuid from "cuid";
import firebase from "../../app/config/firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore();

export async function createClient(client, profile) {
    //Check if client already exists
    let clientRef = firestore.collection("clients").where("client_email", "==", client.client_email);
    let clientSnap = await clientRef.get();

    if (clientSnap.docs.length > 0) {
        toast.error("This email address already exists");
        return false;
    } else {
        client.userUid = profile.id;
        client.userDisplayName = profile.displayName;
        client.createDate = new Date(Date.now());
        client.status = "New Lead";

        try {
            await firestore.collection("clients").add(client);
            toast.success("Client has been created");
            return true;
        } catch (error) {
            toast.error("Something went wrong");
        }
    }
}

export async function saveClient(client) {
    //Check if email is already being used exists
    let originalClient = (await firestore.collection("clients").doc(client.id).get()).data();;
    if (client.client_email !== originalClient.client_email) {
        let clientRef = firestore.collection("clients").where("client_email", "==", client.client_email);
        let clientSnap = await clientRef.get();
        if (clientSnap.docs.length > 0) {
            toast.error("This email address already exists");
            return false;
        }
    }      

    try {
        //First update the client document
        let id = client.id;
        delete client.id;

        await firestore.collection("clients").doc(id).update(client);

        //Update client status
        let quotesRef = firestore.collection("leads").where("clientId", "==", id);
        let quotesSnap = await quotesRef.get();

        let booked = 0;
        let quotes = 0;

        for (let i = 0; i < quotesSnap.docs.length; i++) {
            if (quotesSnap.docs[i].data().status === "Booked") {
                booked++;
            } else {
                quotes++;
            }
        }

        let client_status = "New Lead";
        if (booked === 0 && quotes > 1) {
            client_status = "Prospecitve Lead";
        }
        if (booked === 1) {
            client_status = "Booked Client";
        }
        if (booked > 1) {
            client_status = "Repeat Client";
        }

        await firestore.collection("clients").doc(id).update({ status: client_status });

        //Now run through all leads linked to this client and update details
        let batch = firestore.batch();

        let leadsQuery = await firestore.collection("leads").where("clientId", "==", id);
        let leadsQuerySnap = await leadsQuery.get();

        for (let i = 0; i < leadsQuerySnap.docs.length; i++) {
            let leadDocRef = await firestore.collection("leads").doc(leadsQuerySnap.docs[i].id);
            await batch.update(leadDocRef, {
                client_email: client.client_email,
                client_name: client.client_name,
                client_phone: client.client_phone,
                l_nr: client.client_l_number ? client.client_l_number : "",
            });

            //Get all bookings for each lead and add client ID
            let bookingDocRef = await firestore.collection("bookings").where("leadId", "==", leadsQuerySnap.docs[i].id);
            let bookingQuerySnap = await bookingDocRef.get();

            for (let i = 0; i < bookingQuerySnap.docs.length; i++) {
                let subBookDocRef = await firestore.collection("bookings").doc(bookingQuerySnap.docs[i].id);
                await batch.update(subBookDocRef, {
                    clientId: id,
                });
            }

            //Get all quotes for each lead and add client details
            let quoteDocRef = await firestore.collection("quotes").where("leadId", "==", leadsQuerySnap.docs[i].id);
            let quoteQuerySnap = await quoteDocRef.get();

            for (let i = 0; i < quoteQuerySnap.docs.length; i++) {
                let subquoteDocRef = await firestore.collection("quotes").doc(quoteQuerySnap.docs[i].id);
                await batch.update(subquoteDocRef, {
                    clientId: id,
                    client_email: client.client_email,
                    client_name: client.client_name,
                    client_phone: client.client_phone,
                });
            }
        }

        await batch.commit();

        toast.success("Client has been saved");
    } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
    }
}

export async function createBookingNew(client) {
    let booking = {};

    booking.createDate = new Date(Date.now());

    let bookingRef = firestore.collection("bookings").where("clientId", "==", client.id);
    let bookingQuery = await bookingRef.orderBy("booking_nr", "asc");
    let bookingQuerySnap = await bookingQuery.get();

    let max = 0;
    for (let i = 0; i < bookingQuerySnap.docs.length; i++) {
        if (bookingQuerySnap.docs[i].data().booking_nr > max) {
            max = bookingQuerySnap.docs[i].data().booking_nr;
        }
    }

    booking.booking_nr = max + 1;
    booking.booking_rev = 1;
    booking.clientId = client.id;

    try {
        await firestore.collection("bookings").add(booking);
        toast.success("Booking has been created");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function saveCorporateBooking(values, lead_booking) {
    try {
        if (values.uploadFile) {
            const file = values.uploadFile;
            delete values.uploadFile;

            const path = `bookings/${lead_booking}`;
            const options = {
                name: cuid() + "-" + file.name,
            };

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            values.fileName = file.name;
            values.fileUrl = uploadedFile;
            values.documentNameRand = options.name;
        }

        if (values.booked_package_id) {
            let packid = values.booked_package_id.split("|");
            values.packageId = packid[0];
            values.quoteId = packid[1];
        }

        await firestore.collection("bookings").doc(lead_booking).update(values);

        toast.success("Package has been saved");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function saveAppBooking(values, lead_booking) {
    try {
        if (values.uploadFile) {
            const file = values.uploadFile;
            delete values.uploadFile;

            const path = `bookings/${lead_booking.id}`;
            const options = {
                name: cuid() + "-" + file.name,
            };

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            values.fileName = file.name;
            values.fileUrl = uploadedFile;
            values.documentNameRand = options.name;
        }

        if (values.booked_package_id) {
            let packid = values.booked_package_id.split("|");
            values.packageId = packid[0];
            values.quoteId = packid[1];
        }

        let valueObj = Object.assign({}, values);

        await firestore.collection("bookings").doc(lead_booking.id).update(valueObj);

        toast.success("Package has been saved");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function removeBookingImage(booking){
    try {
        await firebase
            .deleteFile(`bookings/${booking.id}/${booking.documentNameRand}`)
            .then(function () {
                console.log("file del went ok");
            })
            .catch(function (error) {
                console.log(error);
            });

        let bookingRef = firestore.collection("bookings").doc(booking.id);

        await bookingRef.update({
            fileName: firebase.firestore.FieldValue.delete(),
            documentNameRand: firebase.firestore.FieldValue.delete(),
            fileUrl: firebase.firestore.FieldValue.delete(),
        });

        toast.success("Background Image removed");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}


export async function createLeadNew(client, profile) {
    let lead = {};

    lead.createDate = new Date(Date.now());
    lead.assignDate = new Date(Date.now());

    lead.userUid = profile.id;
    lead.userDisplayName = profile.displayName;
    lead.status = "Assigned";

    lead.clientId = client.id;
    lead.client_email = client.client_email;
    lead.client_name = client.client_name;
    lead.client_phone = client.client_phone;
    lead.l_nr = client.client_l_number ? client.client_l_number : "";

    try {
        // console.log(lead);

        await firestore.collection("leads").add(lead);
        toast.success("Lead has been created");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function getClients() {
    return await firestore.collection("clients").get();
}

export async function getClient(clientId) {
    return await firestore.collection("clients").doc(clientId).get();
}

export async function getClientBooking(bookingId) {
    return await firestore.collection("bookings").doc(bookingId).get();
}

export async function getBookings(clientId) {
    if (clientId !== undefined) {
        return await firestore.collection("bookings").where("clientId", "==", clientId).get();
    }
}

export async function getLead(leadId) {
    return await firestore.collection("leads").doc(leadId).get();
}

export async function getLeads(clientId) {
    return await firestore.collection("leads").where("clientId", "==", clientId).get();
}

export async function getQuote(quoteId) {
    return await firestore.collection("quotes").doc(quoteId).get();
}

export async function getAllQuoteTemplates() {
    return await firestore.collection("quote_templates").get();
}

export async function getClientConfirmation(bookingId) {
    return await firestore.collection("bookings").doc(bookingId).collection("confirmation").orderBy("sort_order").get();
}

export async function getTraverllers(bookingId) {
    return await firestore.collection("bookings").doc(bookingId).collection("travellers").orderBy("sort_order").get();
}

export async function getQuotePackage(quoteId) {
    return await firestore.collection("quotes").doc(quoteId).collection("packages").get();
}

export async function getClientPayment(bookingId) {
    return await firestore.collection("payments").where("bookingId", "==", bookingId).get();
}
export async function getClientItin(bookingId) {
    return await firestore.collection("bookings").doc(bookingId).collection("itinerary").orderBy("sort_order").get();
}

export async function getClientAppFiles(bookingId) {
    return await firestore.collection("bookings").doc(bookingId).collection("appFiles").orderBy("sort_order").get();
}

export async function getClientUsers(bookingId) {
    return await firestore.collection("bookings").doc(bookingId).collection("users").get();
}

export async function deleteAppUser(userUid) {
    await firestore.collection('users')
        .where('id','==',userUid)
        .get()
        .then(querySnapshot=>{
            querySnapshot.forEach(doc=>{
                doc.ref.delete();
            })
        })

    let query = await firestore.collection('clients')
        .where('appUserUid', '==', userUid)
        .get()
    query.docs[0].ref.update({
        appUserUid: firebase.firestore.FieldValue.delete(),
        appPassword: firebase.firestore.FieldValue.delete()
      });
}