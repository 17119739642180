import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Header, Button, Grid, Confirm } from "semantic-ui-react";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { saveCorporateBooking, getLead, getQuote, getAllQuoteTemplates, getQuotePackage, getClient, getClientConfirmation, getTraverllers, getClientPayment } from "../../clients/clientActions";
import { getPackages, deleteBooking } from "../../leads/leadActions";
import { openModal } from "../../../app/common/modals/modalReducer";
import BookingPayments from "./BookingPayments";
import { Formik } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTinyMCE from "../../../app/common/form/MyTinyMCE";
import { renderToString } from "react-dom/server";
import { getPackageFlights } from "../../quotes/quoteActions";
import { toast } from "react-toastify";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";
import BookingConfirmations from "./BookingConfirmations";
import BookingTravellers from "./BookingTravellers";
import ErrorBoundary from "../../../app/layout/ErrorBoundary";
import BookingPDFPackage from "../BookingPDF/BookingPDFPackage";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function BookingPage(props) {
    // const { currentUserProfile } = useSelector((state) => state.auth);
    const { currentUserProfile } = useSelector((state) => state.profile);

    const dispatch = useDispatch();
    let bookingId = props.match.params.bookingId;
    let leadId = props.match.params.leadId;
    let clientId = props.match.params.clientId;

    const [client_booking, setClient_booking] = useState([]);
    const [lead, setLead] = useState([]);
    const [quote, setQuote] = useState([]);
    const [quote_templates, setQuote_templates] = useState([]);
    const [quotePackages, setQuotePackages] = useState([]);
    const [client, setClient] = useState([]);
    // const [openB, setOpenB] = useState(false);
    const [openDlt, setOpenDlt] = useState(false);
    const [packagesList, setPackagesList] = useState([]);
    const [removeItem, setRemoveItem] = useState([]);
    const [removeItemType, setRemoveItemType] = useState([]);
    // const [packageId, setPackageId] = useState("None");
    // const [packageData, setPackageData] = useState({ total_booking_price: "", overview: "" });
    // const [packageIds, setPackageIds] = useState("");
    const [printing, setPrinting] = useState(false);
    const [deleting, setdeleting] = useState(false);
    const [totalValue, setTotalValue] = useState("");

    useEffect(() => {
        const unsubscribe = firestore
            .collection("bookings")
            .doc(bookingId)
            .onSnapshot(
                (res) => {
                    // getClientBooking(bookingId).then(
                    // (res) => {
                    if (res.exists) {
                        let data = res.data();

                        if (!data.id) {
                            data.id = bookingId;
                        }

                        if (!data.cancellation_fee) {
                            data.cancellation_fee = "";
                        }

                        if (!data.changes_amendments) {
                            data.changes_amendments = "";
                        }

                        if (!data.total_booking_price) {
                            data.total_booking_price = "";
                        }
                        // } else {
                        //     setTotalValue(data.total_booking_price);
                        // }

                        if (!data.overview) {
                            data.overview = "";
                        }

                        if (!data.booked_package_id) {
                            data.booked_package_id = "";
                        }

                        if (!data.pricing_new_pg) {
                            data.pricing_new_pg = false;
                        }

                        if (!data.total_discount) {
                            data.total_discount = "";
                        }

                        if (!data.priceAfter) {
                            data.priceAfter = data.total_booking_price;
                        }

                        if (data.quoteId && data.quoteId !== "None") {
                            //convertHtmlToPdf
                            firestore
                                .collection("quotes")
                                .doc(data.quoteId)
                                .onSnapshot((snapshot) => {
                                    setQuote(snapshot.data());
                                    // console.log(snapshot.data())
                                });

                            //convertHtmlToPdf

                            firestore
                                .collection("quotes")
                                .doc(data.quoteId)
                                .collection("packages")
                                .onSnapshot((snapshot) => {
                                    let packageList = [];

                                    snapshot.docs.forEach((doc) => {
                                        if (doc.exists) {
                                            let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                            packageList.push(appObj);

                                            // console.log(appObj);

                                            // price

                                            // total_booking_price

                                            // if (!data.price || data.price == "") {
                                            //     data.total_booking_price = appObj.price ? appObj.price : 0;

                                            //     // firestore.collection("bookings").doc(bookingId).update({total_booking_price: data.total_booking_price});
                                            // }

                                            // if (!data.total_discount || data.total_discount == "") {
                                            //     data.total_discount = appObj.discount ? appObj.discount : 0;

                                            //     // firestore.collection("bookings").doc(bookingId).update({total_discount: data.total_discount});
                                            // }

                                            // if (!data.priceAfter || data.priceAfter == "") {
                                            //     data.priceAfter = appObj.priceAfter ? appObj.priceAfter : appObj.total_booking_price;
                                            //     // firestore.collection("bookings").doc(bookingId).update({priceAfter: data.priceAfter});
                                            // }
                                        }
                                    });

                                    setQuotePackages(packageList);
                                });
                        }

                        // console.log(data)

                        // console.log(data)

                        setClient_booking(data);
                    }
                },
                (err) => {
                    console.log(err);
                }
            );

        //convertHtmlToPdf
        //BookingEmailModal
        //app button
        if (leadId && leadId !== "" && lead && lead !== "" && lead.length === 0) {
            firestore
                .collection("leads")
                .doc(leadId)
                .onSnapshot((snapshot) => {
                    setLead(snapshot.data());
                });
        }

        //convertHtmlToPdf
        //BookingEmailModal
        const unsubscribe2 = firestore.collection("quote_templates").onSnapshot((snapshot) => {
            let templateList = [];

            snapshot.docs.forEach((doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                    templateList.push(appObj);
                }
            });

            setQuote_templates(templateList);
        });

        //deleteBookingData
        if (client !== "None" && (!client || client.length === 0 || client === "")) {
            firestore
                .collection("clients")
                .doc(clientId)
                .onSnapshot((snapshot) => {
                    setClient(snapshot.data());
                });
        }

        if (leadId !== "None" && (!packagesList || packagesList.length === 0 || packagesList === "")) {
            firestore
                .collection("quotes")
                .where("leadId", "==", leadId)
                .onSnapshot(async (snapshot) => {
                    let packagesList = [];

                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            let quote_id = doc.id;
                            let quote_nr = format(doc.data().createDate.toDate(), "yyyyMMdd") + "-" + doc.data().quote_nr + "-" + doc.data().quote_rev;

                            firestore
                                .collection("quotes")
                                .doc(quote_id)
                                .collection("packages")
                                .onSnapshot((snapshot2) => {
                                    snapshot2.docs.forEach((doc2) => {
                                        let cur_package = {
                                            key: doc2.id + "|" + quote_id,
                                            text: quote_nr + " - " + doc2.data().package_name,
                                            value: doc2.id + "|" + quote_id,
                                            price: doc2.data().price,
                                            discount: doc2.data().discount,
                                            priceAfter: doc2.data().priceAfter,
                                            holiday_description: doc2.data().holiday_description,
                                        };
                                        packagesList.push(cur_package);
                                    });
                                });
                        }
                    });

                    const someTimeoutAction = () => {
                        return new Promise((resolve) => {
                            setTimeout(function () {
                                resolve(packagesList);
                            }, 500);
                        });
                    };

                    const status = await someTimeoutAction();

                    setPackagesList(status);
                });
        }

        return () => {
            unsubscribe();
            unsubscribe2();
        };
    }, []);

    const updateQuote = useCallback(async (quote) => {
        //convertHtmlToPdf
        let all = [];

        await firestore
            .collection("quotes")
            .doc(quote.id)
            .onSnapshot((snapshot) => {
                setQuote(snapshot.data());
                all = snapshot.data();
            });

        const someTimeoutAction = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(all);
                }, 500);
            });
        };

        const status = await someTimeoutAction();

        return status;
    }, []);

    function openDelete() {
        setOpenDlt(true);
    }
    function closeDlt() {
        setOpenDlt(false);
    }

    function deleteBookingData(client_booking, client) {
        let leadId = client_booking.leadId;
        deleteBooking(client, client_booking);

        if (typeof leadId !== "undefined") {
            props.history.push("/leads/edit/" + leadId);
        } else {
            props.history.push("/clients/edit/" + client.id);
        }
    }

    async function convertHtmlToPdf(quote) {
        setPrinting(true);

        if (!quote.id) {
            quote.id = client_booking.quoteId;
        }

        // let newQuote = await updateQuote(quote);

        let quoteNew = quote;

        //Find Quote template
        Object.keys(quote_templates).forEach(function (key) {
            if (quote_templates[key].id === quote.template_id) {
                quoteNew.templateUrl = quote_templates[key].fileUrl;
                quoteNew.template_name = quote_templates[key].template_name;
                quoteNew.visa = quote_templates[key].visa;
                quoteNew.destination_link = quote_templates[key].destination_link;
                quoteNew.wording = quote_templates[key].wording ? quote_templates[key].wording : "";
            }
        });

        let flightlist = [];

        firestore
            .collection("quotes")
            .doc(quote.id)
            .collection("packages")
            .onSnapshot((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        firestore
                            .collection("quotes")
                            .doc(quote.id)
                            .collection("packages")
                            .doc(doc.id)
                            .collection("flights")
                            .onSnapshot((snapshot2) => {
                                if (snapshot2.docs && snapshot2.docs.length > 0) {
                                    snapshot2.docs.forEach((doc2) => {
                                        let flight = doc2.data();
                                        flightlist.push(flight);
                                    });
                                }
                            });
                    }
                });
            });

        const someTimeoutAction = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(flightlist);
                }, 500);
            });
        };

        const flights = await someTimeoutAction();

        if (flights.length > 0) {
            flights.sort((a, b) => (a.departDateTime > b.departDateTime ? 1 : -1));
        }

        let client_confirmation_list = {};

        firestore
            .collection("bookings")
            .doc(bookingId)
            .collection("confirmation")
            .orderBy("sort_order")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                client_confirmation_list = list;
            });

        const someTimeoutActionClient = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(client_confirmation_list);
                }, 500);
            });
        };

        const client_confirmation = await someTimeoutActionClient();

        let travellers_list = {};

        firestore
            .collection("bookings")
            .doc(bookingId)
            .collection("travellers")
            .orderBy("sort_order")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                travellers_list = list;
            });

        const someTimeoutActionTravellers = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(travellers_list);
                }, 500);
            });
        };

        const travellers = await someTimeoutActionTravellers();

        let payments_list = {};

        firestore
            .collection("payments")
            .where("bookingId", "==", client_booking.id)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                payments_list = list;
            });

        const someTimeoutActionPayment = () => {
            return new Promise((resolve) => {
                setTimeout(function () {
                    resolve(payments_list);
                }, 500);
            });
        };

        const payments = await someTimeoutActionPayment();

        let qtPackage = [];
        let breakdown = [];

        // console.log(quoteNew)

        if (quotePackages && quotePackages.length > 0) {
            Object.keys(quotePackages).forEach(function (key) {
                if (quotePackages[key].id == client_booking.packageId) {
                    if (quotePackages[key].price_breakdown) {
                        let payments = quotePackages[key].price_breakdown;
                        Object.keys(payments).forEach(function (key2) {
                            // console.log(payments[key2].value)

                            if (payments[key2].value) {
                                breakdown.push(payments[key2].value);
                            }
                        });
                    }

                    qtPackage = quotePackages[key];
                }
            });
        }

        const pdfPackage = renderToString(
            <BookingPDFPackage
                qtPackage={qtPackage}
                breakdown={breakdown}
                client_booking={client_booking}
                quote={quoteNew}
                lead={lead}
                quote_templates={quote_templates}
                client_confirmation={client_confirmation}
                travellers={travellers}
                flights={flights}
                payments={payments}
                condensed={client_booking.condensed_pdf === true ? true : false}
            />
        );

        let html = pdfPackage;

        fetch("https://v2018.api2pdf.com//chrome/html", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "104a3c14-8782-4ee5-885f-000b5a7fb8a1",
            },
            body: JSON.stringify({
                html: html,
                inlinePdf: true,
                fileName: "test.pdf",
                options: {
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                },
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.success === true) {
                    window.open(res.pdf);
                } else {
                    // console.log(res);

                    toast.error("Something went wrong");
                }

                setPrinting(false);
            });
    }

    const SignupSchema = Yup.object().shape({
        cancellation_fee: Yup.string().required("Required"),
        changes_amendments: Yup.string().required("Required"),
        total_booking_price: Yup.string().required("Required"),
    });

    // console.log(currentUserProfile)

    return (
        <>
            <Segment attached="top">
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Header as="h3">
                                {client_booking.createDate
                                    ? "Booking - " + format(client_booking.createDate.toDate(), "yyyyMMdd") + "-" + client_booking.booking_nr + "-" + client_booking.booking_rev + " - Confirmation"
                                    : "Booking"}
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign="right" width={12}>
                            {leadId && leadId !== "none" && client_booking.booked_package_id && client_booking.booked_package_id !== "" && (
                                <Button content="Print Booking PDF" loading={printing} onClick={() => convertHtmlToPdf(quote)} />
                            )}
                            {leadId && leadId !== "none" && client_booking.booked_package_id && client_booking.booked_package_id !== "" && currentUserProfile && currentUserProfile !== "None" && (
                                <Button
                                    content="Email Booking"
                                    onClick={() =>
                                        dispatch(
                                            openModal({
                                                modalType: "BookingEmailModal",
                                                modalProps: {
                                                    leadId: client_booking.leadId,
                                                    bookingId: client_booking.id,
                                                    quoteId: client_booking.quoteId,
                                                    client_email: lead.client_email,
                                                    initialValues: {
                                                        cc: client.client_cc_email,
                                                        bcc: currentUserProfile.email,
                                                        message:
                                                            currentUserProfile.fileUrl && currentUserProfile.fileUrl !== ""
                                                                ? currentUserProfile.defaultBookingMessage + "<img src='" + currentUserProfile.fileUrl + "' />"
                                                                : currentUserProfile.defaultBookingMessage,
                                                    },
                                                    emailType: "Email Booking",

                                                    client_booking,
                                                    quote,
                                                    quote_templates,
                                                    quotePackages,
                                                    lead,
                                                    currentUserProfile,
                                                },
                                            })
                                        )
                                    }
                                />
                            )}

                            {leadId && leadId !== "none" && <Button content="Back to Lead" as={Link} to={"/leads/edit/" + leadId} />}
                            <Button content="Back to Client" as={Link} to={"/clients/edit/" + clientId} />

                            {leadId && leadId !== "none" && (
                                <Button size="medium" color="orange" content="App" as={Link} to={"/booking/app/" + lead.clientId + "/" + lead.id + "/" + client_booking.id} />
                            )}

                            <Button color="red" content="Delete Booking" loading={deleting} onClick={openDelete} />

                            <Confirm
                                header="You are about to delete this booking"
                                content="This cannot be undone, are you sure?"
                                size="tiny"
                                open={openDlt}
                                onCancel={closeDlt}
                                onConfirm={() => deleteBookingData(client_booking, client)}
                                confirmButton="Yes"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment attached>
                <Formik
                    enableReinitialize="true"
                    initialValues={client_booking}
                    validationSchema={SignupSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        // console.log("form", values);
                        // values.total_booking_price = totalValue;
                        // values.total_discount =
                        console.log(values.total_booking_price);
                        setClient_booking(values);
                        await saveCorporateBooking(values, bookingId);
                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <div style={{ marginBottom: "10px" }}>{lead && lead.l_nr && "L Number: " + lead.l_nr}</div>

                            <Grid>
                                <Grid.Row columns={2} verticalAlign="middle">
                                    <Grid.Column style={{ paddingTop: 20, paddingBottom: 20 }}>
                                        {leadId && leadId !== "none" && packagesList && packagesList.length > 0 && (
                                            <MySelectInput
                                                label="Choose Package"
                                                name="booked_package_id"
                                                options={packagesList}
                                                placeholder="Select a Package"
                                                value={values.booked_package_id}
                                                onChange={(field, value) => {
                                                    // console.log(value);
                                                    //Loop over options and set price and overview
                                                    Object.keys(value.options).forEach(function (key) {
                                                        if (value.options[key].key === value.value) {
                                                            if (value.options[key].price) {
                                                                setFieldValue("total_booking_price", value.options[key].price);
                                                            }

                                                            // if (value.options[key].discount) {
                                                            //     setFieldValue("total_discount", value.options[key].discount);
                                                            // } else {
                                                            //     setFieldValue("total_discount", "0");
                                                            // }

                                                            // if (value.options[key].priceAfter) {
                                                            //     setFieldValue("priceAfter", value.options[key].priceAfter);
                                                            //     setTotalValue(value.options[key].priceAfter);
                                                            // } else {
                                                            //     setFieldValue("priceAfter", value.options[key].price);
                                                            //     setTotalValue(value.options[key].price);
                                                            // }

                                                            if (value.options[key].holiday_description) {
                                                                setFieldValue("overview", value.options[key].holiday_description);
                                                            }
                                                        }
                                                    });

                                                    //Set the chosen package
                                                    setFieldValue("booked_package_id", value.value);

                                                    //Set other info about package
                                                    let split = value.value.split("|");
                                                    setFieldValue("packageId", split[0]);
                                                    setFieldValue("quoteId", split[1]);
                                                }}
                                            />
                                        )}

                                        {leadId && leadId !== "none" && packagesList && packagesList.length > 0 && (
                                            <MyCheckboxInput type="checkbox" label="" labelcb="Pricing on new page" name="pricing_new_pg" />
                                        )}

                                        {leadId && leadId !== "none" && packagesList && packagesList.length > 0 && (
                                            <MyCheckboxInput type="checkbox" label="" labelcb="Show Deposit Amount" name="show_deposit" />
                                        )}

                                        {leadId && leadId !== "none" && packagesList && packagesList.length > 0 && (
                                            <MyCheckboxInput type="checkbox" label="" labelcb="Condensed PDF" name="condensed_pdf" />
                                        )}

                                        {leadId && leadId !== "none" && (!packagesList || packagesList.length === 0) && <p>Please add a Quote first!</p>}

                                        {client_booking.quoteId && (
                                            <div style={{ marginTop: 20 }}>
                                                <Button content="View Quote" as={Link} to={"/quotes/edit/" + client_booking.quoteId} />
                                            </div>
                                        )}
                                    </Grid.Column>

                                    <Grid.Column textAlign="right">
                                        <MyTextInput label="Cancellation Fee*" name="cancellation_fee" type="text" />

                                        <MyTextInput label="Changes / Amendments*" name="changes_amendments" type="text" />

                                        <MyTextInput
                                            label="Total Booking Price*"
                                            name="total_booking_price"
                                            type="text"
                                            // value={totalValue} /*onChange={(value) => setTotalValue(value.target.value)}*/
                                        />

                                        <MyTextInput
                                            label="Discount"
                                            name="total_discount"
                                            type="text"
                                            // onBlur={(value) => {
                                            //     let discountValue = value.target.value;
                                            //     if (discountValue !== "") {
                                            //         setTotalValue(parseFloat(totalValue) - parseFloat(value.target.value));
                                            //     } else {
                                            //         setTotalValue(parseFloat(client_booking.total_booking_price) + parseFloat(client_booking.total_discount ? client_booking.total_discount : 0));
                                            //     }
                                            //     setFieldValue("total_discount", value.target.value);
                                            // }}
                                        />

                                        {/* <MyTextInput
                                            label="Total Booking Price*"
                                            name="total_booking_price"
                                            type="text"
                                            onChange={(field) => {
                                                let price = 0;
                                                let discount = 0;

                                                if (field.target.value !== "") {
                                                    price = parseFloat(field.target.value);
                                                }

                                                if (values.discount && values.discount !== "" && values.discount !== "NaN") {
                                                    discount = parseFloat(values.discount);
                                                }

                                                setFieldValue("total_booking_price", price);
                                                setFieldValue("total_discount", discount);
                                                setFieldValue("priceAfter", price - discount);
                                            }}
                                        />
                                        <MyTextInput
                                            label="Discount"
                                            name="total_discount"
                                            type="text"
                                            onChange={(field) => {
                                                let price = 0;
                                                let discount = 0;

                                                if (field.target.value !== "") {
                                                    discount = parseFloat(field.target.value);
                                                }

                                                if (client_booking.total_booking_price && client_booking.total_booking_price !== "" && client_booking.total_booking_price !== "NaN") {
                                                    price = parseFloat(client_booking.total_booking_price);
                                                }

                                                setFieldValue("total_booking_price", price);
                                                setFieldValue("total_discount", discount);
                                                setFieldValue("priceAfter", price - discount);
                                            }}
                                        />
                                        <MyTextInput label="Total Due" name="priceAfter" type="text" disabled={true} /> */}
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <MyTinyMCE label="Overview" name="overview" type="text" onEditorChange={(value) => setFieldValue("overview", value)} />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Button type="submit" disabled={isSubmitting} loading={isSubmitting} color="orange" content="Save" />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Segment>

            {client_booking.quoteId && (
                <div style={{ marginTop: 20 }}>
                    <ErrorBoundary>
                        <BookingConfirmations bookingId={bookingId} leadId={leadId} client_booking={client_booking} />
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <BookingTravellers client_booking={client_booking} client={client} />
                    </ErrorBoundary>

                    {clientId && client && (
                        <ErrorBoundary>
                            <BookingPayments client_booking={client_booking} clientId={clientId} client={client} />
                        </ErrorBoundary>
                    )}

                    {/* <Confirm
                        header="You are about to delete this Itinerary Row"
                        content="This cannot be undone, are you sure?"
                        size="tiny"
                        open={openB}
                        onCancel={closeB}
                        onConfirm={() => removeItineraryData(client_booking, "confirmation")}
                        confirmButton="Yes"
                    /> */}
                </div>
            )}
        </>
    );
}
