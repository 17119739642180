import React, { useState, useEffect } from "react";
import { Grid, Message } from "semantic-ui-react";
import LeadsDetailedStatus from "./LeadsDetailedStatus";
import LeadDetailedFollowUps from "./LeadDetailedFollowUps";
import LeadDetailedComments from "./LeadDetailedComments";
import LeadDetailedFiles from "./LeadDetailedFiles";
import LeadDetailedContactDetails from "./LeadDetailedContactDetails";
import LeadDetailedQuotes from "./LeadDetailedQuotes";
import LeadDetailedLogs from "./LeadDetailedLogs";
import LeadsDetailedClient from "./LeadsDetailedClient";
import LeadDetailedBookings from "./LeadDetailedBookings";
import LeadDetailedAdmin from "./LeadDetailedAdmin";
import LeadsDetailedData from "./LeadsDetailedData";
import { updateSource } from "../leadActions";
import ErrorBoundary from "../../../app/layout/ErrorBoundary";
import { useSelector } from "react-redux";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadDetailedPage(props) {
    let leadId = props.match.params.id;

    const [lead, setLead] = useState([]);
    // const [comments, setComments] = useState([]);
    // const [files, setFiles] = useState([]);
    // const [logs, setLogs] = useState([]);
    const [client, setClient] = useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("leads")
            .doc(leadId)
            .onSnapshot(async (doc) => {
                let newLead = doc.data();
                newLead.id = doc.id;
                setLead(newLead);

                if (newLead.clientId) {
                    firestore
                        .collection("clients")
                        .doc(newLead.clientId)
                        .onSnapshot((doc2) => {
                            let newClient = doc2.data();
                            newClient.id = doc2.id;

                            setClient(newClient);
                        });
                }
            });

        // const unsubscribe2 = firestore
        //     .collection("leads")
        //     .doc(leadId)
        //     .collection("comments")
        //     .onSnapshot((snapshot) => {
        //         let list = [];

        //         snapshot.docs.forEach((doc) => {
        //             if (doc.exists) {
        //                 let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
        //                 list.push(appObj);
        //             }
        //         });

        //         setComments(list);
        //     });

        // const unsubscribe3 = firestore
        //     .collection("leads")
        //     .doc(leadId)
        //     .collection("files")
        //     .onSnapshot((snapshot) => {
        //         let list = [];

        //         snapshot.docs.forEach((doc) => {
        //             if (doc.exists) {
        //                 let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
        //                 list.push(appObj);
        //             }
        //         });

        //         setFiles(list);
        //     });

        // const unsubscribe4 = firestore
        //     .collection("logs")
        //     .where("rowId", "==", leadId)
        //     .where("table", "==", "leads")
        //     .onSnapshot((snapshot) => {
        //         let list = [];

        //         snapshot.docs.forEach((doc) => {
        //             if (doc.exists) {
        //                 let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
        //                 list.push(appObj);
        //             }
        //         });

        //         let log = list.sort((a, b) => (b.createDate > a.createDate ? 1 : -1));
        //         setLogs(log);
        //     });


        // return () => {unsubscribe(); unsubscribe2(); unsubscribe3(); unsubscribe4()};
        return () => unsubscribe();
    }, []);

    return (
        (!currentUserProfile.isExternal?true:(currentUserProfile.isExternal && lead.userDisplayName==currentUserProfile.displayName)?true:false) && <>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        {lead && lead.id && (
                            <ErrorBoundary>
                                <LeadsDetailedStatus lead={lead} currentUserProfile={currentUserProfile} />
                            </ErrorBoundary>
                        )}

                        {lead && lead.id && (
                            <ErrorBoundary>
                                <LeadDetailedFollowUps lead={lead} currentUserProfile={currentUserProfile} />
                            </ErrorBoundary>
                        )}

                        {lead && lead.id && (
                            <ErrorBoundary>
                                <LeadDetailedComments lead={lead} currentUserProfile={currentUserProfile} />
                            </ErrorBoundary>
                        )}

                        {lead && lead.id && (
                            <ErrorBoundary>
                                <LeadDetailedFiles lead={lead} currentUserProfile={currentUserProfile} />
                            </ErrorBoundary>
                        )}
                    </Grid.Column>

                    <Grid.Column>
                        {lead && lead.id && (
                            <ErrorBoundary>
                                <LeadsDetailedClient lead={lead} />
                            </ErrorBoundary>
                        )}

                        {lead && lead.id && (
                            <ErrorBoundary>
                                <LeadDetailedQuotes lead={lead} currentUserProfile={currentUserProfile} history={props.history} client={client} />
                            </ErrorBoundary>
                        )}

                        {lead.status === "Booked" && lead.l_nr && lead.l_nr !== "" && (
                            <ErrorBoundary>
                                <LeadDetailedBookings lead={lead} />
                            </ErrorBoundary>
                        )}

                        {lead.status === "Booked" && lead.l_nr === undefined && (
                            <Message negative>
                                {" "}
                                <Message.Header>Bookings</Message.Header>
                                <p>Please add client L number</p>
                            </Message>
                        )}

                        {lead && lead.id && currentUserProfile && currentUserProfile.isSuper && currentUserProfile.isSuper === true && (
                            <ErrorBoundary>
                                <LeadDetailedAdmin lead={lead} />
                            </ErrorBoundary>
                        )}

                        {lead && lead.cfdb && lead.cfdb.length > 0 && (
                            <ErrorBoundary>
                                <LeadDetailedContactDetails cfdb={lead.cfdb} />
                            </ErrorBoundary>
                        )}

                        {lead && lead.cfdb && (
                            <ErrorBoundary>
                                <LeadsDetailedData lead={lead} />
                            </ErrorBoundary>
                        )}

                        {lead && lead.id && (
                            <ErrorBoundary>
                                <LeadDetailedLogs lead={lead} />
                            </ErrorBoundary>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}
