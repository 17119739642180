import React, { useEffect, useState } from "react";
import ClientDetailedForm from "./ClientDetailedForm";
import ClientUserForm from "./ClientUserForm";
import ClientDetailedLeads from "./ClientDetailedLeads";
import { Grid, Segment, Header, Button, Confirm } from "semantic-ui-react";
import ClientCorporateBookings from "./ClientCorporateBookings";
import { useSelector } from "react-redux";
import { deleteAppUser } from "../clientActions";
import ClientAppDetails from "./ClientAppDetails";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function ClientDetailedPage(props) {
    let clientId = props.match.params.id;
    const [client, setClient] = useState([]);
    const [delAppOpen, setDelAppOpen] = useState(false);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("clients")
            .doc(clientId)
            .onSnapshot((doc) => {
                let newClient = doc.data();
                newClient.id = doc.id;

                setClient(newClient);
            });

        return () => unsubscribe();
    }, []);

    return (
        <>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <ClientDetailedForm client={client} />

                        {currentUserProfile && !currentUserProfile.isExternal && (
                        (!client.appUserUid) || client.appUserUid.length === 0 ? (
                                    <ClientUserForm client={client} />
                                ) : (
                                    <ClientAppDetails client={client} />
                            )
                        )}
                    </Grid.Column>

                    <Grid.Column>
                        {client && client !== "" && client.id && currentUserProfile && currentUserProfile !== "" && <ClientDetailedLeads client={client} currentUserProfile={currentUserProfile} />}

                        {client && client !== "" && client.id && <ClientCorporateBookings client={client} />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}
