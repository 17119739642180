import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Divider, Segment, Grid, Image, Confirm } from "semantic-ui-react";
import { addQuoteTemplate, updateQuoteTemplate, deleteQuoteTemplate, deleteTemplateImage } from "../adminActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTinyMCE from "../../../app/common/form/MyTinyMCE";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function QuoteTemplateDetailedPage(props) {
    let templateId = props.match.params.id;
    const [quote_template, setQuote_template] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [open, setOpen] = useState(false);
    const [iniVal, setIniVal] = useState({ template_name: "", template_name_admin: "", visa: "", destination_link: "", terms_link: "" });

    useEffect(() => {
        const unsubscribe = firestore
            .collection("quote_templates")
            .doc(templateId)
            .onSnapshot((snapshot) => {
                setQuote_template(snapshot.data());
                if (props.match.params.id !== "0") {
                    setIniVal(snapshot.data());
                }
            });

        return () => unsubscribe();
    }, []);

    function openD() {
        setOpen(true);
    }
    function close() {
        setOpen(false);
    }

    function deleteQuoteTemplateData(quote_template) {
        let templateId = props.match.params.id;

        setOpen(false);
        setDeleting(true);

        deleteQuoteTemplate(templateId);

        props.history.push("/admin/quote_templates/");
    }

    function openI(documentNameRand) {
        let templateId = props.match.params.id;
        deleteTemplateImage(templateId, documentNameRand);
    }

    return (
        <Container>
            <Segment>
                <Formik
                    initialValues={iniVal}
                    enableReinitialize="true"
                    validate={(values) => {
                        const error = {};
                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (props.match.params.id !== "0") {
                            values.id = props.match.params.id;
                            await updateQuoteTemplate(values);
                        } else {
                            await addQuoteTemplate(values);
                        }

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <MyTextInput label="Template Name PDF" name="template_name" type="text" placeholder="Template Name" />

                                        <MyTextInput label="Template Name Admin" name="template_name_admin" type="text" placeholder="Template Name" />

                                        <MyTextInput label="Visa" name="visa" type="text" placeholder="Visa" />

                                        <MyTextInput label="Destination Link" name="destination_link" type="text" placeholder="Destination Link" />

                                        <MyTextInput label="Terms Link" name="terms_link" type="text" placeholder="Terms Link" />

                                        <MyTinyMCE label="Final Page Wording" name="wording" type="text" onEditorChange={(value) => setFieldValue("wording", value)} />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <label>Upload Image</label>
                                        <input
                                            id="uploadFile"
                                            name="uploadFile"
                                            type="file"
                                            onChange={(event) => {
                                                setFieldValue("uploadFile", event.currentTarget.files[0]);
                                            }}
                                            className="form-control"
                                        />

                                        {quote_template.fileUrl && quote_template.fileUrl.length > 0 && (
                                            <>
                                                <Image size="small" style={{ marginTop: 20 }} src={quote_template.fileUrl} />
                                                <br />
                                                <Button size="tiny" color="red" as="a" content="Remove Image" loading={deleting} onClick={() => openI(quote_template.documentNameRand)} />
                                            </>
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider />

                            <Button disabled={isSubmitting} loading={isSubmitting} size="large" positive content={templateId.length > 1 ? "Update Template" : "Create Template"} type="submit" />

                            <Button size="large" color="red" as="a" content="Delete" loading={deleting} onClick={openD} />

                            <Button size="large" as={Link} to={`/admin/quote_templates/`}>
                                Cancel
                            </Button>
                        </form>
                    )}
                </Formik>
            </Segment>
            <Confirm
                header="You are about to delete this quote template"
                content="This cannot be undone, are you sure?"
                size="tiny"
                open={open}
                onCancel={close}
                onConfirm={() => deleteQuoteTemplateData(quote_template)}
                confirmButton="Yes"
            />
        </Container>
    );
}
