import React, { useState, useEffect } from "react";
import { Segment, Table, Header, Button, Icon } from "semantic-ui-react";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import { createLeadNew, getLeads } from "../clientActions";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function ClientDetailedLeads(props) {
    const [loading, setloading] = useState(false);
    const [leads, setLeads] = useState(false);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("leads")
            .where("clientId", "==", props.client.id)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setLeads(list);
            });

        return () => unsubscribe();
    }, []);

    async function createLead(client) {
        setloading(true);
        await createLeadNew(client, props.currentUserProfile);
        setloading(false);
    }

    let currentUser = props.currentUserProfile;

    return (
        <>
            <Segment attached="top">
                <Header as="h3">Leads / Trips</Header>
            </Segment>

            <Segment attached>
                {leads && leads.length > 0 && (
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    <Icon name="file alternate" />
                                </Table.HeaderCell>
                                <Table.HeaderCell>View</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {leads.map((lead) => (
                                (!currentUser.isExternal?true:(currentUser.isExternal && lead.userDisplayName==currentUser.displayName)?true:false) && <Table.Row key={lead.id}>
                                    <Table.Cell>{lead && lead.createDate && lead.createDate !== "" ? format(lead.createDate.toDate(), "d MMMM yyyy") : ""}</Table.Cell>
                                    <Table.Cell>{lead.status}</Table.Cell>
                                    <Table.Cell textAlign="center">{lead.hasFiles && lead.hasFiles === true && <Icon name="check" />}</Table.Cell>
                                    <Table.Cell>
                                        <Button color="orange" as={Link} to={"/leads/edit/" + lead.id}>
                                            View
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}

                {leads && leads.length === 0 && <p>No Leads or Trips</p>}
            </Segment>

            <Segment attached="bottom" className="buttons_row" style={{ marginBottom: 20 }}>
                <Button size="medium" color="orange" content="Create New Lead" loading={loading} onClick={() => createLead(props.client)} />
            </Segment>
        </>
    );
}
