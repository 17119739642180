import React, { useState } from "react";
import { Button, Grid } from "semantic-ui-react";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { uploadFile } from "../leadActions";

export default function LeadDetailedFilesForm(props) {
    const [file, setFile] = useState([]);
    return (
        <>
            <Formik
                initialValues={{ description: "" }}
                validate={(values) => {
                    const error = {};

                    if (!values.description) {
                        error.description = "Description is required";
                    }
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    // console.log(values)

                    values.leadId = props.leadid;
                    values.displayName = props.profile.displayName;
                    values.userUid = props.profile.id;

                    uploadFile(values);

                    resetForm();
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <MyTextInput label="Description" name="description" placeholder="Description" type="text" />
                        <Grid verticalAlign="middle">
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <label>
                                        <strong>Upload File</strong>
                                    </label>
                                </Grid.Column>

                                <Grid.Column width={11}>
                                    <input
                                        id="uploadFile"
                                        name="uploadFile"
                                        type="file"
                                        onChange={(event) => {
                                            setFieldValue("uploadFile", event.currentTarget.files[0]);
                                        }}
                                        className="form-control"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Button style={{ marginTop: 20 }} disabled={isSubmitting} loading={isSubmitting} color="orange" content="Upload File" />
                    </form>
                )}
            </Formik>
        </>
    );
}
