import React from "react";
import { Button } from "semantic-ui-react";
import { createFollowUp } from "../leadActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";
import { useSelector } from "react-redux";

export default function LeadDetailedFollowUpForm(props) {
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();
    const { currentUserProfile } = useSelector((state) => state.profile);
    return (
        <>
            <Formik
                initialValues={{ followup_nr: "", followup: "" }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setTimeout(() => {
                        values.leadId = lastSegment;
                        values.displayName = currentUserProfile.displayName;
                        values.userUid = currentUserProfile.id;
                        createFollowUp(values);
                        resetForm();
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="ui form" style={{ marginBottom: 20 }}>
                        <MyTextInput label="Follow Up Nr" name="followup_nr" placeholder="Follow Up Nr" style={{ marginBottom: 10 }} />
                        <MyCheckboxInput labelcb="Send Email to Consultant:" name="followup_email" type="checkbox" style={{ marginTop: 10 }} />
                        <MyTextInput label="" name="followup" placeholder="Follow Up" />
                        <Button style={{ marginTop: 20 }} disabled={isSubmitting} loading={isSubmitting} color="orange" content="Add Follow Up" />
                    </form>
                )}
            </Formik>
        </>
    );
}
