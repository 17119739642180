import { SET_FILTER_STATUS } from "./leadConstants";

const initialState = {
    filterStatus: "",
};

export default function leadReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_FILTER_STATUS:
            return {
                ...state,
                filterStatus: payload,
            };
        default: {
            return state;
        }
    }
}
