import React from "react";
import { useField } from "formik";
import { FormField, Label, Grid } from "semantic-ui-react";

export default function MyTextInput({ label, labelTop, ...props }) {
    const [field, meta] = useField(props);

    return (
        <Grid verticalAlign="middle">
            <Grid.Row>
                {label && label.length > 0 && (
                    <Grid.Column width={5}>
                        <label>
                            <strong>{label}</strong>
                        </label>
                    </Grid.Column>
                )}

                <Grid.Column width={label && label.length > 0 ? 11 : 16}>
                    <FormField error={meta.touched && !!meta.error}>
                        {labelTop && labelTop.length > 0 && <label>{labelTop}</label>}

                        <input {...field} {...props} autcomplete="false" />
                        {meta.touched && meta.error ? (
                            <Label basic color="red">
                                {meta.error}
                            </Label>
                        ) : null}
                    </FormField>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
