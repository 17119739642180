import moment from "moment";
import { toastr } from "react-redux-toastr";
import cuid from "cuid";
//import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncActions";
import firebase from "../../app/config/firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore();

export const createUser = (user) => async (dispatch, getState) => {
    const { isLoaded, isEmpty, email, password, ...updatedUser } = user;

    try {
        //We need to set up a second firebase login because otherwise we get logged into the newly created user
        const hostname = window && window.location && window.location.hostname;

        if (hostname === "localhost" || hostname === "192.168.1.106") {
            var config = {
                apiKey: "AIzaSyDjM24Pf9wSADq5ZOJl142nZZ6uUrwJCyU",
                authDomain: "igo-travel-leads-test.firebaseapp.com",
                databaseURL: "https://igo-travel-leads-test.firebaseio.com",
            };
        } else {
            var config = {
                apiKey: "AIzaSyClU8nmq7vaVG8PKhzW-zuTbquvQc7ns9U",
                authDomain: "igo-travel-leads-f4ef5.firebaseapp.com",
                databaseURL: "https://igo-travel-leads-f4ef5.firebaseio.com",
            };
        }

        // var config = {
        //     apiKey: "AIzaSyClU8nmq7vaVG8PKhzW-zuTbquvQc7ns9U",
        //     authDomain: "igo-travel-leads-f4ef5.firebaseapp.com",
        //     databaseURL: "https://igo-travel-leads-f4ef5.firebaseio.com"
        // };

        if (!secondaryApp) {
            var secondaryApp = firebase.initializeApp(config, "Secondary" + cuid());
        }

        secondaryApp
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(function (firebaseUser) {
                secondaryApp.auth().signOut();
                secondaryApp.delete();
                //delete secondaryApp;

                updatedUser.createdAt = firestore.FieldValue.serverTimestamp();
                updatedUser.id = firebaseUser.user.uid;
                updatedUser.email = email;
                updatedUser.isConsultant = true;

                firestore.set(`users/${updatedUser.id}`, updatedUser).then(function () {
                    toastr.success("Success", "Profile added");
                });
            })
            .catch(function (error) {
                // if (error.code === "auth/email-already-in-use") {
                //     updatedUser.createdAt = new Date();
                //     //updatedUser.id = firebaseUser.user.uid;
                //     updatedUser.email = email;

                //     firestore.add(`users`, updatedUser).then(function() {
                //         toastr.success("Success", "Profile added");
                //     });
                // }
                console.log("Error getting document:", error);
                toastr.error("Error", error.code);
            });
    } catch (e) {
        console.log(e);
    }
};

export async function updateProfile(user) {
    const { uploadFile, isLoaded, isEmpty, ...updatedUser } = user;

    try {
        if (uploadFile) {
            const file = uploadFile;

            const path = `users/${user.id}`;
            const options = {
                name: cuid() + "-" + file.name,
            };

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            updatedUser.fileName = file.name;
            updatedUser.fileUrl = uploadedFile;
            updatedUser.documentNameRand = options.name;
        }

        if (updatedUser.id == "0") {
            const hostname = window && window.location && window.location.hostname;

            if (hostname === "localhost" || hostname === "192.168.1.106") {
                var config = {
                    apiKey: "AIzaSyDjM24Pf9wSADq5ZOJl142nZZ6uUrwJCyU",
                    authDomain: "igo-travel-leads-test.firebaseapp.com",
                    databaseURL: "https://igo-travel-leads-test.firebaseio.com",
                };
            } else {
                var config = {
                    apiKey: "AIzaSyClU8nmq7vaVG8PKhzW-zuTbquvQc7ns9U",
                    authDomain: "igo-travel-leads-f4ef5.firebaseapp.com",
                    databaseURL: "https://igo-travel-leads-f4ef5.firebaseio.com",
                };
            }

            if (!secondaryApp) {
                var secondaryApp = firebase.initializeApp(config, "Secondary" + cuid());
            }

            secondaryApp
                .auth()
                .createUserWithEmailAndPassword(updatedUser.email, updatedUser.password)
                .then(function (firebaseUser) {
                    secondaryApp.auth().signOut();
                    secondaryApp.delete();

                    updatedUser.createdAt = firebase.firestore.FieldValue.serverTimestamp();
                    updatedUser.id = firebaseUser.user.uid;
                    updatedUser.email = updatedUser.email;
                    updatedUser.isConsultant = true;

                    firestore
                        .collection("users")
                        .doc(updatedUser.id)
                        .set(updatedUser, { merge: true })
                        .then(function () {
                            toast.success("Profile added");
                        });
                })
                .catch(function (error) {
                    console.log("Error getting document:", error);
                    toast.error(error.code);
                });
        } else {
            await firestore.collection("users").doc(updatedUser.id).update(updatedUser);
            toast.success("Profile updated");
        }
    } catch (e) {
        console.log(e);

        // Sentry.captureException(e);
        // console.log("Sentry Notified!");
    }
}

export const createCompany =
    (company) =>
    async (dispatch, getState, { getFirestore }) => {
        const { isLoaded, isEmpty, ...newCompany } = company;

        try {
            await firestore.add(`companies`, newCompany);
            toastr.success("Success", "Company created");
        } catch (e) {
            console.log(e);
        }
    };

export const updateCompany =
    (company) =>
    async (dispatch, getState, { getFirestore }) => {
        const { isLoaded, isEmpty, ...updatedCompany } = company;

        try {
            await firestore.set(`companies/${company.id}`, updatedCompany);
            toastr.success("Success", "Company updated");
        } catch (e) {
            console.log(e);
        }
    };

export async function addQuoteTemplate(template) {
    let file = "";

    if (template.uploadFile) {
        file = template.uploadFile;
        delete template.uploadFile;
    }

    try {
        let newTemp = await firestore.collection("quote_templates").add(template);

        if (file) {
            const path = `quote_templates/${newTemp.id}`;
            const options = {
                name: cuid() + "-" + file.name,
            };

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            let new_template = {};
            new_template.fileName = file.name;
            new_template.fileUrl = uploadedFile;
            new_template.documentNameRand = options.name;

            await firestore.collection("quote_templates").doc(newTemp.id).update(new_template);
        }

        toast.success("Template Added");
    } catch (e) {
        console.log(e);
    }
}

export async function updateQuoteTemplate(template) {
    const { id, ...updatedTemplate } = template;

    if (template.uploadFile) {
        const file = template.uploadFile;
        delete updatedTemplate.uploadFile;

        const path = `quote_templates/${id}`;
        const options = {
            name: cuid() + "-" + file.name,
        };

        let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

        updatedTemplate.fileName = file.name;
        updatedTemplate.fileUrl = uploadedFile;
        updatedTemplate.documentNameRand = options.name;
    }

    try {
        await firestore.collection("quote_templates").doc(id).update(updatedTemplate);

        toast.success("Template Updated");
    } catch (e) {
        console.log(e);
    }
}

export const deleteQuoteTemplate = (templateId) => {
    try {
        firestore.collection("quote_templates").doc(templateId).delete();

        toast.success("Quote Template has been deleted");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
};

export const getUser = (userId) => {
    return firestore.collection("users").doc(userId).get();
};

export const getUsersC = () => {
    const firestore = firebase.firestore();
    return firestore.collection("users").where("isConsultant", "==", true).get();
};

export async function deleteTemplateImage(templateId, documentNameRand) {
    try {
        await firebase
            .deleteFile(`quote_templates/${templateId}/${documentNameRand}`)
            .then(function () {
                console.log("file del went ok");
                toast.success("Image was removed");
            })
            .catch(function (error) {
                console.log(error);
            });

        await firestore.collection("quote_templates").doc(templateId).update({ documentNameRand: "", fileName: "", fileUrl: "" });
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export const getTemplates = () => {
    const firestore = firebase.firestore();
    return firestore.collection("quote_templates").get();
};
export const getQuoteTemplate = (templateId) => {
    const firestore = firebase.firestore();
    return firestore.collection("quote_templates").doc(templateId).get();
};
