import React from "react";
import { Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AdminPage(props) {
    const { currentUserProfile } = useSelector((state) => state.profile);

    if (currentUserProfile && currentUserProfile.length === undefined) {
        if (!currentUserProfile.isSuper) {
            props.history.push("/");
        }
        if (currentUserProfile.isLoaded === true && currentUserProfile.isEmpty === true) {
            props.history.push("/");
        }
    }

    return (
        <Container>
            <h2>Admin</h2>

            <ul>
                <li>
                    <Link to="/admin/users">Users</Link>
                </li>
                <li>
                    <Link to="/admin/quote_templates">Quote Templates</Link>
                </li>
            </ul>
        </Container>
    );
}
