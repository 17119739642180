import React, { useState, useCallback } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { Button, Grid, GridColumn, GridRow, Form } from "semantic-ui-react";
import { createLead } from "../leads/leadActions";
import { Formik } from "formik";
import MyDateInput from "../../app/common/form/MyDateInput";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import MyCheckboxInput from "../../app/common/form/MyCheckboxInput";

export default function LeadCreateModal(props) {
    const dispatch = useDispatch();
    let status = props.status;
    const [website_divisionValue, setWebsite_divisionValue] = useState("Leisure");

    const updateUser = useCallback((value) => {
        setWebsite_divisionValue(value);
    }, []);

    function changeDiv(value) {
        updateUser(value.value);
    }

    const sourceArr = [
        { key: "Telephone", text: "Telephone", value: "Telephone" },
        { key: "Email", text: "Email", value: "Email" },
        { key: "Chat", text: "Chat", value: "Chat" },
        { key: "Website", text: "Website", value: "Website" },
        { key: "Social Media", text: "Social Media", value: "Social Media" },
        { key: "Repeat Client", text: "Repeat Client", value: "Repeat Client" },
        { key: "Referral", text: "Referral", value: "Referral" },
        { key: "Mailchimp/Mailer", text: "Mailchimp/Mailer", value: "Mailchimp/Mailer" },
        { key: "Other", text: "Other", value: "Other" },
    ];

    const webDivArr = [
        { key: "Leisure", text: "Leisure", value: "Leisure" },
        { key: "Non-Leisure", text: "Non-Leisure", value: "Non-Leisure" },
    ];
    return (
        <>
            <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>Add Lead</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Formik
                            initialValues={{ client_name: "", client_email: "", enquiry_source: "", travel_date_from: "", travel_date: "", destination: "" }}
                            enableReinitialize="true"
                            validate={(values) => {
                                const error = {};
                                if (!values.client_name) {
                                    error.client_name = "Required";
                                }
                                if (!values.client_email) {
                                    error.client_email = "Required";
                                }
                                if (!values.enquiry_source) {
                                    error.enquiry_source = "Required";
                                }
                                if (!values.travel_date_from) {
                                    error.travel_date_from = "Required";
                                }
                                if (!values.travel_date) {
                                    error.travel_date = "Required";
                                }
                                if (!values.destination) {
                                    error.destination = "Required";
                                }
                                return error;
                            }}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                values.status = props.status;

                                await createLead(values);

                                if (props.status === "Unassigned") {
                                    setSubmitting(false);
                                    resetForm();
                                    dispatch(closeModal());
                                } else {
                                    window.location.replace("/leads/landing");
                                }
                            }}
                        >
                            {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid centered>
                                        <GridRow>
                                            <GridColumn width={8}>
                                                <MyDateInput
                                                    label="Custom Date"
                                                    name="add_date"
                                                    dateFormat="yyyy-MM-dd HH:mm"
                                                    showYearDropdown={true}
                                                    showMonthDropdown={true}
                                                    dropdownMode="select"
                                                    showTimeSelect={true}
                                                />
                                                <MyTextInput label="Client Name*" name="client_name" type="text" />
                                                <MyTextInput label="Custom Email*" name="client_email" type="email" />
                                                <MyTextInput label="Client Phone" name="client_phone" type="text" />
                                                <MySelectInput label="Source*" name="enquiry_source" options={sourceArr} placeholder="Select your Source" legacy={true} />
                                                <MyTextInput label="Source Other" name="enquiry_source_other" type="text" />
                                                <MyTextInput label="Package" name="package" type="text" />
                                            </GridColumn>

                                            <GridColumn width={8}>
                                                <MyDateInput
                                                    label="Travel Date (From)*"
                                                    name="travel_date_from"
                                                    dateFormat="yyyy-MM-dd"
                                                    showYearDropdown={true}
                                                    showMonthDropdown={true}
                                                    dropdownMode="select"
                                                />

                                                <MyDateInput
                                                    label="Travel Date (To)*"
                                                    name="travel_date"
                                                    dateFormat="yyyy-MM-dd"
                                                    showYearDropdown={true}
                                                    showMonthDropdown={true}
                                                    dropdownMode="select"
                                                />

                                                <MyTextInput label="Number of Passengers" name="number_travellers" type="text" />

                                                <MyTextInput label="Comments" name="unassigned_comments" type="text" />

                                                <MySelectInput
                                                    label="Website Division"
                                                    name="website_division"
                                                    options={webDivArr}
                                                    value={website_divisionValue}
                                                    onChange={(field, value) => changeDiv(value)}
                                                    placeholder="Select your Division"
                                                    legacy={true}
                                                />

                                                <MyTextInput label="Departing From" name="departing_from" type="text" />

                                                <MyTextInput label="Destination*" name="destination" type="text" />

                                                <Form.Field inline style={{ marginTop: 28 }}>
                                                    <MyCheckboxInput type="checkbox" label="" labelcb="Send Thank You Email" name="send_thank_you" />
                                                </Form.Field>
                                            </GridColumn>
                                        </GridRow>

                                        <GridRow>
                                            <GridColumn>
                                                <Button disabled={isSubmitting} loading={isSubmitting} color="orange" content="Create Lead" />
                                                <Button disabled={isSubmitting} onClick={() => dispatch(closeModal())}>
                                                    Cancel
                                                </Button>
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
