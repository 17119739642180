import React from "react";
import { useDispatch } from "react-redux";
import { Segment, Header, Button, Grid, Divider } from "semantic-ui-react";
import { clonePackage } from "../quoteActions";
import { openModal } from "../../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";

export default function QuoteDetailedClonePack(props) {
    let quote = props.quote;
    let profile = props.profile;
    let quotePackages = props.quotePackages;
    const dispatch = useDispatch();
    let packages = [
        {
            key: "",
            text: "Choose a Package",
            value: "",
        },
    ];

    Object.keys(quotePackages).forEach(function (key) {
        packages.push({
            key: quotePackages[key].id,
            text: quotePackages[key].package_name,
            value: quotePackages[key].id,
        });
    });
    return (
        <div style={{ marginBottom: 20 }}>
            <Formik
                initialValues={{ new_package_name: "", package_id: "" }}
                validate={(values) => {
                    const error = {};

                    if (!values.new_package_name) {
                        error.new_package_name = "New Package Name Required";
                    }

                    if (!values.package_id) {
                        error.package_id = "Choose a Package to Clone";
                    }

                    return error;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    clonePackage(values, quote, profile);
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Segment attached="top">
                            <Header as="h3">Clone Package / Template</Header>
                        </Segment>
                        <Segment attached>
                            <MyTextInput label="Package Name" name="new_package_name" type="text" />
                            <MySelectInput label="Quote Package" name="package_id" options={packages} placeholder="Quote Package" />
                        </Segment>
                        <Segment attached="bottom">
                            <Grid columns={2} relaxed="very">
                                <Grid.Column>
                                    <Button disabled={isSubmitting} loading={isSubmitting} size="medium" color="orange" content="Clone Package" />
                                </Grid.Column>
                                <Grid.Column textAlign="right">
                                    <Button
                                        disabled={isSubmitting}
                                        size="medium"
                                        color="orange"
                                        content="Clone Package Template"
                                        as="a"
                                        onClick={() => dispatch(openModal({ modalType: "PackageTemplateCreateModal", modalProps: { quote } }))}
                                    />
                                </Grid.Column>
                            </Grid>
                            <Divider vertical>OR</Divider>
                        </Segment>
                    </form>
                )}
            </Formik>
        </div>
    );
}
