import React from "react";
import { Modal, Grid, Button } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import LeadBookingItinForm from "../leads/LeadBooking/LeadBookingItinForm";
import LeadBookingPaymentsForm from "../booking/BookingModal/LeadBookingPaymentsForm";
import LeadBookingUserForm from "../leads/LeadBooking/LeadBookingUserForm";
import LeadBookingTravellersForm from "../booking/BookingModal/LeadBookingTravellersForm";
import LeadBookingItinBGForm from "../leads/LeadBooking/LeadBookingItinBGForm";

export default function LeadBookingModal(props) {
    const dispatch = useDispatch();
    let modalType = props.modalType;
    let itinerary = props.itin;
    let bookingType = props.bookingType;
    let lead_booking = {};
    let leadId = "";
    let payment = {};
    let user = {};
    let lead = {};
    let clientId = "";
    let client_booking = {};
    let client = {};
    let traveller = {};
    let bookingId = {};

    if (props.lead_booking) {
        lead_booking = props.lead_booking;
    }
    if (props.leadId) {
        leadId = props.leadId;
    }
    if (props.bookingId) {
        bookingId = props.bookingId;
    }
    if (props.payment) {
        payment = props.payment;
    }
    if (props.user) {
        user = props.user;
    }
    if (props.lead) {
        lead = props.lead;
    }
    if (props.clientId) {
        clientId = props.clientId;
    }
    if (props.client_booking) {
        client_booking = props.client_booking;
    }
    if (props.client) {
        client = props.client;
    }
    if (props.traveller) {
        traveller = props.traveller;
    }

    let modalsize = "large";
    if (modalType === "Edit BG") {
        modalsize = "tiny";
    }

    return (
        <>
            <Modal size={modalsize} open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>
                    <Grid>
                        <Grid.Row columns={2} verticalAlign="middle">
                            <Grid.Column>
                                {modalType} {itinerary && itinerary !== undefined && itinerary.itineraryType}
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Button size="medium" content="Cancel" as="a" onClick={() => dispatch(closeModal())} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {modalType === "Edit" && itinerary.itineraryType && itinerary.itineraryType === "Flight" && <p>flight form - edit</p>}

                        {modalType === "Add Itinerary" && (
                            <LeadBookingItinForm type="Add" closeModal={() => dispatch(closeModal())} leadId={leadId} client_booking={client_booking} clientId={clientId} bookingType={bookingType} />
                        )}

                        {modalType === "Edit" && itinerary.itineraryType && itinerary.itineraryType === "Itinerary" && (
                            <LeadBookingItinForm
                                type="Save"
                                closeModal={() => dispatch(closeModal())}
                                itin={itinerary}
                                lead_booking={lead_booking}
                                client_booking={client_booking}
                                clientId={clientId}
                                leadId={leadId}
                                bookingType={bookingType}
                            />
                        )}
                        {modalType === "Add Payment" && <LeadBookingPaymentsForm type="Add" closeModal={() => dispatch(closeModal())} lead_booking={lead_booking} leadId={leadId} client={client} />}

                        {modalType === "Edit Payment" && <LeadBookingPaymentsForm type="Save" closeModal={() => dispatch(closeModal())} payment={payment} />}

                        {modalType === "Add User" && (
                            <LeadBookingUserForm type="Add" closeModal={() => dispatch(closeModal())} lead_booking={lead_booking} lead={lead} bookingType={bookingType} bookingId={bookingId} />
                        )}

                        {modalType === "Edit User" && bookingType === "User" && (
                            <LeadBookingUserForm type="Save" closeModal={() => dispatch(closeModal())} user={user} lead_booking={lead_booking} bookingType={bookingType} bookingId={bookingId} />
                        )}

                        {modalType === "Add Traveller" && <LeadBookingTravellersForm type="Add" closeModal={() => dispatch(closeModal())} lead_booking={lead_booking} bookingType={bookingType} />}

                        {modalType === "Edit Traveller" && (
                            <LeadBookingTravellersForm type="Save" closeModal={() => dispatch(closeModal())} lead_booking={lead_booking} bookingType={bookingType} traveller={traveller} />
                        )}

                        {modalType === "Edit BG" && (
                            <LeadBookingItinBGForm
                                type="Save"
                                closeModal={() => dispatch(closeModal())}
                                itin={itinerary}
                                lead_booking={lead_booking}
                                client_booking={client_booking}
                                bookingId={bookingId}
                                clientId={clientId}
                                leadId={leadId}
                                bookingType={bookingType}
                            />
                        )}
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
