import React, { useState, useEffect } from "react";
import { Header, Segment } from "semantic-ui-react";
import LeadDetailedFollowUpForm from "./LeadDetailedFollowUpForm";
import format from "date-fns/format";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadDetailedFollowUps(props) {
    const [followups, setFollowups] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("leads")
            .doc(props.lead.id)
            .collection("followups")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setFollowups(list);
            });

        return () => unsubscribe();
    }, []);

    return (
        <div style={{ marginBottom: 20 }}>
            <Segment attached="top">
                <Header as="h3">Follow Ups</Header>
            </Segment>

            <Segment attached>
                {followups &&
                    followups.length > 0 &&
                    followups.map((followup) => (
                        <p key={followup.id}>
                            <strong>
                                {followup.displayName} ({format(followup.createDate.toDate(), "d MMMM yyyy")}):
                            </strong>
                            <br />
                            <strong>Follow Up Nr: </strong>
                            {followup.followup_nr}
                            {followup.followup_email && <span> (Email Sent)</span>}
                            <br />
                            {followup.followup}
                        </p>
                    ))}
            </Segment>

            <Segment attached="bottom">
                <LeadDetailedFollowUpForm leadid={props.lead.id} profile={props.profile} />
            </Segment>
        </div>
    );
}
