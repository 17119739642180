import React from "react";
import { useField } from "formik";
import { FormField, Label } from "semantic-ui-react";
import { Editor } from "@tinymce/tinymce-react";

export default function MyTextInput({ label, ...props }) {
    const [field, meta] = useField(props);

    let removeInvalidItems = false;
    if (props.removeInvalidItems) {
        removeInvalidItems = props.removeInvalidItems;
    }

    return (
        <FormField error={meta.touched && !!meta.error}>
            {label && label.length > 0 && (
                <label>
                    <strong>{label}</strong>
                </label>
            )}

            <Editor
                {...field}
                {...props}
                apiKey="u3wbb2rax98pnwm5w8cxrjja5oxoqztklpt49ug97d5n009f"
                // onEditorChange={(event) => {
                //     // field.onChange(event);
                //     // field.value(event);
                //     // this.value = event;
                //     // field.onChange(event.target.getContent());
                //     return event;
                // }}
                // onBlur={(event) => {
                //     // return event.target.getContent();
                //     field.onChange(event.target.getContent());
                // }}
                plugins="autoresize,advlist,autolink,lists,charmap,print,preview,anchor,searchreplace,visualblocks,code,fullscreen,insertdatetime,table,contextmenu,paste,code,image"
                autoresize_bottom_margin="50"
                init={
                    removeInvalidItems && removeInvalidItems === true
                        ? {
                              font_formats: "Calibri=Calibri,Verdana,Geneva,sans-serif;Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace",
                              browser_spellcheck: true,
                              toolbar: "fontselect undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent image",
                              invalid_styles: "width height",
                              extended_valid_elements: "td[class|colspan|rowspan],tr[class],table[class|style]",
                              relative_urls: false,
                              remove_script_host: false,
                          }
                        : {
                              font_formats: "Calibri=Calibri,Verdana,Geneva,sans-serif;Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace",
                              browser_spellcheck: true,
                              toolbar: "fontselect undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent image",
                              relative_urls: false,
                              remove_script_host: false,
                          }
                }
            />

            {meta.touched && meta.error ? (
                <Label basic color="red">
                    {meta.error}
                </Label>
            ) : null}
        </FormField>
    );
}
