import React, { useEffect, useState } from "react";
import { Segment, Header, Button } from "semantic-ui-react";
import { loadHoliday, addHolidays } from "../quoteActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import Swal from "sweetalert2";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function QuoteDetailedLoadHoliday(props) {
    let quote = props.quote;
    let holidaysData = [];
    const [submitting, setSubmitting] = useState(false);
    const [holidays, setHolidays] = useState([]);

    useEffect(() => {
        setAllHolidays();
    }, []);

    async function setAllHolidays() {
        setSubmitting(true);
        let holidayArr = [];

        await firestore
            .collection("holidays")
            .get()
            .then(
                async (res) => {
                    let list = [];
                    res.forEach((doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), id: doc.id };
                            list.push(appObj);
                        }
                    });
                    var data = await list.map(mapProduct);
                    holidayArr = multiDimensionalUnique([...new Set([...holidayArr, ...data])]);

                    holidayArr.sort((a, b) => (a.text > b.text ? 1 : -1));
                },
                (err) => {
                    console.log(err);
                }
            );

        setHolidays(holidayArr);
        setSubmitting(false);
    }

    async function loadHolidaysDB() {
        Swal.fire({
            title: "Are you sure?",
            text: "This will load holidays and may take a while",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, run it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setSubmitting(true);

                let holidaysArr = [];
                let page = 1;
                let hasMore = true;

                while (hasMore) {
                    try {
                        let data = await fetchHolidays(page);
                        if (data.length === 0) {
                            hasMore = false;
                        } else {
                            data = data.map(mapProductName);
                            holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data])]);
                            page++;
                        }
                    } catch (e) {
                        console.log(e);
                        hasMore = false; // Stop fetching if there's an error
                    }
                }

                if (holidaysArr.length > 0) {
                    await addHolidays(holidaysArr.flat());
                    Swal.fire("Completed!", "Holidays updated.", "success");
                } else {
                    Swal.fire("No Data!", "No holidays found to update.", "info");
                }

                setAllHolidays();

                setSubmitting(false);
            }
        });
    }

    async function loadHolidays() {
        setSubmitting(true);

        let holidaysArr = [];

        try {
            let data = await fetchHolidays(1);
            data = data.map(mapProduct);
            holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data])]);
        } catch (e) {
            console.log(e);
        }

        try {
            let data1 = await fetchHolidays(2);
            data1 = data1.map(mapProduct);
            holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data1])]);
        } catch (e) {
            console.log(e);
        }

        try {
            let data2 = await fetchHolidays(3);
            data2 = data2.map(mapProduct);
            holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data2])]);
        } catch (e) {
            console.log(e);
        }

        try {
            let data4 = await fetchHolidays(4);
            data4 = data4.map(mapProduct);
            holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data4])]);
        } catch (e) {
            console.log(e);
        }

        try {
            let data5 = await fetchHolidays(5);
            data5 = data5.map(mapProduct);
            holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data5])]);
        } catch (e) {
            console.log(e);
        }

        try {
            let data6 = await fetchHolidays(6);
            data6 = data6.map(mapProduct);
            holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data6])]);
        } catch (e) {
            console.log(e);
        }

        await setHolidays(holidaysArr.flat());

        setSubmitting(false);
    }

    async function fetchHolidays(page) {
        let tot = 100;
        let params = "&status=publish&per_page=" + tot + "&page=" + page;
        let route = "https://www.igotravel.co.za/wp-json/wc/v3/products?&consumer_key=ck_6da67ca9223a3ed71eaadb4197dffa67bf19b2cb&consumer_secret=cs_9ccfb1ed69cdb1c827e23eec3cfaa849c9225a43";

        let data = await fetch(route + params);
        let dataRes = await data.json();

        return dataRes;

        // let res = await fetch(route + params)
        //     .then((res) => res.json())
        //     .then((holidaysArr) => {
        //         holidaysArr = holidaysArr.map(mapProduct);
        //         let holidaysArrNew = {};

        //         if (holidaysData.length !== 0) {
        //             holidaysArrNew = multiDimensionalUnique([...new Set([...holidaysArr, ...holidaysData])]);
        //         } else {
        //             holidaysArrNew = holidaysArr;
        //         }

        //         holidaysArrNew = holidaysArrNew.sort();
        //         holidaysData.push(holidaysArrNew);
        //         holidaysData = holidaysData.flat();

        //         console.log("holidaysData", holidaysData.length);
        //         console.log("holidaysArr", holidaysArr.length);

        //         setHolidays(holidaysData);

        //         return holidaysArr.length;
        //     });

        // console.log("after fetch", page);

        // console.log(page, res, params);

        // if (res === 0) {
        //     setSubmitting(false);
        // } else {
        //     fetchHolidays(page + 1);
        // }
    }

    function mapProduct(product) {
        return {
            id: product.id,
            value: product.id,
            text: product.text,
            product,
        };
    }

    function mapProductName(product) {
        return {
            id: product.id,
            value: product.id,
            text: product.name,
            product,
        };
    }

    function multiDimensionalUnique(arr) {
        var uniques = [];
        var itemsFound = {};
        for (var i = 0, l = arr.length; i < l; i++) {
            var stringified = JSON.stringify(arr[i]);
            if (itemsFound[stringified]) {
                continue;
            }
            uniques.push(arr[i]);
            itemsFound[stringified] = true;
        }
        return uniques;
    }

    return (
        <div style={{ marginBottom: 20 }}>
            {!holidays || (holidays.length === 0 && <Button disabled={submitting} loading={submitting} size="medium" color="orange" content="Load Packages from Website" onClick={loadHolidays} />)}

            {holidays && holidays.length > 0 && (
                <>
                    <Formik
                        initialValues={{ package_name: "", holiday: "" }}
                        validate={(values) => {
                            const error = {};
                            if (!values.holiday) {
                                error.holiday = "Required";
                            }
                            return error;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            function search(namekey, holidays) {
                                for (var i = 0; i < holidays.length; i++) {
                                    if (holidays[i].id === namekey) {
                                        return holidays[i];
                                    }
                                }
                            }

                            function searchMeta(key, data) {
                                for (var i = 0; i < data.length; i++) {
                                    if (data[i].key === key) {
                                        return data[i].value;
                                    }
                                }
                            }

                            let holiday = search(values.holiday, holidays);
                            let excludes = await searchMeta("cstm_package_details_package_exclusions", holiday.product.product.meta_data);
                            let includes = await searchMeta("cstm_package_details_package_inclusions", holiday.product.product.meta_data);

                            function cleanLi(arr) {
                                arr = arr.replaceAll(/<\/li>/gi, "");

                                //Remove &amp;
                                arr = arr.replaceAll("&amp;", "&");
                                arr = arr.replaceAll("&nbsp;", " ");
                                arr = arr.replaceAll("<ul>", "");
                                arr = arr.replaceAll("</ul>", "");
                                arr = arr.replaceAll("<strong>", "");
                                arr = arr.replaceAll("</strong>", "");
                                arr = arr.replaceAll("&#8217;", "");
                                arr = arr.replaceAll("&#8220;", "");
                                arr = arr.replaceAll("&#8221;", "");
                                arr = arr.replaceAll("&#8211;", "");

                                arr = arr.split("<li>");
                                if (arr[0] === "") {
                                    arr.shift();
                                }

                                var i;
                                for (i = 0; i < arr.length; i++) {
                                    arr[i] = {
                                        value: arr[i],
                                    };
                                }

                                return arr;
                            }

                            function cleanAmp(arr) {
                                arr = arr.replaceAll(/<\/li>/gi, "");
                                arr = arr.replaceAll(/(<([^>]+)>)/gi, "");

                                //Remove &amp; + &nbsp;
                                arr = arr.replaceAll("&amp;", "&");
                                arr = arr.replaceAll("&nbsp;", " ");
                                arr = arr.replaceAll("<ul>", "");
                                arr = arr.replaceAll("</ul>", "");
                                arr = arr.replaceAll("<strong>", "");
                                arr = arr.replaceAll("</strong>", "");
                                arr = arr.replaceAll("&#8217;", "");

                                return arr;
                            }

                            excludes = cleanLi(excludes);
                            includes = cleanLi(includes);

                            let holiday_description = cleanAmp(holiday.product.product.short_description);

                            //Remove &amp;
                            let package_name = cleanAmp(values.package_name);
                            let holiday_name = cleanAmp(holiday.text);

                            let holidayData = {
                                createDate: new Date(Date.now()),
                                package_name: package_name,
                                websiteId: holiday.id,
                                holiday_name: holiday_name,
                                price: holiday.product.product.sale_price && holiday.product.product.sale_price > 0 ? holiday.product.product.sale_price : holiday.product.product.regular_price,
                                quoteId: quote.id,
                                productOriginal: holiday.product.product,
                                stars: searchMeta("cstm_package_rating", holiday.product.product.meta_data),
                                recommendation: searchMeta("cstm_visuals_cta_description", holiday.product.product.meta_data),
                                excludes,
                                includes,
                                holiday_description: holiday_description,
                            };

                            await loadHoliday(holidayData);
                            setSubmitting(false);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Segment attached="top">
                                    <Header as="h3">Load Holiday</Header>
                                </Segment>
                                <Segment attached>
                                    <MyTextInput label="Package Name" name="package_name" type="text" />
                                    <MySelectInput label="Choose Package" name="holiday" options={holidays} placeholder="Select a Holiday" />
                                </Segment>
                                <Segment attached="bottom">
                                    <Button type="submit" disabled={isSubmitting} loading={isSubmitting} size="medium" color="orange" content="Link Holiday to Package" />
                                    <Button
                                        tupe="button"
                                        disabled={submitting}
                                        loading={submitting}
                                        size="medium"
                                        color="orange"
                                        content="Refresh List"
                                        onClick={loadHolidaysDB}
                                        style={{ float: "right" }}
                                    />
                                </Segment>
                            </form>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
}
