import React, { useState } from "react";
import { Segment, Header, Button, Grid, Confirm, Table } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import format from "date-fns/format";
import { useDispatch } from "react-redux";
import { removePayment } from "../bookingActions";
import { getClientPayment } from "../../clients/clientActions";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function BookingPayments(props) {
    const dispatch = useDispatch();
    let client_booking = props.client_booking;
    let clientId = props.clientId;
    let client = props.client;
    const [delId, setDelId] = useState("");
    const [client_payments, setClient_payments] = useState([]);

    function openP(rowid) {
        setDelId(rowid);
    }
    function closeP() {
        setDelId("");
    }

    function removePaymentData() {
        removePayment(delId);
        setDelId("");
    }

    if (client_booking.id && client_booking.id !== "") {
        firestore
        .collection("payments")
        .where("bookingId", "==", client_booking.id)
        .onSnapshot((snapshot) => {
            let list = [];

            snapshot.docs.forEach((doc) => {
                if (doc.exists) {
                    let appObj = { ...doc.data(), id: doc.id };
                    list.push(appObj);
                }
            });

            setClient_payments(list);
        });
    }

    let total_paid = 0;
    if (client_payments.length !== 0) {
        Object.keys(client_payments).forEach(function (key) {
            total_paid += parseFloat(client_payments[key]["amount"]);
            client_payments[key]["amount"] = parseFloat(client_payments[key]["amount"]).toFixed(2);
        });
    }

    total_paid = total_paid.toFixed(2);

    let total_left = 0;
    total_left = client_booking.priceAfter - total_paid;
    total_left = total_left.toFixed(2);

    return (
        <div style={{ paddingBottom: 20 }}>
            <Segment attached="top" style={{ marginTop: 15 }}>
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column>
                            <Header as="h3">Payments</Header>
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            {client_booking && clientId && client && (
                                <Button
                                    color="orange"
                                    as="a"
                                    onClick={() =>
                                        dispatch(
                                            openModal({ modalType: "LeadBookingModal", modalProps: { client_booking: client_booking, clientId: clientId, client: client, modalType: "Add Payment" } })
                                        )
                                    }
                                >
                                    Add Payment
                                </Button>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment attached>
                {client_payments && client_payments.length > 0 ? (
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Date / Time</Table.HeaderCell>
                                <Table.HeaderCell>Source</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Amount</Table.HeaderCell>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {client_payments.map((pay) => (
                                <Table.Row key={pay.id}>
                                    <Table.Cell>{pay.date && (format(pay.date.toDate(), "yyyy-MM-dd HH:mm"))}</Table.Cell>
                                    <Table.Cell>{pay.bookingId ? "Booking" : "Client"}</Table.Cell>
                                    <Table.Cell>{pay.paymentType}</Table.Cell>
                                    <Table.Cell>{pay.amount > 0 ? `R ${pay.amount}` : ""}</Table.Cell>
                                    <Table.Cell>{pay.description}</Table.Cell>

                                    <Table.Cell>
                                        {pay.payment_status ? pay.payment_status : pay.status}
                                        {pay.status && pay.status === "Link Request" && `: https://leads.igotravel.co.za/public/payfast/pay/${pay.id}`}
                                    </Table.Cell>

                                    <Table.Cell textAlign="right" width={3}>
                                        <Button color="orange" as="a" onClick={() => dispatch(openModal({ modalType: "LeadBookingModal", modalProps: { payment: pay, modalType: "Edit Payment" } }))}>
                                            Edit
                                        </Button>

                                        <Button size="medium" color="red" content="Delete" as="a" onClick={() => openP(pay.id)} />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell>Total Paid</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>{total_paid > 0 ? `R ${total_paid}` : ""}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Total Left</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>{total_left > 0 ? `R ${total_left}` : ""}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                ) : (
                    "No Payments"
                )}
            </Segment>

            <Confirm
                header="You are about to delete this Payment"
                content="This cannot be undone, are you sure?"
                size="tiny"
                open={delId && delId !== ""}
                onCancel={closeP}
                onConfirm={() => removePaymentData()}
                confirmButton="Yes"
            />
        </div>
    );
}
