import React, { useState } from "react";
import { Modal, Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { Formik } from 'formik';
import MyTextInput from "../../app/common/form/MyTextInput";
import MyCheckboxInput from "../../app/common/form/MyCheckboxInput";
import { updateComment } from "../leads/leadActions";

export default function CommentEdit(props){
    const dispatch = useDispatch();
    let initialValues = props.comment;
    

    return(
        <>
            <Modal size="medium" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>
                    <Grid>
                        <Grid.Row columns={1} verticalAlign="middle">
                            <Grid.Column>Edit Comment</Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>

                <Modal.Content>
                    <Modal.Description>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={async (values, { setSubmitting, resetForm }) => {

                                            // console.log(values);

                                            await updateComment(values);
                                            setSubmitting(false);
                                            dispatch(closeModal())
                                        }}
                                    >
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                            <form onSubmit={handleSubmit} className="ui form" style={{ marginBottom: 20 }}>
                                                <MyCheckboxInput labelcb="Send Email to Consultant:" name="comment_email" type="checkbox" />
                                                <MyTextInput label="" name="comment" placeholder="Comment" />
                                                <Button style={{ marginTop: 20 }} disabled={isSubmitting} loading={isSubmitting} color="orange" content="Edit Comment" type="submit" />
                                                &nbsp;&nbsp;
                                                <Button onClick={() => dispatch(closeModal())}>
                                                    Cancel
                                                </Button>
                                            </form>
                                        )}
                                    </Formik>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                
            </Modal>
        </>
    )
}