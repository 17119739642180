import React from "react";
import { Header, Segment, Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { createClientFromLead } from "../leadActions";
import { useDispatch } from "react-redux";
import { openModal } from "../../../app/common/modals/modalReducer";

export default function LeadDetailedClient(props) {
    const lead = props.lead;
    const dispatch = useDispatch();

    function createClientFromLeadData(lead) {
        createClientFromLead(lead);
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Segment attached="top">
                <Header as="h3">Client Contact Details</Header>
            </Segment>

            <Segment attached>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong>Name:</strong>
                        </Grid.Column>
                        <Grid.Column>{lead.client_name}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong>Email:</strong>
                        </Grid.Column>
                        <Grid.Column>{lead.client_email}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong>Phone:</strong>
                        </Grid.Column>
                        <Grid.Column>
                            <div className="ui middle aligned" onClick={() => dispatch(openModal({ modalType: "WhatsappUserModal", modalProps: { lead } }))}>
                                {lead.client_phone}
                                {lead.client_phone && lead.client_phone !== "" && <img src="/assets/whatsapp.png" alt="Whatsapp" className="whatsappIcon" />}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong>L Number:</strong>
                        </Grid.Column>
                        <Grid.Column>{lead.l_nr}</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment attached="bottom">
                {lead.clientId && lead.clientId.length > 0 && (
                    <Button as={Link} to={"/clients/edit/" + lead.clientId}>
                        View Client
                    </Button>
                )}

                {lead.client_email && lead.client_email.length > 0 && (!lead.clientId || lead.clientId.length === 0) && (
                    <Button color="orange" as="a" onClick={() => createClientFromLeadData(lead)}>
                        Create/Link Client
                    </Button>
                )}
            </Segment>
        </div>
    );
}
