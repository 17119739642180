import React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import { addTraveller, saveTraveller } from "../../booking/bookingActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyDateInput from "../../../app/common/form/MyDateInput";
import addSeconds from "date-fns/addSeconds";
import format from "date-fns/format";

export default function LeadBookingTravellersForm(props) {
    let traveller = props.traveller;
    let lead_booking = props.client_booking;
    let closeModal = props.closeModal;
    let type = props.type;
    let iniValue = {};
    let date1 = "";
    const dispatch = useDispatch();
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();

    if (props.traveller !== undefined) {
        if (props.traveller.traveller_passport_expiry !== undefined) {
            date1 = format(addSeconds(new Date(0), props.traveller.traveller_passport_expiry.seconds), "yyyy-MM-dd HH:mm");
        }
        iniValue = props.traveller;
    }

    return (
        <>
            <Formik
                initialValues={iniValue}
                validate={(values) => {
                    const error = {};
                    if (!values.traveller_name) {
                        error.traveller_name = "Traveller Name is required";
                    }
                    return error;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        if (props.type === "Add") {
                            addTraveller(values, props.lead_booking);
                        } else {
                            props.lead_booking.id = lastSegment;
                            saveTraveller(values, props.lead_booking);
                        }

                        setSubmitting(false);
                        dispatch(closeModal());
                    }, 400);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid style={{ padding: "20px 0" }}>
                            <Grid.Row columns={4} verticalAlign="middle">
                                <Grid.Column>
                                    <MyTextInput labelTop="Name" name="traveller_name" type="text" />
                                </Grid.Column>

                                <Grid.Column>
                                    <MyTextInput labelTop="ID Number" name="traveller_id" type="text" />
                                </Grid.Column>

                                <Grid.Column>
                                    <MyTextInput labelTop="Passport Number" name="traveller_passport" type="text" />
                                </Grid.Column>

                                <Grid.Column>
                                    <MyDateInput
                                        labelTop="Passport Expiry"
                                        name="traveller_passport_expiry"
                                        selected={date1}
                                        dateFormat="yyyy-MM-dd"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        dropdownMode="select"
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column textAlign="center">
                                    <Button disabled={isSubmitting} loading={isSubmitting} type="submit" size="large" color="orange" content={type} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
}
