import React from "react";
import NavBar from "../../features/nav/NavBar/NavBar";
import { Container } from "semantic-ui-react";
import { Route, useLocation } from "react-router-dom";
import ModalManager from "../../features/modals/modalManager";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingComponent from "./LoadingComponent";
import ProfilePage from "../../features/profiles/profilePage/ProfilePage";
import AccountPage from "../../features/auth/AccountPage";
import PrivateRoute from "./PrivateRoute";
import AdminPage from "../../features/admin/AdminPage";
import UsersPage from "../../features/admin/Users/UsersPage";
import UsersDetailedPage from "../../features/admin/Users/UsersDetailedPage";
import QuoteTemplatesPage from "../../features/admin/Quotes/QuoteTemplatesPage";
import QuoteTemplateDetailedPage from "../../features/admin/Quotes/QuoteTemplateDetailedPage";
import "react-toastify/dist/ReactToastify.css";

import DashboardPage from "../../features/dashboard/DashboardPage";
import ErrorComponent from "../common/errors/ErrorComponent";
import LoginForm from "../../features/auth/LoginForm";
import ClientsDashboardPage from "../../features/clients/ClientsDashboard/ClientsDashboardPage";
import ClientDetailedPage from "../../features/clients/ClientDetailed/ClientDetailedPage";
import LeadsDashboardPage from "../../features/leads/LeadsDashboard/LeadsDashboardPage";
import LeadDetailedPage from "../../features/leads/LeadDetailed/LeadDetailedPage";
import BookingPage from "../../features/booking/BookingPage/BookingPage";

import QuoteDetailedPage from "../../features/quotes/QuoteDetailed/QuoteDetailedPage";
import LeadsUnassignedPage from "../../features/leads/LeadsUnassigned/LeadsUnassignedPage";
import LeadsExportPage from "../../features/leads/Export/LeadsExportPage";
import AppPage from "../../features/booking/AppPage/AppPage";

import RemindersDashboardPage from "../../features/reminders/RemindersDashboard/RemindersDashboardPage";

import LoadHolidays from "../../features/holidays/LoadHolidays";

import ForceReset from "../../features/reset/ForceReset";

import ErrorBoundary from "../layout/ErrorBoundary";

export default function App() {
    // const { key } = useLocation();
    const { initialized } = useSelector((state) => state.async);

    if (!initialized) return <LoadingComponent content="Loading app..." />;

    return (
        <>
            <ModalManager />
            <ToastContainer position="bottom-right" hideProgressBar />
            <Route exact path="/" component={LoginForm} />
            <Route
                path={"/(.+)"}
                render={() => (
                    <>
                        <NavBar />
                        <Container className="main">
                            <PrivateRoute path="/admin/landing" component={AdminPage} />
                            <PrivateRoute path="/admin/users" component={UsersPage} />
                            <PrivateRoute path="/admin/user/:id" component={UsersDetailedPage} />
                            <PrivateRoute path="/admin/quote_templates" component={QuoteTemplatesPage} />
                            <PrivateRoute path="/admin/quote_template/:id" component={QuoteTemplateDetailedPage} />

                            <PrivateRoute path="/dashboard/landing" component={DashboardPage} />

                            <PrivateRoute path="/clients/landing/" component={ClientsDashboardPage} />
                            <PrivateRoute path="/clients/edit/:id" component={ClientDetailedPage} />

                            <PrivateRoute path="/leads/landing/" component={LeadsDashboardPage} />
                            <PrivateRoute path="/leads/edit/:id" component={LeadDetailedPage} />

                            <PrivateRoute path="/booking/confirmation/:clientId/:leadId/:bookingId" component={BookingPage} />
                            <PrivateRoute path="/booking/app/:clientId/:leadId/:bookingId" component={AppPage} />

                            <PrivateRoute path="/quotes/edit/:id" component={QuoteDetailedPage} />

                            <PrivateRoute path="/leads/unassigned/" component={LeadsUnassignedPage} />

                            <PrivateRoute path="/leads/export/" component={LeadsExportPage} />

                            <PrivateRoute path="/reminders" component={RemindersDashboardPage} />

                            <PrivateRoute path="/account" component={AccountPage} />
                            <PrivateRoute path="/profile/:id" component={ProfilePage} />

                            <PrivateRoute path="/load/holidays/" component={LoadHolidays} />

                            <PrivateRoute path="/password/reset/" component={ForceReset} />

                            <Route path="/error" component={ErrorComponent} />
                        </Container>
                    </>
                )}
            />
        </>
    );
}
