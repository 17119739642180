import React, { useEffect, useState } from "react";
import { Segment, Button, Header, Label } from "semantic-ui-react";
import { saveClient } from "../clientActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";

export default function ClientDetailedForm(props) {
    // const [iniVal, setIniVal] = useState({ client_name: "", client_email: "", client_cc_email: "", client_phone: "", client_l_number: "" });

    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();

    // useEffect(() => {
    //     let newData = {};

    //     if (props.client.client_name !== undefined) {
    //         newData.client_name = props.client.client_name;
    //     } else {
    //         newData.client_name = "";
    //     }
    //     if (props.client.client_email !== undefined) {
    //         newData.client_email = props.client.client_email;
    //     } else {
    //         newData.client_email = "";
    //     }
    //     if (props.client.client_cc_email !== undefined) {
    //         newData.client_cc_email = props.client.client_cc_email;
    //     } else {
    //         newData.client_cc_email = "";
    //     }
    //     if (props.client.client_phone !== undefined) {
    //         newData.client_phone = props.client.client_phone;
    //     } else {
    //         newData.client_phone = "";
    //     }
    //     if (props.client.client_l_number !== undefined) {
    //         newData.client_l_number = props.client.client_l_number;
    //     } else {
    //         newData.client_l_number = "";
    //     }
    //     setIniVal(newData);
    // }, []);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={props.client}
                validate={(values) => {
                    const error = {};

                    if (!values.client_name) {
                        error.client_name = "Required";
                    }

                    if (!values.client_l_number) {
                        error.client_l_number = "Client L Number is Required";
                        // } else if (values.client_l_number.length !== 6) {
                        //     error.client_l_number = "Invalid L Number. Example: L12345";
                        // } else if (values.client_l_number[0] !== "L") {
                        //     error.client_l_number = "Invalid L Number. Example: L12345";
                    }

                    if (!values.client_email && !values.client_phone) {
                        error.client_email = "Please enter either Email or Phone";
                        error.client_phone = "Please enter either Email or Phone";
                    }

                    return error;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    values.id = lastSegment;
                    await saveClient(values);
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="ui form" style={{ marginBottom: 20 }}>
                        <Segment attached="top">
                            <Header as="h3">Client - {props.client.status}</Header>
                        </Segment>
                        <Segment attached>
                            <MyTextInput label="Client Name*" name="client_name" type="text" placeholder="Client Name" />
                            <MyTextInput label="Email*" name="client_email" type="email" placeholder="Email" readOnly={props.client.appUserUid ? true : false}/>
                            <br/>
                            {props.client.appUserUid && <p>Email can't be edited if client has app login details. If you need to change the email, delete the app user first.</p>}
                            <MyTextInput label="CC Email" name="client_cc_email" type="text" placeholder="CC Email" />
                            <MyTextInput label="Client Phone" name="client_phone" type="text" placeholder="Client Phone" />
                            <MyTextInput label="L Number" name="client_l_number" type="text" placeholder="L Number" />
                            <br/>
                            <MyCheckboxInput label="Don't allow auto link to lead" name="auto_link_off" type="checkbox" />
                            {errors.client_l_number && <Label basic color="red" style={{ marginBottom: 10, marginTop: 15 }} content={errors.client_l_number} />}
                        </Segment>

                        <Segment attached="bottom">
                            <Button type="submit" disabled={isSubmitting} loading={isSubmitting} color="orange" content="Save" />
                        </Segment>
                    </form>
                )}
            </Formik>
        </>
    );
}
