import React, { useCallback, useEffect, useState } from "react";
import { Modal, Grid, Divider, Table } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { deleteFlightTravelport, createFlightSearch, getAllFlights } from "../quotes/quoteActions";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import AsyncSelect from "react-select/async";
import { Formik, FieldArray, Field } from "formik";
import MyDateInput from "../../app/common/form/MyDateInput";
import MyTextInput from "../../app/common/form/MyTextInput";
import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export default function FlightSearchModal(props) {
    const dispatch = useDispatch();
    let quotePackage = props.quotePackage;
    let quoteId = props.quotePackage.quoteId;
    let packageId = props.quotePackage.id;

    const [flights, setFlights] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [deleting, setDeleting] = useState(false);

    let today = new Date();
    today = addDays(today, 1);

    useEffect(() => {
        let id = packageId ? packageId : "None";

        const unsubscribe = firestore
            .collection("flights")
            .where("packageId", "==", id)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                setFlights(list);
            });

        return () => unsubscribe();
    }, []);

    const airportsAll = require("airport-codes").toJSON();
    const airlinesAll = require("airline-codes").toJSON();

    const getSuggestions = (inputValue) => {
        const inputValueData = inputValue.trim().toLowerCase();
        const inputLength = inputValueData.length;
        let results = inputLength === 0 ? [] : airportsAll.filter((x) => x.iata.toLowerCase().includes(inputValueData));
        let data = [];

        Object.keys(results).forEach(function (key) {
            data.push({
                key: results[key].iata,
                text: results[key].country + " - " + results[key].name + " (" + results[key].iata + ")",
                value: results[key].iata,
            });

            results[key].label = results[key].country + " - " + results[key].name + " (" + results[key].iata + ")";
            results[key].value = results[key].iata;
        });

        return results;
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(getSuggestions(inputValue));
        }, 1000);
    };

    const updateStatus = useCallback((inputValue) => {
        setInputValue(inputValue);
    }, []);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, "");
        updateStatus(inputValue);

        return inputValue;
    };

    async function deleteFlight(flight) {
        setDeleting(true);

        await deleteFlightTravelport(flight.id);

        setDeleting(false);
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    return (
        <>
            <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>Flight Search - {quotePackage.package_name}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Formik
                            initialValues={{ INS: "0", INF: "0", CNN: "0" }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.ADT) {
                                    errors.ADT = "Required";
                                }
                                if (!values.CNN) {
                                    errors.CNN = "Required";
                                }
                                if (!values.INF) {
                                    errors.INF = "Required";
                                }
                                if (!values.INS) {
                                    errors.INS = "Required";
                                }

                                if (!values.legs || values.legs.length == 0) {
                                    errors.legs = "Required";
                                } else {
                                    Object.keys(values.legs).forEach(function (keys) {
                                        if (!values.legs[keys].dateFrom) {
                                            errors.dateFrom = "Required";
                                        }
                                        if (!values.legs[keys].airportFrom) {
                                            errors.airportFrom = "Required";
                                        }
                                        if (!values.legs[keys].airportTo) {
                                            errors.airportTo = "Required";
                                        }
                                    });
                                }

                                console.log("errors", errors);

                                return errors;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                console.log(values);

                                if (values.legs && values.legs.length > 0) {
                                    values.packageId = quotePackage.id;
                                    values.quoteId = quotePackage.quoteId;

                                    await createFlightSearch(values);
                                } else {
                                    alert("Please add at least 1 flight leg");
                                }

                                setSubmitting(false);
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, form, field }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <FieldArray
                                                    name="legs"
                                                    render={(arrayHelpers) => (
                                                        <div>
                                                            <Table striped celled structured>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell colSpan={4} textAlign="center">
                                                                            Legs
                                                                        </Table.HeaderCell>

                                                                        <Table.HeaderCell textAlign="right">
                                                                            <Button size="medium" color="orange" type="button" content="Add" as="a" onClick={() => arrayHelpers.push({})} />
                                                                        </Table.HeaderCell>
                                                                    </Table.Row>

                                                                    <Table.Row>
                                                                        <Table.HeaderCell>Leg</Table.HeaderCell>
                                                                        <Table.HeaderCell>Departure Date</Table.HeaderCell>
                                                                        <Table.HeaderCell>Departure Airport</Table.HeaderCell>
                                                                        <Table.HeaderCell>Arrival Airport</Table.HeaderCell>
                                                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                                                    </Table.Row>
                                                                </Table.Header>

                                                                {errors.legs && errors.legs !== "" ? (
                                                                    <Table.Body>
                                                                        <Table.Row key={0}>
                                                                            <Table.Cell colSpan={5}>At least 1 Leg is required!</Table.Cell>
                                                                        </Table.Row>
                                                                    </Table.Body>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                {values.legs && values.legs.length > 0 ? (
                                                                    <Table.Body>
                                                                        {values.legs.map((row, index) => (
                                                                            <Table.Row key={index}>
                                                                                <Table.Cell width={1}>{index + 1}</Table.Cell>
                                                                                <Table.Cell width={2}>
                                                                                    <MyDateInput
                                                                                        label=""
                                                                                        name={`legs[${index}].dateFrom`}
                                                                                        placeholder="Date From"
                                                                                        dateFormat="yyyy-MM-dd"
                                                                                        showYearDropdown={true}
                                                                                        showMonthDropdown={true}
                                                                                        dropdownMode="select"
                                                                                        minDate={today}
                                                                                    />
                                                                                </Table.Cell>

                                                                                <Table.Cell width={5}>
                                                                                    <AsyncSelect
                                                                                        cacheOptions
                                                                                        name={`legs[${index}].airportFrom`}
                                                                                        loadOptions={loadOptions}
                                                                                        defaultOptions
                                                                                        onChange={(value) => setFieldValue(`legs[${index}].airportFrom`, value)}
                                                                                        isClearable={true}
                                                                                    />
                                                                                </Table.Cell>

                                                                                <Table.Cell width={5}>
                                                                                    <AsyncSelect
                                                                                        cacheOptions
                                                                                        name={`legs[${index}].airportTo`}
                                                                                        loadOptions={loadOptions}
                                                                                        defaultOptions
                                                                                        onChange={(value) => setFieldValue(`legs[${index}].airportTo`, value)}
                                                                                        isClearable={true}
                                                                                    />
                                                                                </Table.Cell>

                                                                                <Table.Cell textAlign="right">
                                                                                    {values.legs.length > 0 && (
                                                                                        <Button size="medium" color="red" content="Delete" as="a" onClick={() => arrayHelpers.remove(index)} />
                                                                                    )}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        ))}
                                                                    </Table.Body>
                                                                ) : null}
                                                            </Table>
                                                        </div>
                                                    )}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Grid>
                                        <Grid.Row columns={5}>
                                            <Grid.Column>
                                                <MyTextInput labelTop="Adults" name="ADT" placeholder="Adults" />
                                            </Grid.Column>

                                            <Grid.Column>
                                                <MyTextInput labelTop="Children" name="CNN" placeholder="Children" />
                                            </Grid.Column>

                                            <Grid.Column>
                                                <MyTextInput labelTop="Infants" name="INF" placeholder="Infants" />
                                            </Grid.Column>

                                            <Grid.Column>
                                                <MyTextInput labelTop="Infants (with seat)" name="INS" placeholder="Infants (with seat)" />
                                            </Grid.Column>

                                            <Grid.Column>
                                                <label>Carriers (Optional)</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    name="carrier"
                                                    loadOptions={loadOptions}
                                                    defaultOptions
                                                    onChange={(value) => setFieldValue("carrier", value)}
                                                    isClearable={true}
                                                    isMulti
                                                />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column textAlign="center">
                                                <Button
                                                    disabled={isSubmitting || isEmpty(errors) === false}
                                                    loading={isSubmitting}
                                                    size="medium"
                                                    color="orange"
                                                    content="Search for Flights"
                                                    type="submit"
                                                />
                                                <Button disabled={isSubmitting} loading={isSubmitting} size="medium" type="button" content="Cancel" as="a" onClick={() => dispatch(closeModal())} />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column>
                                                <Divider />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            )}
                        </Formik>

                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Searched On</Table.HeaderCell>
                                    <Table.HeaderCell>Departing Date</Table.HeaderCell>
                                    <Table.HeaderCell>Departing Airport</Table.HeaderCell>
                                    <Table.HeaderCell>Legs</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>View</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {flights &&
                                    flights.length > 0 &&
                                    flights.map((flight) => (
                                        <Table.Row key={flight.id}>
                                            <Table.Cell>
                                                {format(flight.createdDate.toDate(), "yyyy-MM-dd HH:mm:ii")}
                                                <br />
                                                {flight.id}
                                            </Table.Cell>
                                            <Table.Cell>{flight.legs && flight.legs[0] ? format(flight.legs[0].dateFrom.toDate(), "yyyy-MM-dd") : ""}</Table.Cell>
                                            <Table.Cell>{flight.legs && flight.legs[0] ? flight.legs[0]["airportFrom"]["iata"] : ""}</Table.Cell>
                                            <Table.Cell>{flight.legs.length}</Table.Cell>

                                            <Table.Cell>
                                                <div>{flight.status}</div>
                                                {flight.status === "Failed" && flight.reasonObj && flight.reasonObj.data && flight.reasonObj.data.faultstring && (
                                                    <div>{flight.reasonObj.data.faultstring}</div>
                                                )}
                                            </Table.Cell>

                                            <Table.Cell>
                                                {flight.status === "Complete" && (
                                                    <Button
                                                        size="medium"
                                                        color="orange"
                                                        content="View Details"
                                                        as="a"
                                                        onClick={() =>
                                                            dispatch(openModal({ modalType: "FlightSearchModalSub", modalProps: { flight: flight, quotePackage: quotePackage, quoteId: quoteId } }))
                                                        }
                                                        disabled={deleting}
                                                        loading={deleting}
                                                    />
                                                )}
                                                {flight.status !== "Searching" && (
                                                    <Button disabled={deleting} loading={deleting} size="medium" color="red" content="Delete" as="a" onClick={() => deleteFlight(flight)} />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
