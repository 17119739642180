import React, { useCallback, useEffect, useState } from "react";
import { Modal, Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { addManualFlight, saveManualFlight } from "../quotes/quoteActions";
import AsyncSelect from "react-select/async";
import { Formik } from "formik";
import MyDateInput from "../../app/common/form/MyDateInput";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import addSeconds from "date-fns/addSeconds";
import format from "date-fns/format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "../../app/config/firebase";

const firestore = firebase.firestore();

export default function QuoteFlightEditModal(props) {
    const dispatch = useDispatch();
    const [suggestionsFrom, setSuggestionsFrom] = useState({});
    const [searching, setSearching] = useState(false);
    const [toMinDate, setToMinDate] = useState("");
    const [toDate, setToDate] = useState("");
    let flight = [];
    let dteFrm = "";
    let dteTo = "";
    let initV = { ADT: "", CNN: "0", INF: "0", INS: "0", departDateTime: "", arriveDateTime: "", airportTo: "", airportFrom: "", flight_nr: "", flight_class: "" };
    let valueFrom = "";
    let valueTo = "";

    if (props.flight && props.flight !== undefined && props.flight !== "undefined") {
        flight = props.flight;
        initV = props.flight;

        valueFrom = { label: props.flight.airportFrom, value: props.flight.airportFrom };
        valueTo = { label: props.flight.airportTo, value: props.flight.airportTo };

        dteFrm = new Date(format(addSeconds(new Date(0), props.flight.departDateTime.seconds), "yyyy-MM-dd HH:mm"));
        dteTo = new Date(format(addSeconds(new Date(0), props.flight.arriveDateTime.seconds), "yyyy-MM-dd HH:mm"));
    }

    let quotePackage = {};
    let quoteId = "";
    let quotePackageId = "";

    if (props.quotePackage !== undefined) {
        quotePackage = props.quotePackage;
        quoteId = props.quotePackage.quoteId;
        quotePackageId = props.quotePackage.id;
    } else {
        quoteId = props.client_booking.quoteId;
        quotePackageId = props.client_booking.packageId;
    }

    useEffect(() => {
        if (props.flight && props.flight !== undefined && props.flight !== "undefined") {
            setToDate(props.flight.arriveDateTime.toDate());
            setToMinDate(props.flight.departDateTime.toDate());
        }
        // if(props.quotePackage === undefined){
        //     firestore.collection("quote").doc(props.client_booking.quoteId)
        // }
    }, []);

    // let client_booking = props.client_booking;

    let quotePackages = props.quotePackages;

    const airportsAll = require("airport-codes").toJSON();
    // const airlinesAll = require("airline-codes").toJSON();

    const getSuggestions = (inputValue) => {
        const inputValueData = inputValue.trim().toLowerCase();
        const inputLength = inputValueData.length;
        let results = inputLength === 0 ? [] : airportsAll.filter((x) => x.iata.toLowerCase().includes(inputValueData));

        Object.keys(results).forEach(function (key) {
            results[key].label = results[key].name + " (" + results[key].iata + ")";
            results[key].value = results[key].iata;
        });

        return results;
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(getSuggestions(inputValue));
        }, 1000);
    };

    const classList = [
        { key: "Economy", text: "Economy", value: "Economy" },
        { key: "Business", text: "Business", value: "Business" },
        { key: "First", text: "First", value: "First" },
    ];

    const updateFrom = useCallback((value) => {
        setSuggestionsFrom({ name: value, value: value });
    }, []);

    function handleCloseMenu(value) {
        updateFrom(value.target.value);
    }

    function updateFromDate(value) {
        updateFromDateCB(value);
    }

    const updateFromDateCB = useCallback((value) => {
        setToMinDate(new Date(value));
    }, []);

    function updateToDate(value) {
        updateToDateCB(value);
    }

    const updateToDateCB = useCallback((value) => {
        setToDate(new Date(value));
    }, []);

    return (
        <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
            <Modal.Header>Add a Manual Flight - {quotePackage.package_name}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Formik
                        initialValues={initV}
                        enableReinitialize="true"
                        validate={(values) => {
                            const error = {};
                            return error;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            console.log(values.airportFrom.iata);

                            if (values.airportFrom.iata !== undefined) {
                                values.airportFrom = values.airportFrom.iata;
                            }

                            if (values.airportTo.iata !== undefined) {
                                values.airportTo = values.airportTo.iata;
                            }

                            if (values.departDateTime !== undefined) {
                                values.departDateTime = toMinDate;
                            }

                            if (values.arriveDateTime !== undefined) {
                                values.arriveDateTime = toDate;
                            }

                            if (flight && flight.id && flight.id !== "") {
                                values.packageId = quotePackageId;
                                values.quoteId = flight.quoteId;
                                await saveManualFlight(values);
                            } else {
                                values.packageId = quotePackageId;
                                values.quoteId = quoteId;
                                values.type = "Manual";

                                await addManualFlight(values);
                            }

                            setSubmitting(false);
                            dispatch(closeModal());
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid>
                                    <Grid.Row columns={4}>
                                        <Grid.Column textAlign="center">
                                            <label>
                                                <strong>Departure</strong>
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column textAlign="center">
                                            <label>
                                                <strong>Arrival</strong>
                                            </label>
                                        </Grid.Column>
                                        <Grid.Column textAlign="center" colSpan={2}>
                                            <label>
                                                <strong>Additional Information</strong>
                                            </label>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={4}>
                                        <Grid.Column>
                                            <label style={{ marginRight: 32 }}>
                                                <strong>Date</strong>
                                            </label>{" "}
                                            &nbsp;
                                            <DatePicker
                                                name="departDateTime"
                                                placeholder="Depart"
                                                selected={toMinDate}
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                showTimeSelect={true}
                                                minDate={new Date()}
                                                dropdownMode="select"
                                                onChange={(value) => {
                                                    updateFromDate(value);
                                                }}
                                                maxDate={values.arriveDateTime}
                                                autcomplete="false"
                                            />
                                            <Grid verticalAlign="middle">
                                                <Grid.Row>
                                                    <Grid.Column width={5}>
                                                        <label>
                                                            <strong>Airport</strong>
                                                        </label>
                                                    </Grid.Column>

                                                    <Grid.Column width={11}>
                                                        <br />
                                                        <AsyncSelect
                                                            cacheOptions={true}
                                                            name="airportFrom"
                                                            loadOptions={loadOptions}
                                                            defaultOptions
                                                            defaultValue={valueFrom}
                                                            onBlur={handleCloseMenu}
                                                            onChange={(value) => {
                                                                setFieldValue("airportFrom", value);
                                                            }}
                                                            isClearable={true}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <label style={{ marginRight: 32 }}>
                                                <strong>Date</strong>
                                            </label>{" "}
                                            &nbsp;
                                            <DatePicker
                                                name="arriveDateTime"
                                                placeholder="Arrive"
                                                selected={toDate}
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                showTimeSelect={true}
                                                minDate={toMinDate}
                                                dropdownMode="select"
                                                onChange={(value) => {
                                                    updateToDate(value);
                                                }}
                                                autcomplete="false"
                                            />
                                            <Grid verticalAlign="middle">
                                                <Grid.Row>
                                                    <Grid.Column width={5}>
                                                        <label>
                                                            <strong>Airport</strong>
                                                        </label>
                                                    </Grid.Column>

                                                    <Grid.Column width={11}>
                                                        <br />
                                                        <AsyncSelect
                                                            cacheOptions
                                                            name="airportTo"
                                                            loadOptions={loadOptions}
                                                            defaultOptions
                                                            defaultValue={valueTo}
                                                            onChange={(value) => setFieldValue("airportTo", value)}
                                                            isClearable={true}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <MyTextInput label="Flight Number" name="flight_nr" placeholder="Flight Number" />
                                        </Grid.Column>

                                        <Grid.Column>
                                            <MySelectInput label="Class" name="flight_class" options={classList} placeholder="Select Class" />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={1}>
                                        <Grid.Column textAlign="center">
                                            <label>
                                                <strong>Passengers</strong>
                                            </label>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={4}>
                                        <Grid.Column>
                                            <MyTextInput labelTop="Adults" name="ADT" placeholder="Adults" />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <MyTextInput labelTop="Children" name="CNN" placeholder="Children" />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <MyTextInput labelTop="Infants" name="INF" placeholder="Infants" />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <MyTextInput labelTop="Infants (with seat)" name="INS" placeholder="Infants (with seat)" />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={1}>
                                        <Grid.Column textAlign="center">
                                            <Button
                                                disabled={searching}
                                                loading={searching}
                                                size="medium"
                                                color="orange"
                                                type="submit"
                                                content={flight && flight.id && flight.id !== "" ? "Save Flight" : "Add Flight"}
                                            />
                                            <Button disabled={searching} loading={searching} size="medium" content="Cancel" as="a" onClick={() => dispatch(closeModal())} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </form>
                        )}
                    </Formik>

                    {flight && flight !== "" && flight.id && flight.id !== "" && (
                        <div style={{ marginTop: "20px" }}>
                            <Formik
                                initialValues={{ newPackageId: "" }}
                                validate={(values) => {
                                    const errors = {};
                                    if (!values.newPackageId) {
                                        errors.newPackageId = "Required";
                                    }
                                    return errors;
                                }}
                                onSubmit={async (values, { setSubmitting }) => {
                                    let newFlight = flight;
                                    newFlight.packageId = values.newPackageId;
                                    delete newFlight.id;

                                    await addManualFlight(newFlight);

                                    dispatch(closeModal());
                                }}
                            >
                                {({ errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                    <form className="ui form" onSubmit={handleSubmit}>
                                        <Grid style={{ marginTop: "20px" }}>
                                            <Grid.Row>
                                                <Grid.Column textAlign="center">Clone Flight to another package</Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <div className="field">
                                                        <select className="ui selection dropdown" name="newPackageId" onChange={handleChange} onBlur={handleBlur}>
                                                            <option value="">Choose a Package</option>

                                                            {quotePackages.map((qt) => {
                                                                if (flight.packageId !== qt.id) return <option value={qt.id}>{qt.package_name}</option>;
                                                            })}
                                                        </select>
                                                        {errors.newPackageId && <div class="ui pointing red basic label">{errors.newPackageId}</div>}
                                                    </div>
                                                </Grid.Column>

                                                <Grid.Column>
                                                    <Button type="submit" disabled={isSubmitting} loading={isSubmitting} content="Clone Flight" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    )}
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}
