import React from "react";
import format from "date-fns/format";
import BookingPDFHeader from "../BookingPDF/BookingPDFHeader";
import BookingPDFPrice from "./BookingPDFPrice";

const styleHtml = {
    fontFamily: "Gotham, sans-serif",
    fontSize: "13px",
    lineHeight: "17px",
};

const orangeBar = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "15px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "20px",
};
const orangeBar2 = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "5px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "20px",
};

const heading2 = {
    fontSize: "16px",
    lineHeight: "22px",
};

const padding = {
    padding: "20px 40px",
};
const padding2 = {
    padding: "0 40px",
};

const flightTbl = {
    backgroundColor: "#eef0f1",
    padding: "5px 15px",
    marginTop: "10px",
};

const flightTbl2 = {
    backgroundColor: "#eef0f1",
    padding: "5px 15px",
    marginTop: "10px",
};

const initial_block_outer = {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-15px",
};
const initial_block_outer2 = {
    display: "flex",
    justifyContent: "flex-end",
};

const initial_block = {
    backgroundColor: "#eef0f1",
    padding: "15px",
    color: "red",
    float: "right",
};

const airportsAll = require("airport-codes");

const BookingPDFPackage = ({ qtPackage, client_booking, breakdown, quote, lead, client_confirmation, travellers, flights, payments, condensed }) => {
    // if (client_booking.overview) {
    //     client_booking.overview = client_booking.overview.replace(/(<([^>]+)>)/gi, "");
    //     client_booking.overview = client_booking.overview.replace("&amp;", "&");
    //     client_booking.overview = client_booking.overview.replace("&ndash;", "–");
    //     client_booking.overview = client_booking.overview.replace("&rsquo;", "`");
    //     client_booking.overview = client_booking.overview.replace("&nbsp;", " ");
    // }

    // let breakdown = [];

    // if (breakdown) {
    //     // console.log(breakdown);
    //     Object.keys(breakdown).forEach(function (key) {
    //         if (breakdown[key].value) {
    //             breakdown[key].value = breakdown[key].value.replace(/(<([^>]+)>)/gi, "");
    //             // console.log(breakdown[key].value);
    //         }
    //     });
    // }

    qtPackage.showFlights = false;
    qtPackage.showFlightsHtml = false;

    if ((!qtPackage.flight_html || qtPackage.flight_html === "") && flights && flights.length > 0) {
        Object.keys(flights).forEach(function (key2) {
            if (flights[key2].packageId == client_booking.packageId) {
                qtPackage.showFlights = true;

                //First make it uppercase
                flights[key2].airportFrom = flights[key2].airportFrom.toUpperCase();
                flights[key2].airportTo = flights[key2].airportTo.toUpperCase();

                //Now try to find the airport details
                try {
                    flights[key2].airportFromName = airportsAll.findWhere({ iata: flights[key2].airportFrom }).get("name") + " (" + flights[key2].airportFrom + ")";
                } catch (e) {
                    flights[key2].airportFromName = flights[key2].airportFrom;
                }

                try {
                    flights[key2].airportToName = airportsAll.findWhere({ iata: flights[key2].airportTo }).get("name") + " (" + flights[key2].airportTo + ")";
                } catch (e) {
                    flights[key2].airportToName = flights[key2].airportTo;
                }

                //Check for airport with long name
                if (flights[key2].airportFromName === "Sir Seewoosagur Ramgoolam Intl (MRU)") {
                    flights[key2].airportFromName = "Mauritius (MRU)";
                }
                if (flights[key2].airportToName === "Sir Seewoosagur Ramgoolam Intl (MRU)") {
                    flights[key2].airportToName = "Mauritius (MRU)";
                }
            }
        });
    } else {
        if (qtPackage.flight_html !== undefined) {
            qtPackage.showFlightsHtml = true;

            let cssFlight = "<style>table { width: 100%; font-size: 8pt; line-height: '17px'; }</style>";
            qtPackage.flight_html = cssFlight + qtPackage.flight_html;
        }
    }

    return (
        <div style={styleHtml}>
            <div className="content" key={qtPackage.id}>
                <BookingPDFHeader quote={quote} pagenr={1} lead={lead} condensed={condensed} />

                {quote.template_id && quote.template_id !== "Custom Upload" && quote.templateUrl && quote.templateUrl.length > 0 && (
                    <div>
                        <div>
                            <img style={{ width: "100%" }} src={quote.templateUrl} alt="" />
                        </div>

                        <div style={orangeBar}>{quote.template_name}</div>
                    </div>
                )}
                {quote.template_id && quote.template_id === "Custom Upload" && quote.fileUrl && quote.fileUrl.length > 0 && (
                    <div style={{ height: "126px", overflow: "hidden" }}>
                        <img style={{ width: "100%" }} src={quote.fileUrl} alt="" />
                    </div>
                )}

                <div style={padding}>
                    <div>
                        WE ARE PLEASED TO CONFIRM THE FOLLOWING
                        <br />
                        {qtPackage.package_name}
                        <br />
                        {client_booking.overview && client_booking.overview !== "" && <div dangerouslySetInnerHTML={{ __html: client_booking.overview }}></div>}
                        {travellers && travellers.length > 0 && (
                            <div>
                                {condensed === false && <br />}
                                Travellers:
                                <br />
                                {travellers.map((traveller) => (
                                    <div>
                                        <b>{traveller.traveller_name}</b>
                                    </div>
                                ))}
                            </div>
                        )}
                        {condensed === false && (
                            <>
                                <br />
                                <br />
                            </>
                        )}
                        {qtPackage.showFlights === false &&
                            qtPackage.showFlightsHtml === true &&
                            qtPackage.flight_html &&
                            qtPackage.flight_html !== "" &&
                            qtPackage.flight_html !== "<style>table { width: 100%; font-size: 8pt; line-height: '17px'; }</style>" && (
                                <div style={condensed === true ? { padding2 } : { padding }}>
                                    <div style={{ marginBottom: 5, heading2 }}>Your flight itinerary</div>
                                    <div style={condensed === true ? flightTbl2 : flightTbl}>
                                        <div dangerouslySetInnerHTML={{ __html: qtPackage.flight_html }}></div>
                                    </div>
                                </div>
                            )}
                    </div>

                    {qtPackage.showFlights === true && (
                        <>
                            <div style={{ marginBottom: 5, heading2, marginTop: 10 }}>Your flight itinerary</div>

                            <div style={flightTbl}>
                                <table width="100%" style={{ width: "100%", fontSize: 12, lineHeight: "16px" }}>
                                    <tr>
                                        <td>Flight</td>
                                        <td>From</td>
                                        <td>To</td>
                                        <td>Depart</td>
                                        <td>Arrive</td>
                                    </tr>

                                    {flights.map((flght) => {
                                        if (flght.packageId === client_booking.packageId) {
                                            return (
                                                <tr key={flght.id}>
                                                    <td>{flght.flight_nr}</td>
                                                    <td>{flght.airportFromName}</td>
                                                    <td>{flght.airportToName}</td>
                                                    <td>{format(flght.departDateTime.toDate(), "dd MMM yyyy HH:mm")}</td>
                                                    <td>{format(flght.arriveDateTime.toDate(), "dd MMM yyyy HH:mm")}</td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </table>
                            </div>
                        </>
                    )}
                </div>

                {/* <div style={{ pageBreakBefore: "always" }}> */}
                {/* <BookingPDFHeader quote={quote} pagenr={2} lead={lead} /> */}
                {client_confirmation && client_confirmation.length > 0 && (
                    <div>
                        <div style={orangeBar2}>YOUR TRAVEL PACKAGE INCLUDES</div>

                        <div style={padding}>
                            <ul style={{ margin: 0 }}>
                                {client_confirmation.map((include, key) => {
                                    if (include.itineraryClass === "Includes") return <li key={key}>{include.booking_ref}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                )}
                {/* <div style={orangeBar2}>PRICE BREAKDOWN</div>
                <div style={padding2}>
                    {qtPackage.price_breakdown && qtPackage.price_breakdown.length > 0 && (
                        <div style={flightTbl}>
                            <ul style={{ margin: 0 }}>
                                {qtPackage.price_breakdown.map((price, key) => (
                                    <li key={key}>{price.value}</li>
                                ))}
                            </ul>
                        </div>
                    )} */}

                {/* <div style={flightTbl}>Package Price Based on 3 Adults, 1 Teen (15 years) & 1 Child (10 years)</div> */}

                {client_booking.pricing_new_pg && client_booking.pricing_new_pg === true ? (
                    <div style={{ pageBreakBefore: "always" }}>
                        <BookingPDFHeader quote={quote} pagenr={2} lead={lead} />

                        <BookingPDFPrice qtPackage={qtPackage} client_booking={client_booking} payments={payments} breakdown={breakdown} client_confirmation={client_confirmation} />
                    </div>
                ) : (
                    <BookingPDFPrice qtPackage={qtPackage} client_booking={client_booking} payments={payments} breakdown={breakdown} client_confirmation={client_confirmation} />
                )}

                {/* <div style={flightTbl}>
                        TOTAL DUE
                        <br />
                        <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "30px" }}>
                            <strong>R{client_booking.total_booking_price && client_booking.total_booking_price > 0 ? client_booking.total_booking_price : qtPackage.price}</strong>
                        </span>
                        <br />
                        Price is subject to change due to ROE unless full payment is received
                    </div>
                </div>
                <div style={padding}>
                    <div style={initial_block_outer}>
                        <div style={initial_block}>Initial___________</div>
                    </div>
                </div> */}

                <div style={{ pageBreakBefore: "always" }}>
                    <BookingPDFHeader quote={quote} pagenr={2} lead={lead} />
                    <div style={orangeBar2}>BOOKING</div>
                    <div style={padding}>
                        <br />
                        <br />
                        I hereby agree that the above booking details are correct and names are as per passport.
                        <br />
                        <br />
                        Signed: _______________________ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Name: ____________________
                        <br />
                        <br />
                        <br />
                        <br />
                        Your booking terms are as follows:
                        <br />
                        <br />
                        <br />
                        <br />
                        Cancellation fee: {client_booking.cancellation_fee}
                        <br />
                        <br />
                        Changes / amendments: {client_booking.changes_amendments}
                        <div style={initial_block_outer2}>
                            <div style={initial_block}>Initial___________</div>
                        </div>
                        <br />
                        <br />
                        Please acknowledge you are aware of the following:
                        <br />
                        <ul>
                            <li>You require a valid passport to travel not expiring withing 6 months of return date with at least 2 blank pages</li>
                            <li>Visas may be required and it is your responsibility to obtain the relevant visas in time to travel.</li>
                            <li>Travel insurance is recommended and has been offered to you, should you choose to travel without you do so at your own risk.</li>
                            <li>Due to COVID specific tests, vaccines and other health forms may be required to fly or enter certain destinations, please make sure you are aware and comply.</li>
                            <li>Children under 18 require an unabridged birth certificate to travel</li>
                        </ul>
                        <div style={initial_block_outer}>
                            <div style={initial_block}>Initial___________</div>
                        </div>
                        <br />
                        <br />
                        <div>
                            Please click the following link for Full <a href="https://leads.igotravel.co.za/pdf/IGO_Travel_Terms_and_Conditions_2023.pdf">Terms and Conditions</a>.<br />
                            <strong>Please initial that you have read in full. This document needs to be returned to your consultant for your booking to be completed.</strong>
                            <div style={initial_block_outer}>
                                <div style={initial_block}>Initial___________</div>
                            </div>
                        </div>
                        <br />
                        <br />
                        Yours sincerely,
                        <br />
                        IGOTRAVEL
                        <br />
                        <br />
                        <img src="https://leads.igotravel.co.za/assets/partner.png" style={{ width: "350px" }} alt="IGO Travel" />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingPDFPackage;
