import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Segment, Header, Button, Grid, Confirm, Table, Ref } from "semantic-ui-react";
import { getClientConfirmation } from "../../clients/clientActions";
import { importIncludes, saveSortOrder, importExcludes, importFlights, importItinerary, removeItinerary } from "../../leads/leadActions";
import { openModal } from "../../../app/common/modals/modalReducer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import format from "date-fns/format";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

function BookingConfirmations({ bookingId, leadId, client_booking }) {
    const dispatch = useDispatch();

    const [client_confirmation, setClient_confirmation] = useState([]);
    const [removeItem, setRemoveItem] = useState([]);
    const [removeItemType, setRemoveItemType] = useState([]);
    const [openB, setOpenB] = useState(false);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("bookings")
            .doc(bookingId)
            .collection("confirmation")
            .orderBy("sort_order")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                setClient_confirmation(list);
            });

        return () => unsubscribe();
    }, []);

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && "lightblue",
        ...draggableStyle,
    });

    async function onDragEnd(result) {
        const { destination, source } = result;

        //Dropped back in same place
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        //What table are we sorting?
        let sorted_data = {};

        sorted_data = Object.assign([], client_confirmation);

        //Change order of the items
        const file = sorted_data[source.index];
        sorted_data.splice(source.index, 1);
        sorted_data.splice(destination.index, 0, file);

        //Save order
        client_booking.id = bookingId;
        await saveSortOrder(sorted_data, "confirmation", client_booking);
    }

    function importIncludesData(leadId, client_booking, type) {
        client_booking.id = bookingId;
        importIncludes(leadId, client_booking, type);
    }

    function importExcludesData(leadId, client_booking, type) {
        client_booking.id = bookingId;
        importExcludes(leadId, client_booking, type);
    }

    function importItineraryData(leadId, client_booking, type) {
        client_booking.id = bookingId;
        importItinerary(leadId, client_booking, type);
    }

    function importFlightsData(leadId, client_booking, type) {
        client_booking.id = bookingId;
        importFlights(leadId, client_booking, type);
    }

    function openD(id, type) {
        setRemoveItem(id);
        setRemoveItemType(type);
        setOpenB(true);
    }

    function closeB() {
        setOpenB(false);
    }

    async function removeItineraryData(client_booking) {
        client_booking.id = bookingId;
        await removeItinerary(client_booking, removeItem, removeItemType);

        setOpenB(false);
    }

    return (
        <div style={{ marginBottom: "20px" }}>
            <Segment attached="top">
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column width={4}>
                            <Header as="h3">Booking Confirmation</Header>
                        </Grid.Column>

                        <Grid.Column textAlign="right" width={12}>
                            {client_booking.booked_package_id && client_booking.booked_package_id !== "" && (
                                <Button as="a" onClick={() => importIncludesData(leadId, client_booking, "confirmation")}>
                                    Import Includes
                                </Button>
                            )}

                            {client_booking.booked_package_id && client_booking.booked_package_id !== "" && (
                                <Button as="a" onClick={() => importExcludesData(leadId, client_booking, "confirmation")}>
                                    Import Excludes
                                </Button>
                            )}

                            {client_booking.booked_package_id && client_booking.booked_package_id !== "" && (
                                <Button as="a" onClick={() => importItineraryData(leadId, client_booking, "confirmation")}>
                                    Import Itinerary
                                </Button>
                            )}

                            {client_booking.booked_package_id && client_booking.booked_package_id !== "" && (
                                <Button as="a" onClick={() => importFlightsData(leadId, client_booking, "confirmation")}>
                                    Import Flight Details
                                </Button>
                            )}
                            <Button
                                color="orange"
                                as="a"
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modalType: "LeadBookingModal",
                                            modalProps: { modalType: "Add Itinerary", client_booking: client_booking, leadId: leadId, bookingType: "confirmation", modalType: "Add Itinerary" },
                                        })
                                    )
                                }
                            >
                                Add Itinerary
                            </Button>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column textAlign="center" style={{ color: "red" }}>
                            <strong>File Uploads are done via App Itinerary not the Booking Confirmation!</strong>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {client_confirmation && client_confirmation.length > 0 && (
                <Segment attached>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.HeaderCell>Date / Time</Table.HeaderCell>
                                    <Table.HeaderCell>Item</Table.HeaderCell>
                                    <Table.HeaderCell>Booking Reference</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Droppable droppableId="table_app_confirmation">
                                {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                        <Table.Body {...provided.droppableProps}>
                                            {client_confirmation.map((itin, idx) => {
                                                return (
                                                    <Draggable draggableId={itin.id.toString()} index={idx} key={itin.id}>
                                                        {(provided, snapshot) => (
                                                            <Ref innerRef={provided.innerRef}>
                                                                <Table.Row
                                                                    key={itin.id}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <Table.Cell>{itin.itineraryClass ? itin.itineraryClass : itin.itineraryType}</Table.Cell>
                                                                    <Table.Cell>{itin.date ? format(itin.date.toDate(), "yyyy-MM-dd HH:mm") : ""}</Table.Cell>
                                                                    <Table.Cell>{itin.item}</Table.Cell>
                                                                    <Table.Cell>{itin.booking_ref}</Table.Cell>
                                                                    <Table.Cell textAlign="right" width={3} hidden={snapshot.isDragging === true}>
                                                                        {itin.itineraryType === "Itinerary" /*|| itin.itineraryType === "Hotel"*/ && (
                                                                            <Button
                                                                                color="orange"
                                                                                as="a"
                                                                                onClick={() =>
                                                                                    dispatch(
                                                                                        openModal({
                                                                                            modalType: "LeadBookingModal",
                                                                                            modalProps: { itin, client_booking, modalType: "Edit", bookingType: "confirmation" },
                                                                                        })
                                                                                    )
                                                                                }
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        )}

                                                                        <Button size="medium" color="red" content="Delete" as="a" onClick={() => openD(itin.id, "confirmation")} />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </Ref>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </Table.Body>
                                    </Ref>
                                )}
                            </Droppable>
                        </Table>
                    </DragDropContext>
                </Segment>
            )}

            <Confirm
                header="You are about to delete this Itinerary Row"
                content="This cannot be undone, are you sure?"
                size="tiny"
                open={openB}
                onCancel={closeB}
                onConfirm={() => removeItineraryData(client_booking, "confirmation")}
                confirmButton="Yes"
            />
        </div>
    );
}

export default BookingConfirmations;
