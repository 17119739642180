import React from "react";
import { Modal, Grid } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { createPackageTemplate } from "../quotes/quoteActions";
import { closeModal } from "../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";

export default function PackageTemplateModal(props) {
    const dispatch = useDispatch();
    return (
        <>
            <Modal size="tiny" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>Package Template</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Formik
                            initialValues={{ template_name: "" }}
                            validate={(values) => {
                                const error = {};
                                if (!values.template_name) {
                                    error.template_name = "Required";
                                }
                                return error;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                console.log(values);
                                console.log(props);
                                await createPackageTemplate(values, props.quotePackage, props.profile);
                                setSubmitting(false);
                                dispatch(closeModal());
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <MyTextInput label="Template Name" name="template_name" type="text" />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column>
                                                <Button disabled={isSubmitting} loading={isSubmitting} color="orange" content="Create Package Template" />
                                                <Button disabled={isSubmitting} onClick={() => dispatch(closeModal())}>
                                                    Cancel
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
