import React, { Component } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Grid, GridColumn, GridRow, Form  } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { Formik } from "formik";
import MyDateInput from "../../app/common/form/MyDateInput";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import MyCheckboxInput from "../../app/common/form/MyCheckboxInput";
import { updateUserPassword } from "./resetAction";

export default function ForceReset({history}) {

    const dispatch = useDispatch();
    return (
        <Modal size="tiny" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>Password Reset
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Your password is 90 days old and requires an update.</p>
                        <br/><br/>
                        <Formik
                            initialValues={{ password: "", password_confirm: "" }}
                            enableReinitialize="true"
                            validate={(values) => {
                                const error = {};
                                if (!values.password) {
                                    error.password = "Required";
                                }
                                if (!values.password_confirm) {
                                    error.password_confirm = "Required";
                                }
                                if (values.password !== values.password_confirm) {
                                    error.password_confirm = "Password doesn't match";
                                }
                                if (values.password.length < 8) {
                                    error.password = "Password must be at least 8 characters";
                                }
                                return error;
                            }}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                await updateUserPassword(values.password);

                                history.push("/dashboard/landing");
                            }}
                        >
                            {({ values, error, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid centered>
                                        <GridRow>
                                            <GridColumn width={16}>

                                                <MyTextInput label="Password*" name="password" type="password" />
                                                <MyTextInput label="Confirm Password*" name="password_confirm" type="password" />
    
                                            </GridColumn>
                                        </GridRow>

                                        <GridRow>
                                            <GridColumn>
                                                <Button type="submit" disabled={isSubmitting} loading={isSubmitting} color="orange" content="Update password" />
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
    );
}

