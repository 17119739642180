import React, { useState, useEffect } from "react";
import QuoteDetailedPackage from "./QuoteDetailedPackage";

// import firebase from "../../../app/config/firebase";
// const firestore = firebase.firestore();

export default function QuoteDetailedPackages({ quote, quotePackages, profile }) {
    return (
        <>
            {quotePackages &&
                quote &&
                quote.id &&
                quotePackages.length > 0 &&
                quotePackages.map((quotePackage) => <QuoteDetailedPackage key={quotePackage.id} quote={quote} quotePackage={quotePackage} profile={profile} quotePackages={quotePackages} />)}
        </>
    );
}
