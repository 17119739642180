import React, { useState, useEffect } from "react";
import { Divider, Header, Segment, Table } from "semantic-ui-react";
import format from "date-fns/format";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadDetailedLogs(props) {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("logs")
            .where("rowId", "==", props.lead.id)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setLogs(list);
            });

        return () => unsubscribe();
    }, []);

    return (
        <Segment>
            <Header as="h3">Lead Logs</Header>

            <Divider />

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        <Table.HeaderCell>User</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {logs &&
                        logs.length > 0 &&
                        logs.map((lg) => (
                            <Table.Row key={lg.id}>
                                <Table.Cell>{format(lg.createDate.toDate(), "d MMMM yyyy HH:mm:ii")}</Table.Cell>
                                <Table.Cell>{lg.type}</Table.Cell>
                                <Table.Cell>{lg.userDisplayName}</Table.Cell>
                            </Table.Row>
                        ))}
                </Table.Body>
            </Table>
        </Segment>
    );
}
