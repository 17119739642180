import React from "react";
import NumberFormat from "react-number-format";

const styleHtml = {
    fontFamily: "Gotham, sans-serif",
    fontSize: "13px",
    lineHeight: "17px",
};

const orangeBar = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "15px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "20px",
};
const orangeBar2 = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "5px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "20px",
};

const heading2 = {
    fontSize: "16px",
    lineHeight: "22px",
};

const padding = {
    padding: "20px 40px",
};
const padding2 = {
    padding: "0 40px",
};

const flightTbl = {
    backgroundColor: "#eef0f1",
    padding: "5px 15px",
    marginTop: "10px",
};

const initial_block_outer = {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-15px",
};
const initial_block_outer2 = {
    display: "flex",
    justifyContent: "flex-end",
};

const initial_block = {
    backgroundColor: "#eef0f1",
    padding: "15px",
    color: "red",
    float: "right",
};

const flightTbl2 = {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 15px",
};
const flightTbl2Inner = {
    width: "33%",
};

const BookingPDFPrice = ({ qtPackage, client_booking, payments, breakdown, client_confirmation }) => {
    let deposit = 0;
    let total_booking_price = 0;
    let total_discounted_price = 0;

    if (parseFloat(client_booking.total_booking_price) && parseFloat(client_booking.total_booking_price) > 0) {
        total_booking_price = parseFloat(client_booking.total_booking_price);
    }

    if (client_booking.total_discount && parseFloat(client_booking.total_discount) > 0) {
        total_discounted_price = total_booking_price - parseFloat(client_booking.total_discount);
    }

    if (payments && payments.length > 0) {
        Object.keys(payments).forEach(function (key) {
            if (payments[key].status !== "Awaiting Payment") {
                deposit += parseFloat(payments[key].amount);
            }
        });

        deposit = deposit.toFixed(2);

        total_booking_price = parseFloat(total_booking_price) - parseFloat(deposit);
        total_booking_price = total_booking_price.toFixed(2);
    }

    //Check if we need to show Excludes
    let showExcludes = false;
    Object.keys(client_confirmation).forEach(function (key) {
        if (client_confirmation[key].itineraryClass === "Excludes") {
            showExcludes = true;
        }
    });

    return (
        <div>
            {showExcludes === true && (
                <div>
                    <div style={orangeBar2}>YOUR TRAVEL PACKAGE EXCLUDES</div>

                    <div style={padding}>
                        <ul style={{ margin: 0 }}>
                            {client_confirmation.map((include, key) => {
                                if (include.itineraryClass === "Excludes") return <li key={key}>{include.booking_ref}</li>;
                            })}
                        </ul>
                    </div>
                </div>
            )}

            <div style={orangeBar2}>PRICE BREAKDOWN</div>
            <div style={padding2}>
                {breakdown && breakdown.length > 0 && (
                    <div style={flightTbl}>
                        <ul style={{ margin: 0 }}>
                            {breakdown.map((price, key) => (
                                <li key={key}>{price}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {/* <div style={flightTbl}>Package Price Based on 3 Adults, 1 Teen (15 years) & 1 Child (10 years)</div> */}
                {/* <div></div> */}

                {client_booking.total_discount && parseFloat(client_booking.total_discount) > 0 ? (
                    <div style={flightTbl}>
                        <div style={flightTbl2}>
                            <div style={flightTbl2Inner}>
                                TOTAL
                                <br />
                                <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "50px" }}>
                                    <strong>
                                        <NumberFormat value={client_booking.total_booking_price} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                    </strong>
                                </span>
                            </div>
                            <div style={flightTbl2Inner}>
                                DISCOUNT
                                <br />
                                <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "50px" }}>
                                    <strong>
                                        <NumberFormat value={client_booking.total_discount} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                    </strong>
                                </span>
                            </div>
                            <div style={flightTbl2Inner}>
                                DUE
                                <br />
                                <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "50px" }}>
                                    <strong>
                                        <NumberFormat value={total_discounted_price} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                    </strong>
                                </span>
                            </div>
                        </div>
                        Price is subject to change due to ROE unless full payment is received
                    </div>
                ) : (
                    <div style={flightTbl}>
                        {client_booking.show_deposit && client_booking.show_deposit === true && deposit !== 0 && (
                            <div style={{ marginBottom: "20px" }}>
                                DEPOSIT PAID = <strong>R{deposit}</strong>
                            </div>
                        )}
                        TOTAL DUE
                        <br />
                        <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "30px" }}>
                            <strong>
                                {parseFloat(total_booking_price) > 0 ? (
                                    <NumberFormat value={total_booking_price} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                ) : parseFloat(deposit) >= parseFloat(total_discounted_price) ? (
                                    <NumberFormat value={0} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                ) : (
                                    <NumberFormat value={qtPackage.price} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                )}
                            </strong>
                        </span>
                        <br />
                        Price is subject to change due to ROE unless full payment is received
                    </div>
                )}
            </div>
            <div style={padding}>
                <div style={initial_block_outer}>
                    <div style={initial_block}>Initial___________</div>
                </div>
            </div>
        </div>
    );
};

export default BookingPDFPrice;
