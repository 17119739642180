import React, { useState, useEffect, useCallback } from "react";
import { Table, Modal, Button, Grid, Segment, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { addManualFlight, resetSearch, getFlightsResults } from "../quotes/quoteActions";
import format from "date-fns/format";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { Formik } from 'formik';
import MySelectInput from "../../app/common/form/MySelectInput";
import MyTextInput from "../../app/common/form/MyTextInput";

export default function FlightSearchModalSub(props){
    let quotePackage = props.quotePackage;
    let flightId = props.flight.id;
    const dispatch = useDispatch();
    const [adding, setAdding] = useState(false);
    const [flight_results, setFlight_results] = useState([]);
    const [priceSort, setPriceSort] = useState("asc");
    var airports = require("airport-codes");
    var airlines = require("airline-codes");
    const [flight_results_filtered, setFlight_results_filtered] = useState([])
    const [filterStops, setFilterStops] = useState("0")
    const [filterCarrier, setFilterCarrier] = useState("");
    

    useEffect(() => {
        getFlightsResults(flightId, priceSort).then(
            (res) => {
                let flightList = []
                res.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ['id']: `${doc.id}` }
                        flightList.push(appObj)
                    }
                });
                // setFlight_results(flightList);
                let stops = 0;
                Object.keys(flightList).forEach(function (key1) {
                    stops = 0;
                    Object.keys(flightList[key1].directions).forEach(function (key2) {
                        stops += flightList[key1].directions[key2].segments.length - 1;
                        Object.keys(flightList[key1].directions[key2].segments).forEach(function (key3) {
                            
                            let thisTime = flightList[key1].directions[key2].segments[key3].details[0].flightTime;
                            let hours = Math.floor(thisTime / 60);
                            let minutes = thisTime - hours * 60;
                            
                            if (minutes < 10) {
                                minutes = "0" + minutes;
                            }

                            flightList[key1].directions[key2].segments[key3].details[0].flightTime = hours + ":" + minutes;

                            thisTime = flightList[key1].directions[key2].segments[key3].details[0].travelTime;
                            hours = Math.floor(thisTime / 60);
                            minutes = thisTime - hours * 60;

                            if (minutes < 10) {
                                minutes = "0" + minutes;
                            }

                            flightList[key1].directions[key2].segments[key3].details[0].travelTime = hours + ":" + minutes;
                        })
                    })
                    flightList[key1].stops = stops;

                    if (!filterStops || parseInt(filterStops) === parseInt(stops)) {
                        // flight_results_filtered.push(flightList[key1]);
                        setFlight_results_filtered([flightList[key1]])
                    }
                })
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);


    let sortOrder = [
        { key: 1, text: "Price Low to High", value: 1 },
        { key: 2, text: "Price High to Low", value: 2 },
    ];

    const airportsAll = require("airport-codes").toJSON();

    const getSuggestions = (inputValue) => {
        const inputValueData = inputValue.trim().toLowerCase();
        const inputLength = inputValueData.length;
        let results = inputLength === 0 ? [] : airportsAll.filter((x) => x.iata.toLowerCase().includes(inputValueData))

        Object.keys(results).forEach(function (key) {
            results[key].label = results[key].country + " - " + results[key].name + " (" + results[key].iata + ")";
            results[key].value = results[key].iata;
        });
        return results;
    };
  
    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
        callback(getSuggestions(inputValue));
        }, 1000);
    };

    const updateCarrier = useCallback((value) => {
        setFilterCarrier(value);
    }, [])

    function filterCarrierChange(event, value){
        let iata = "";

        if (value && value.option !== "undefined" && value.option !== undefined && value.option.iata) {
            iata = value.option.iata;
        }

        updateCarrier(iata);

    }
    
    function SavePackageFlight(fr){
        setAdding(true)

        let values = {};

        values.flightId = props.flight.id;
        values.packageId = props.quotePackage.id;
        values.quoteId = props.quoteId;

        Object.keys(fr.directions).forEach(function (key) {
            values.type = "Travelport";
            // values.airportFrom = fr.directions[key].from;
            // values.airportTo = fr.directions[key].to;

            Object.keys(fr.directions[key].segments).forEach(function (k2) {
                values.departDateTime = new Date(Date.parse(fr.directions[key].segments[k2].departure));
                values.arriveDateTime = new Date(Date.parse(fr.directions[key].segments[k2].arrival));

                values.airportFrom = fr.directions[key].segments[k2].from;
                values.airportTo = fr.directions[key].segments[k2].to;

                values.flight_nr = fr.directions[key].platingCarrier + fr.directions[key].segments[k2].flightNumber;
                values.flight_class = fr.directions[key].segments[k2].serviceClass;

                addManualFlight(values);
            });
        });

        dispatch(closeModal())
    }

    return(
        <>
            <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
            <Modal.Header>
                    <Grid>
                        <Grid.Row columns={2} verticalAlign="middle">
                            <Grid.Column>Flight Options - {quotePackage.package_name}</Grid.Column>

                            <Grid.Column textAlign="right">
                                {/* <Button
                                    disabled={adding}
                                    loading={adding}
                                    size="medium"
                                    color="red"
                                    content="Reset Search"
                                    as="a"
                                    onClick={() => this.resetSearch()}
                                /> */}

                                <Button disabled={adding} loading={adding} size="medium" content="Close" as="a" onClick={() => dispatch(closeModal())} />

                                <Button
                                    disabled={adding}
                                    loading={adding}
                                    size="medium"
                                    color="orange"
                                    content="Back to Search"
                                    as="a"
                                    onClick={() => dispatch(openModal({modalType:'FlightSearchModal', modalProps: { quotePackage: quotePackage,}}))}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>

                <Modal.Content>
                    <Modal.Description>
                        <Segment>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validate={values => {
                                const error = {};

                                return error;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                                setSubmitting(false);
                                }, 400);
                            }}
                            >
                            {({handleSubmit,}) => (
                                <form onSubmit={handleSubmit} className="ui form">
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <label style={{ display: "block", margin: "-8px 0 .28571429rem 0", fontWeight: "bold" }}>Carrier Filter</label>
                                        <AsyncSelect
                                            cacheOptions
                                            name={"carrier"}
                                            loadOptions={loadOptions}
                                            defaultOptions
                                            onChange={(event, value) => filterCarrierChange(event, value)}
                                            isClearable={true}
                                            isMulti
                                        />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <MySelectInput labelTop="Sort Order" name="sortOrder" options={sortOrder} />
                                    </Grid.Column>

                                    <Grid.Column>
                                        <MyTextInput labelTop="Max Nubmer of Stops" name="filterStops" />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            </form>
                            )}
                            </Formik>
                        </Segment>

                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Overview</Table.HeaderCell>
                                    <Table.HeaderCell>Segments</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            {flight_results_filtered &&
                                    flight_results_filtered.length > 0 &&
                                    flight_results_filtered.map((fr) => (
                                        <>
                                        <Table.Row key={fr.id} hidden={filterCarrier !== "" && filterCarrier !== fr.directions[0].platingCarrier ? true : false}>
                                            <Table.Cell>
                                                <div>Total Price: {fr.totalPrice}</div>
                                                <div>Taxes: {fr.taxes}</div>
                                                <div>
                                                    From:{" "}
                                                    {airports
                                                        .findWhere({
                                                            iata: fr.directions[0].from,
                                                        })
                                                        .get("name")}
                                                    <br />
                                                    {airports
                                                        .findWhere({
                                                            iata: fr.directions[0].from,
                                                        })
                                                        .get("city")}{" "}
                                                    ({fr.directions[0].from})
                                                </div>
                                                <div>
                                                    To:{" "}
                                                    {airports
                                                        .findWhere({
                                                            iata: fr.directions[0].to,
                                                        })
                                                        .get("name")}
                                                    <br />
                                                    {airports
                                                        .findWhere({
                                                            iata: fr.directions[0].to,
                                                        })
                                                        .get("city")}{" "}
                                                    ({fr.directions[0].to})
                                                </div>
                                                <div>
                                                    Plating Carrier:{" "}
                                                    {airlines
                                                        .findWhere({
                                                            iata: fr.directions[0].platingCarrier,
                                                        })
                                                        .get("name")}{" "}
                                                    ({fr.directions[0].platingCarrier})
                                                </div>
                                                <div>Stops: {fr.stops}</div>
                                                <Button
                                                    disabled={adding}
                                                    loading={adding}
                                                    size="medium"
                                                    color="orange"
                                                    content="Add"
                                                    as="a"
                                                    onClick={() => SavePackageFlight(fr)}
                                                />
                                            </Table.Cell>

                                            <Table.Cell>
                                                {fr.directions &&
                                                    fr.directions.length > 0 &&
                                                    fr.directions.map((direction) => (
                                                        <Table>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>Carrier</Table.HeaderCell>
                                                                    <Table.HeaderCell>Departure</Table.HeaderCell>
                                                                    <Table.HeaderCell>Arrival</Table.HeaderCell>
                                                                    <Table.HeaderCell>Duration</Table.HeaderCell>
                                                                    <Table.HeaderCell>Service Class</Table.HeaderCell>
                                                                    <Table.HeaderCell>Baggage</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            <Table.Body>
                                                                {direction.segments &&
                                                                    direction.segments.length > 0 &&
                                                                    direction.segments.map((segment, s_idx) => (
                                                                        <Table.Row>
                                                                            <Table.Cell>
                                                                                <div>
                                                                                    {airlines
                                                                                        .findWhere({
                                                                                            iata: segment.airline,
                                                                                        })
                                                                                        .get("name")}{" "}
                                                                                    ({segment.airline})
                                                                                </div>
                                                                                <div>{direction.platingCarrier + " " + segment.flightNumber}</div>
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {airports
                                                                                    .findWhere({
                                                                                        iata: segment.from,
                                                                                    })
                                                                                    .get("name")}
                                                                                <br />
                                                                                {airports
                                                                                    .findWhere({
                                                                                        iata: segment.from,
                                                                                    })
                                                                                    .get("city")}{" "}
                                                                                ({segment.from})
                                                                                <br />
                                                                                {format(moment(segment.departure).toDate(), "dd MMMM yyyy HH:mm")}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {airports
                                                                                    .findWhere({
                                                                                        iata: segment.to,
                                                                                    })
                                                                                    .get("name")}
                                                                                <br />
                                                                                {airports
                                                                                    .findWhere({
                                                                                        iata: segment.to,
                                                                                    })
                                                                                    .get("city")}{" "}
                                                                                ({segment.to})
                                                                                <br />
                                                                                {format(moment(segment.arrival).toDate(), "dd MMMM yyyy HH:mm")}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                <div>{segment.details[0].flightTime}</div>
                                                                                <div>{segment.details[0].travelTime}</div>
                                                                            </Table.Cell>
                                                                            <Table.Cell>{segment.serviceClass}</Table.Cell>
                                                                            <Table.Cell>
                                                                                {segment.baggage &&
                                                                                    segment.baggage.length > 0 &&
                                                                                    segment.baggage.map((bag, b_idx) => (
                                                                                        <p key={"bag_" + b_idx}>
                                                                                            {bag.amount} {bag.units} ({bag.bookingClass})
                                                                                        </p>
                                                                                    ))}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    ))}
                                                            </Table.Body>
                                                        </Table>
                                                    ))}
                                            </Table.Cell>
                                        </Table.Row>
                                        </>
                                    ))}
                            </Table.Body>
                        </Table>


                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    )
}
