import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { VerticalBarSeries, XYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, RadialChart } from "react-vis";
import { dashhboardQuery, getcurrentUserProfileUser } from "./dashboardActions";

export default function DashboardPage(props) {
    const [users, setUsers] = useState([]);
    const [leads_by_source, setLeads_by_source] = useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        dashQuery();
    }, []);

    if (currentUserProfile.isLoaded === true && currentUserProfile.isEmpty === true) {
        this.props.history.push("/");
    }

    async function dashQuery() {
        let query = await dashhboardQuery();
        setUsers(query.users);
        setLeads_by_source(query.leads_by_source);
    }

    let new_pickups_data = [];

    if (users && users.length > 0) {
        Object.keys(users).forEach(function (key) {
            let name = users[key].email;
            if (users[key].displayName) {
                name = users[key].displayName;
            }

            new_pickups_data.push({
                x: name,
                y: users[key].leads_count,
            });
        });
    }

    let leads_by_source_data = [];

    const sourceArr = ["Telephone", "Email", "Chat", "Website", "Social Media", "Repeat Client", "Referral", "Mailchimp/Mailer", "Other"];

    for (let i = 0; i < sourceArr.length; i++) {
        if (typeof leads_by_source[sourceArr[i]] !== "undefined") {
            leads_by_source[sourceArr[i]].label = leads_by_source[sourceArr[i]].label + " = " + leads_by_source[sourceArr[i]].angle;

            leads_by_source_data.push(leads_by_source[sourceArr[i]]);
        }
    }

    return (
        <Container>
            <h2>Dashboard</h2>
            <h3>Consultants - New enquiries picked up</h3>

            <XYPlot xType="ordinal" stackBy="y" width={1100} height={200}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis />
                <VerticalBarSeries cluster="2015" color="#12939A" data={new_pickups_data} />
            </XYPlot>

            <h3>Enquiries by Source</h3>
            <RadialChart data={leads_by_source_data} width={500} height={500} showLabels={true} />
        </Container>
    );
}
