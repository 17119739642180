import React from "react";
import { Segment, Header, Button } from "semantic-ui-react";
import { createQuotePackage } from "../quoteActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";

export default function QuoteDetailedManPack(props) {
    return (
        <div style={{ marginBottom: 20 }}>
            <Formik
                initialValues={{ package_name: "" }}
                validate={(values) => {
                    const error = {};
                    if (!values.package_name) {
                        error.package_name = "Package Name needs to be defined";
                    }
                    return error;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    values.displayName = props.profile.displayName;
                    values.userUid = props.profile.id;

                    await createQuotePackage(values, props.quote);

                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Segment attached="top">
                            <Header as="h3">Add a Package</Header>
                        </Segment>
                        <Segment attached>
                            <MyTextInput label="Package Name" name="package_name" type="text" />
                        </Segment>
                        <Segment attached="bottom">
                            <Button disabled={isSubmitting} loading={isSubmitting} size="medium" color="orange" content="Add Package" />
                        </Segment>
                    </form>
                )}
            </Formik>
        </div>
    );
}
