import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../../app/common/modals/modalReducer";
import SmartDataTable from "react-smart-data-table";
import { getClients } from "../clientActions";
import LoadingComponent from "../../../app/layout/LoadingComponent";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function ClientsDashboardPage(props) {
    const dispatch = useDispatch();
    const [clients, setClients] = useState([]);
    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
    
        const unsubscribe = firestore
            .collection("clients")
            .onSnapshot((snapshot) => {
                let list = [];
    
                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });
                list.sort((a, b) => (a.companyName > b.companyName ? 1 : -1));
    
                setClients(list);
            });
    
        return () => unsubscribe();
    }, []);

    function handleChange({ target: { name, value } }) {
        setFilterValue(value);
    }

    function onRowClick(event, { rowData, rowIndex, tableData }) {
        const { fullName, name, id } = rowData;
        let value = fullName || name || id;

        if (!value) {
            const [key] = Object.keys(rowData);
            value = `${key}: ${rowData[key]}`;
        }
        props.history.push("/clients/edit/" + value);
    }

    const orderedHeaders = ["client_name", "client_l_number", "client_email", "client_phone", "status"];

    if (!clients) return <LoadingComponent />;

    return (
        <div>
            <Button style={{ marginBottom: 20 }} color="orange" onClick={() => dispatch(openModal({ modalType: "ClientCreateModal" }))}>
                Add Client
            </Button>

            <div className="ui icon input">
                <input type="text" name="filterValue" value={filterValue} placeholder="Filter results..." onChange={handleChange} />
                <i className="search icon" />
            </div>
            <SmartDataTable
                data={clients}
                name="clients-table"
                className="ui compact selectable table celled"
                sortable
                onRowClick={onRowClick}
                orderedHeaders={orderedHeaders}
                hideUnordered={true}
                filterValue={filterValue}
            />
        </div>
    );
}
