import React, { useState, useEffect } from "react";
import { Header, Segment } from "semantic-ui-react";
import LeadDetailedFilesForm from "./LeadDetailedFilesForm";
import format from "date-fns/format";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadDetailedFiles(props) {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("leads")
            .doc(props.lead.id)
            .collection("files")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setFiles(list);
            });

        return () => unsubscribe();
    }, []);

    return (
        <div style={{ marginBottom: 20 }}>
            <Segment attached="top">
                <Header as="h3">Files</Header>
            </Segment>

            <Segment attached>
                {files &&
                    files.length > 0 &&
                    files.map((file) => (
                        <p key={file.id}>
                            <strong>
                                Uploaded {format(file.createDate.toDate(), "d MMMM yyyy hh:mm:ii")} - {file.description}
                            </strong>
                            <br />
                            <a href={file.fileUrl} target="_blank">
                                Download
                            </a>
                        </p>
                    ))}
            </Segment>

            <Segment attached="bottom">
                <LeadDetailedFilesForm leadid={props.lead.id} profile={props.profile} />
            </Segment>
        </div>
    );
}
